import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

export const getFacilityTemplates = () => {
  const requestURL = `${API_URL}/templates`;
  return new Promise((resolve, reject) => {
    melior
      .get(requestURL)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFacilityTemplatesTemp = () => {
  const requestURL = `${API_URL}/templates`;

  return melior.get(requestURL);
};

export const useGetFacilityTemplates = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["facility-templates"],
    queryFn: () => getFacilityTemplatesTemp(),
    keepPreviousData: true,
  });
};