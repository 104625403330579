import { useEffect, useState, useContext, useCallback, useRef } from "react";
import moment from "moment";
import {
  Box,
  Checkbox,
  Skeleton,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import {
  OverallViewContext,
  updateSelectedFiltersActions,
} from "../../contexts/OverallViewContext";
import {
  MeliorTable,
  useSelectedRows,
} from "../../../../reusable/components/melior-table";
import { ReusableTabSpecificFiltersContainer } from "../ReusableTabSpecificFiltersContainer";
import { COLORS } from "../../../../helpers/Colors";
import { ReviewActivityLogDialog, StarReview } from "./actions";
import { ContactsDialog } from "../../../../reusable/components/ContactsDialog";
import { DynamicSearchInput } from "../DynamicSearchInput";
import LoadingPage from "../../../../layout/LoadingPage";
import { ReviewsBulkActions } from "./ReviewsBulkActions";
import { getAllQuestions, getAnotherPage, getReviews } from "../../api";
import { ColoredMultiSelectWithInfo } from "../../../../reusable/components/form-inputs/uncontrolled/ColoredMultiSelectWithInfo";
import { getKeyByValueInObject } from "../../../../helpers/General";

export const ReviewsManagement = ({ skeleton = false }) => {
  const {
    selectedFiltersState,
    dispatchActionToSelectedFilters,

    filtersOptionsState,

    applyFiltersFlag,
    applyFiltersFlagHandler,

    getAvailableFiltersOfCurrentTab,
  } = useContext(OverallViewContext);

  const [reviewsColumns, setReviewsColumns] = useState([]);

  const [loading, setLoading] = useState(true);
  const [currentPageReviewsRawData, setCurrentPageReviewsRawData] =
    useState(undefined);
  const [currentPageReviewsTableData, setCurrentPageReviewsTableData] =
    useState([]);
  const [metaData, setMetaData] = useState({});
  const [currentPageData, setCurrentPageData] = useState({
    local: 0,
    server: 0,
    totalServer: 0,
    totalLocal: 0,
    rowsPerPage: 10,
  });
  const [questionsData, setQuestionsData] = useState([]);
  const [error, setError] = useState({ isError: false, errorMessage: "" });
  const [bulkBookmarkStatus, setBulkBookmarkStatus] = useState(true);
  const [questionsDataToRender, setQuestionsDataToRender] = useState([]);

  const {
    selectedRows,
    dispatchSelectedRows,
    selectedIDs,
    allRowsSelected,
    rawSelectedRecords,
  } = useSelectedRows();

  const prepareAndSetReviewsResponseData = useCallback(
    (reviewsResponseData) => {
      let firstPage = reviewsResponseData.results.slice(
        0,
        currentPageData.rowsPerPage
      );

      setMetaData(reviewsResponseData);
      setCurrentPageReviewsRawData(firstPage);
      setLoading(false);
      setCurrentPageData((oldCurrentPageData) => ({
        ...oldCurrentPageData,
        local: 0,
        server: 0,
        totalServer: Math.ceil(
          reviewsResponseData["total_count"] / reviewsResponseData["page_size"]
        ),
        totalLocal: Math.ceil(
          reviewsResponseData["total_count"] / oldCurrentPageData.rowsPerPage
        ),
      }));
    },
    [currentPageData.rowsPerPage]
  );

  const getReviewsHandler = useCallback(
    (reviewsQueryStrings) => {
      setLoading(true);

      setError({
        isError: false,
      });

      getReviews({ ...reviewsQueryStrings })
        .then((reviewsResponse) => {
          if (reviewsResponse.results.length === 0) {
            setError({
              isError: true,
              errorMessage: "No Reviews found",
            });
          } else {
            prepareAndSetReviewsResponseData(reviewsResponse);
            setError({
              isError: false,
            });
          }
        })
        .catch((error) => {
          if (error) {
            setError({
              isError: true,
              errorMessage: "Network Error",
            });
          }
        });
    },
    [prepareAndSetReviewsResponseData]
  );

  const getAnotherPageHandler = (direction, newServerPage) => {
    setLoading(true);
    let url = direction === 1 ? metaData["next"] : metaData["previous"];
    if (!url) {
      return;
    }

    getAnotherPage(url)
      .then((data) => {
        let firstPage = data.results.slice(0, currentPageData.rowsPerPage);
        setMetaData(data);
        setCurrentPageReviewsRawData(firstPage);
        setLoading(false);
        setCurrentPageData({
          ...currentPageData,
          local:
            direction == 1
              ? 0
              : data["page_size"] / currentPageData.rowsPerPage - 1,
          server: newServerPage,
          totalServer: Math.ceil(data["total_count"] / data["page_size"]),
          totalLocal: Math.ceil(
            data["total_count"] / currentPageData.rowsPerPage
          ),
        });
      })
      .catch((error) => {
        if (error) {
          setError({
            isError: true,
            errorMessage: "Network Error",
          });
        }
      });
  };

  const changePageHandler = (direction) => {
    if (direction === 1) {
      // check if passed 50
      if (
        currentPageData.local <
          metaData["page_size"] / currentPageData.rowsPerPage - 1 &&
        currentPageData.local < currentPageData.totalLocal - 1
      ) {
        let nextLocalPage = currentPageData.local + 1;
        // not passed 50
        let nextPart = metaData.results.slice(
          nextLocalPage * currentPageData.rowsPerPage,
          nextLocalPage * currentPageData.rowsPerPage +
            currentPageData.rowsPerPage
        );
        setCurrentPageData({ ...currentPageData, local: nextLocalPage });
        setCurrentPageReviewsRawData(nextPart);
      } else {
        if (metaData["next"]) {
          // else if passed 50 and there are still pages in server
          let nextServerPage = currentPageData.server + 1;
          getAnotherPageHandler(1, nextServerPage);
        }
      }
    } else {
      if (currentPageData.local > 0) {
        let prevLocalPage = currentPageData.local - 1;
        // not on first page
        let prevPage = metaData.results.slice(
          prevLocalPage * currentPageData.rowsPerPage,
          prevLocalPage * currentPageData.rowsPerPage +
            currentPageData.rowsPerPage
        );
        setCurrentPageData({ ...currentPageData, local: prevLocalPage });
        setCurrentPageReviewsRawData(prevPage);
      } else {
        if (metaData["previous"]) {
          let prevServerPage = currentPageData.server - 1;
          getAnotherPageHandler(-1, prevServerPage);
        }
      }
    }
  };

  const getAllQuestionsHandler = () => {
    setLoading(true);

    return getAllQuestions({
      isMminimalized: true,
    })
      .then((response) => {
        setQuestionsData(response);
      })
      .catch((error) => console.log(error));
  };

  const updateSelectedReviewsHandler = useCallback(
    ({ page, review, add }) => {
      if (add) {
        // Manage bulk bookmark status (bookmark/unbookmark) - based on the first selected review
        if (selectedIDs.length === 0) {
          setBulkBookmarkStatus(!review.bookmarked);
        }
        dispatchSelectedRows({
          type: "ADD_ROW",
          payload: { page, record: review },
        });
      } else {
        dispatchSelectedRows({
          type: "REMOVE_ROW",
          payload: { page, record: review },
        });
      }
    },
    [dispatchSelectedRows, selectedIDs.length]
  );

  const selectAllReviewsInCurrentPage = ({ page, selectAll }) => {
    dispatchSelectedRows({
      type: "TOGGLE_SELECT_PAGE_ROWS",
      payload: {
        pageRowsData: currentPageReviewsTableData,
        recordProperty: "reviewRecord",
        selectAll,
        page,
      },
    });
  };

  const updateReviewsBookmarkState = ({ selectedIDs, bookmarked }) => {
    const updatedBookmarkStateInAllReviews = metaData.results.map((review) => {
      if (selectedIDs.includes(review.id)) {
        return { ...review, bookmarked };
      } else {
        return { ...review };
      }
    });

    const updatedBookmarkStateInReviewsCurrentPage =
      currentPageReviewsRawData.map((review) => {
        if (selectedIDs.includes(review.id)) {
          return { ...review, bookmarked };
        } else {
          return { ...review };
        }
      });

    setMetaData({
      ...metaData,
      results: [...updatedBookmarkStateInAllReviews],
    });
    setCurrentPageReviewsRawData([...updatedBookmarkStateInReviewsCurrentPage]);
  };

  const currentPageNumber =
    currentPageData.local +
    1 +
    currentPageData.server *
      (metaData["page_size"] / currentPageData.rowsPerPage);

  const updateSelectedChannelHandler = (event) => {
    dispatchActionToSelectedFilters({
      type: updateSelectedFiltersActions.updateSelectedChannel,
      payload: { channels: event.target.value },
    });

    applyFiltersFlagHandler(true);
  };

  const ReviewsSpecificFilters = () => {
    return (
      <ReusableTabSpecificFiltersContainer>
        <ColoredMultiSelectWithInfo
          label="Select Channel"
          labelSingular="Channel"
          width={180}
          options={[
            {
              label: "All Channels",
              value: channelsDict.All,
              resetOption: true,
            },
            { label: "On-Site", value: channelsDict["On-Site"] },
            { label: "Outbound", value: channelsDict.Outbound },
            {
              label: "Personalized Link",
              value: channelsDict["Personalized Link"],
            },
            { label: "QR Code", value: channelsDict["QR Code"] },
            { label: "General Link", value: channelsDict["General Link"] },
          ]}
          selectedValues={
            selectedFiltersState.channels
              ? selectedFiltersState.channels.split(",")
              : []
          }
          onChangeHandler={updateSelectedChannelHandler}
        />

        <ToogleButtonFilter
          label="Archived"
          selected={selectedFiltersState.archived}
          onChangeHandler={() => {
            dispatchActionToSelectedFilters({
              type: updateSelectedFiltersActions.toggleArchivedFlag,
            });

            applyFiltersFlagHandler(true);
          }}
          skeleton={skeleton}
        />
        <ToogleButtonFilter
          label="Starred"
          selected={selectedFiltersState.bookmarked}
          onChangeHandler={() => {
            dispatchActionToSelectedFilters({
              type: updateSelectedFiltersActions.toggleBookmarkedFlag,
            });

            applyFiltersFlagHandler(true);
          }}
          skeleton={skeleton}
        />
      </ReusableTabSpecificFiltersContainer>
    );
  };

  const searchReviewsHandler = ({ type, value }) => {
    const currentlySelectedFilters = { ...getAvailableFiltersOfCurrentTab() };

    currentlySelectedFilters[type] = value;

    getReviewsHandler({ ...currentlySelectedFilters });
  };

  const resetSearchHandler = () => {
    const currentlySelectedFilters = { ...getAvailableFiltersOfCurrentTab() };

    getReviewsHandler({ ...currentlySelectedFilters });
  };

  //   useLazyEffect(
  //     () => {
  //       dispatchSelectedRows({ type: "RESET" });
  //       const currentlySelectedFilters = { ...getAvailableFiltersOfCurrentTab() };
  //       getReviewsHandler({ ...currentlySelectedFilters });
  //     },
  //     [dispatchSelectedRows, getAvailableFiltersOfCurrentTab],
  //     200
  //   );

  useEffect(() => {
    getAllQuestionsHandler();
  }, []);

  /**
   * Refetch the Reviews when the applyFiltersFlag is true
   *  */
  useLazyEffect(
    () => {
      if (applyFiltersFlag) {
        getReviewsHandler({ ...getAvailableFiltersOfCurrentTab() });
        applyFiltersFlagHandler(false);
      }
    },
    [getAvailableFiltersOfCurrentTab, getReviewsHandler, applyFiltersFlag],
    200
  );

  // Prepare the Review Table's columns.
  useEffect(() => {
    const reviewsColumnsTemp = [
      // Multiselect Column
      {
        key: "multiselect",
        title: "Select all Reviews in Current Page",
        sticky: true,
        renderCustomComponent: (tableReviewRow) => {
          return (
            <Box display="flex" gap={1}>
              <Checkbox
                color="secondary"
                checked={
                  allRowsSelected
                    ? true
                    : selectedRows[currentPageNumber]
                    ? selectedRows[currentPageNumber].includes(
                        tableReviewRow.reviewRecord.id
                      )
                    : false
                }
                onChange={(event) =>
                  updateSelectedReviewsHandler({
                    page: currentPageNumber,
                    review: tableReviewRow.reviewRecord,
                    add: event.target.checked,
                  })
                }
              />
              <StarReview
                reviewRecord={tableReviewRow.reviewRecord}
                reviewsRawData={currentPageReviewsRawData}
                setReviewsRawData={setCurrentPageReviewsRawData}
              />
            </Box>
          );
        },
      },
      // Date Column
      {
        id: "submittedAndId",
        title: "Submitted At",
        label: "Submitted At",
        sticky: true,
        // sticky: true,
        renderCustomComponent: (tableReviewRow) => {
          const reviewDatetime = moment(
            tableReviewRow.reviewRecord?.submitted_at
          ).format("Do MMM YY - h:mm a");

          const reviewDate = reviewDatetime.split("-")[0];
          const reviewTime = reviewDatetime.split("-")[1];

          return (
            <Box
              sx={{}}
              fontSize="14px"
              fontFamily="Avenir-Heavy"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <span style={{ color: COLORS.brightRed }}>
                {tableReviewRow.reviewRecord?.id || "—"}
              </span>
              <Typography sx={{ fontSize: "inherit", fontFamily: "inherit" }}>
                {reviewDate}
              </Typography>
              <Typography sx={{ fontSize: "inherit", fontFamily: "inherit" }}>
                {reviewTime}
              </Typography>
            </Box>
          );
        },
      },
      {
        key: "template",
        title: "Template",
        label: "Template",
        sticky: true,
      },
      {
        key: "channel",
        title: "Channel",
        label: "Channel",
        sticky: true,
        renderCustomComponent: (tableReviewRow) => {
          let channelName = "";
          const channelValue = tableReviewRow.reviewRecord.channel;

          if (channelValue.includes(channelsDict["General Link"]))
            channelName = getKeyByValueInObject(
              channelsDict,
              channelsDict["General Link"]
            );
          if (channelValue.includes(channelsDict["On-Site"]))
            channelName = getKeyByValueInObject(
              channelsDict,
              channelsDict["On-Site"]
            );
          if (channelValue.includes(channelsDict["Personalized Link"]))
            channelName = getKeyByValueInObject(
              channelsDict,
              channelsDict["Personalized Link"]
            );
          if (channelValue.includes(channelsDict["Outbound"]))
            channelName = getKeyByValueInObject(
              channelsDict,
              channelsDict["Outbound"]
            );
          if (channelValue.includes(channelsDict["QR Code"]))
            channelName = getKeyByValueInObject(
              channelsDict,
              channelsDict["QR Code"]
            );
          return (
            <Tooltip title={<h3>{channelValue}</h3>}>
              <p>{channelName}</p>
            </Tooltip>
          );
        },
      },
      {
        key: "division",
        title: "Division",
        label: "Division",
        sticky: true,
        lastSticky: true,
      },
    ];

    let selectedQuestionsInFilter = [];

    if (selectedFiltersState.questions !== undefined) {
      selectedQuestionsInFilter = selectedFiltersState.questions.split(",");
    }

    const existingQuestionsOptions = filtersOptionsState.questionsOptions;

    const tempQuestionsDataToRender = questionsData.filter((question) =>
      selectedQuestionsInFilter.length > 0 &&
      selectedQuestionsInFilter[0] !== ""
        ? selectedQuestionsInFilter.includes(question.id.toString())
        : existingQuestionsOptions.filter(
            (questionOption) =>
              questionOption.has_data && questionOption.value === question.id
          ).length === 1
    );

    tempQuestionsDataToRender.forEach((question) => {
      let answers = {};

      question.choices.forEach((choiceRecord) => {
        answers[choiceRecord.id] = {
          text: choiceRecord.text,
          color: choiceRecord.color,
        };
      });
      reviewsColumnsTemp.push({
        key: question.id,

        // Temp
        id: question.id,

        label: `${question.text.split(" ").slice(0, 3).join(" ")}${
          question.text.split(" ").length > 3 ? "..." : ""
        }`,
        title: question.text,
        answers: answers,
        renderCustomComponent: (tableReviewRow) => {
          return (
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Avenir-Heavy",
                color:
                  tableReviewRow.selectedChoices[question.id]?.color || "black",
              }}
            >
              {tableReviewRow.selectedChoices[question.id]?.text || "—"}
            </Typography>
          );
        },
      });
    });

    // Actions Column
    reviewsColumnsTemp.push({
      id: "info",
      title: "Info",
      label: "Info",
      lastSticky: true,
      renderCustomComponent: (tableReviewRow) => {
        return (
          <Box
            fontSize="14px"
            fontFamily="Avenir-Heavy"
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <ReviewActivityLogDialog
                reviewRecord={tableReviewRow.reviewRecord}
                currentPageReviewsRawData={currentPageReviewsRawData}
                setCurrentPageReviewsRawData={setCurrentPageReviewsRawData}
              />
              <ContactsDialog
                rawRecord={tableReviewRow.reviewRecord}
                contactsFor="Review"
              />
            </Box>
          </Box>
        );
      },
    });

    setQuestionsDataToRender([...tempQuestionsDataToRender]);
    setReviewsColumns([...reviewsColumnsTemp]);
  }, [
    currentPageNumber,
    questionsData,
    currentPageReviewsRawData,
    selectedRows,
    updateSelectedReviewsHandler,
    allRowsSelected,
    selectedFiltersState.questions,
    filtersOptionsState.questionsOptions,
  ]);

  // Re-prepare Reviews Table Data upon change of questions retrieved.
  useEffect(() => {
    if (currentPageReviewsRawData) {
      const currentPageReviewsTableDataTemp = prepareReviewDataForTableView(
        currentPageReviewsRawData,
        questionsData,
        selectedRows,
        currentPageNumber
      );
      setCurrentPageReviewsTableData([...currentPageReviewsTableDataTemp]);
    }
  }, [
    currentPageNumber,
    questionsData,
    currentPageReviewsRawData,
    selectedRows,
  ]);

  // Selected IDs overlap between Reviews/Complaints - So we do a reset on mount
  useEffect(() => {
    dispatchSelectedRows({ type: "RESET" });
  }, [dispatchSelectedRows]);

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={2}
        alignItems="center"
        width="100%"
      >
        <ReviewsSpecificFilters />
        <DynamicSearchInput
          initiateSearchCallback={searchReviewsHandler}
          resetSearchCallback={resetSearchHandler}
          skeleton={skeleton}
        />
      </Box>
      <Box display="flex" justifyContent="center" width="100%">
        {error.isError ? (
          <p
            style={{
              textAlign: "center",
              marginTop: "40px",
              color: COLORS.failRed,
            }}
          >
            {error.errorMessage}
          </p>
        ) : loading ? (
          <LoadingPage />
        ) : (
          <Box display="flex" flexDirection="column" gap={2} width="100%">
            <ReviewsBulkActions
              questionsData={questionsData}
              // disabled={!selectedTemplate}
              rawSelectedReviews={rawSelectedRecords}
              reviewsAPIQueryStrings={{ ...getAvailableFiltersOfCurrentTab() }}
              selectedIDs={selectedIDs}
              bulkBookmarkStatus={bulkBookmarkStatus}
              resetSelectedRows={() => dispatchSelectedRows({ type: "RESET" })}
              updateReviewsBookmarkState={updateReviewsBookmarkState}
              reviewsTotalRecords={metaData.total_count}
              selectAllRows={() =>
                dispatchSelectedRows({
                  type: "SELECT_ALL_ROWS",
                  payload: { existingRecords: metaData.results },
                })
              }
              allRowsSelected={allRowsSelected}
              questionsDataToRender={questionsDataToRender}
            />

            {!skeleton && (
              <MeliorTable
                columnsData={reviewsColumns}
                tableData={currentPageReviewsTableData}
                changePageHandler={changePageHandler}
                currentPageData={currentPageData}
                serverPageSize={metaData["page_size"]}
                totalCount={metaData["total_count"]}
                recordKey="reviewRecord"
                selectCurrentPageAllRowsHandler={(event) =>
                  selectAllReviewsInCurrentPage({
                    page: currentPageNumber,
                    selectAll: event.target.checked,
                  })
                }
                currentPageSelected={
                  selectedRows[currentPageNumber] &&
                  selectedRows[currentPageNumber].length ===
                    currentPageReviewsTableData.length
                }
                allRowsSelected={allRowsSelected}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const prepareReviewDataForTableView = (
  reviewRawData,
  questionsData,
  selectedReviews,
  currentPageNumber
) => {
  const preparedReviewDataForTableView = [];

  reviewRawData.forEach((reviewRecord) => {
    const preparedReviewRecordForTable = {};

    reviewRecord.answers.forEach((questionAnswerInReview) => {
      const questionForSelectedReview = questionsData.filter(
        (questionRecord) =>
          questionRecord.id === questionAnswerInReview.question
      )[0];

      const questionChoiceDataForSelectedReview =
        questionForSelectedReview?.choices.filter(
          (questionChoice) =>
            questionChoice.id === questionAnswerInReview.choice
        )[0];

      preparedReviewRecordForTable[questionAnswerInReview.question] = {
        text: questionChoiceDataForSelectedReview?.text || "—",
        color: questionChoiceDataForSelectedReview?.color || "—",
      };
    });

    const rowConditionalStyles = {
      //   backgroundColor:
      //     selectedReviews[currentPageNumber] &&
      //     selectedReviews[currentPageNumber].includes(reviewRecord.id)
      //       ? COLORS.silver
      //       : undefined,
      //   borderLeft: `10px solid ${reviewRecord.branch.color}`,
    };

    preparedReviewDataForTableView.push({
      selectedChoices: preparedReviewRecordForTable,
      rowConditionalStyles,
      reviewRecord: reviewRecord,
      branchName: reviewRecord.branch.name,
    });
  });

  return preparedReviewDataForTableView;
};

export const ToogleButtonFilter = ({
  label,
  selected,
  onChangeHandler,
  skeleton = false,
}) => {
  return skeleton ? (
    <Skeleton variant="rectangular" width={100} height={30} />
  ) : (
    <ToggleButton
      //   value="check"
      selected={selected}
      onChange={onChangeHandler}
      sx={{
        padding: "0.3rem",
        px: "14px",
        height: "35px",
        color: !selected && COLORS.grey,
      }}
    >
      <Typography sx={{ fontSize: "0.9rem", textTransform: "capitalize" }}>
        {label}
      </Typography>
    </ToggleButton>
  );
};

function useLazyEffect(effect, deps = [], wait = 300) {
  const cleanUp = useRef();
  const effectRef = useRef();
  const updatedEffect = useCallback(effect, deps);
  effectRef.current = updatedEffect;
  const lazyEffect = useCallback(
    debounce(() => {
      cleanUp.current = effectRef.current?.();
    }, wait),
    []
  );
  useEffect(lazyEffect, deps);
  useEffect(() => {
    return () => (cleanUp.current ? cleanUp.current() : undefined);
  }, []);
}

export const channelsDict = {
  All: "",
  "On-Site": "on-site",
  Outbound: "outbound",
  "Personalized Link": "personalized-link",
  "QR Code": "qr-code",
  "General Link": "general-link",
};
