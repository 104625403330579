import {
  Box,
  Button,
  Grid,
  Input,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  selectedTemplateScreenSectionTypes,
  staticNavigationMenuKeys,
  templateGroupStateManagementActions,
  templateViewGroupLanguages,
} from "./TemplateGroupManagementPage";
import { FormTextarea } from "../../../../reusable/components/form-inputs/controlled/FormTextarea";
import { KeyboardVoice } from "@mui/icons-material";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import UserErrorSuccessFormDisplay from "../../../../reusable/components/UserErrorSuccessFormDisplay";
import { useState } from "react";
import { getAnimation } from "../../../online-survey/api/getAnimation";
import Lottie from "react-lottie";
import { useEffect } from "react";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";

export const TemplateGroupView = ({ templateGroupStateManagement }) => {
  const currentViewLanguage = templateGroupStateManagement.state.viewLanguage;

  const screenSelected =
    templateGroupStateManagement.state.selectedScreen.type !== undefined;

  return (
    <Grid
      container
      spacing={2}
      bgcolor={primaryColors.gray[200]}
      width="100%"
      height="109vh"
      boxSizing="border-box"
    >
      <Grid item md={8} display="flex" justifyContent="end">
        <Box
          width="392px"
          height="80vh"
          mt={5}
          bgcolor={primaryColors.base.white}
          borderRadius={2}
          paddingY="16px"
          paddingX="16px"
          boxSizing="border-box"
        >
          {useRenderTemplateGroupScreenLayout({
            screenType: determineScreenTypeUsingSelectedScreenKey(
              templateGroupStateManagement.state.selectedScreen.key
            ),
            viewData:
              templateGroupStateManagement.state.selectedScreen.viewData,
            currentViewLanguage,
            screenSelected,
          })}
        </Box>
      </Grid>
      <Grid item md={4} pr={5} display="flex" justifyContent="end">
        <Box display="flex" flexDirection="column" gap={2} mt={5}>
          <Button
            variant="contained"
            sx={{
              width: "47px",
              height: "36px",
              borderRadius: 2,
              boxShadow: "none",
              bgcolor:
                currentViewLanguage === templateViewGroupLanguages.Arabic
                  ? primaryColors.brand[500]
                  : primaryColors.brand[200],
            }}
            onClick={function setArabicAsCurrentTemplateGroupScreenLanguage() {
              templateGroupStateManagement.dispatcher({
                type: templateGroupStateManagementActions.updateViewLanguage,
                payload: { viewLanguage: templateViewGroupLanguages.Arabic },
              });
            }}
          >
            <Typography
              variant="text-sm"
              color={primaryColors.base.white}
              fontWeight="bold"
            >
              AR
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "47px",
              height: "36px",
              borderRadius: 2,
              boxShadow: "none",
              bgcolor:
                currentViewLanguage === templateViewGroupLanguages.English
                  ? primaryColors.brand[500]
                  : primaryColors.brand[200],
            }}
            onClick={function setEnglishAsCurrentTemplateGroupScreenLanguage() {
              templateGroupStateManagement.dispatcher({
                type: templateGroupStateManagementActions.updateViewLanguage,
                payload: { viewLanguage: templateViewGroupLanguages.English },
              });
            }}
          >
            <Typography
              variant="text-sm"
              color={primaryColors.base.white}
              fontWeight="bold"
            >
              EN
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const screenTypes = {
  welcomeScreenPage: "WELCOME-SCREEN-PAGE",
  question: "QUESTION",
  detailedScreenPage: "DETAILED-SCREEN-PAGE",
  endingScreenPage: "ENDING-SCREEN-PAGE",
};

function determineScreenTypeUsingSelectedScreenKey(selectedScreenKey) {
  const isFromReviewSection = selectedScreenKey?.includes(
    selectedTemplateScreenSectionTypes.reviewSection
  );

  const isFromComplaintSection = selectedScreenKey?.includes(
    selectedTemplateScreenSectionTypes.complaintSection
  );

  const isWelcomeSectionStaticPage = selectedScreenKey?.includes(
    staticNavigationMenuKeys.welcomeScreen
  );
  const isDetailedSectionStaticPage = selectedScreenKey?.includes(
    staticNavigationMenuKeys.detailedScreen
  );
  const isEndingSectionStaticPage = selectedScreenKey?.includes(
    staticNavigationMenuKeys.endingScreen
  );

  const isFromWelcomeSectionButNotPage =
    selectedScreenKey?.includes(
      selectedTemplateScreenSectionTypes.welcomeSection
    ) && !isWelcomeSectionStaticPage;
  if (
    isFromReviewSection ||
    isFromComplaintSection ||
    isFromWelcomeSectionButNotPage
  ) {
    return screenTypes.question;
  }

  if (isWelcomeSectionStaticPage) {
    return screenTypes.welcomeScreenPage;
  }
  if (isDetailedSectionStaticPage) {
    return screenTypes.detailedScreenPage;
  }
  if (isEndingSectionStaticPage) {
    return screenTypes.endingScreenPage;
  }
}

function useRenderTemplateGroupScreenLayout({
  screenType,
  viewData,
  currentViewLanguage,
  screenSelected,
}) {
  const isArabic = currentViewLanguage === templateViewGroupLanguages.Arabic;

  const [animationFile, setAnimationFile] = useState(null);

  const getFile = (fileURL) => {
    getAnimation(fileURL)
      .then((response) => {
        setAnimationFile(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const renderAnimation = ({ mediaType, mediaLink, animationFile }) => {
    if (mediaType && mediaLink) {
      if (mediaType === "animation") {
        return (
          <Lottie
            options={{ animationData: animationFile }}
            width={"330px"}
            // height={"182px"}
          />
        );
      } else if (mediaType === "svg") {
        return (
          <img
            src={mediaLink}
            style={{
              //   height: "182px",
              width: "330px",
            }}
            alt="animationSVG"
          />
        );
      }
    }

    return null;
  };

  useEffect(() => {
    if (
      viewData &&
      viewData[currentViewLanguage] &&
      viewData[currentViewLanguage].media_type &&
      viewData[currentViewLanguage].media_link
    );

    // console.log(
    //   props.data["media_link"],
    //   props.data["media_type"],
    //   'props.data["media_link"]'
    // );
    if (
      screenSelected &&
      viewData[currentViewLanguage].media_type === "animation"
    ) {
      getFile(viewData[currentViewLanguage].media_link);
    }
  }, [currentViewLanguage, screenSelected, viewData]);

  if (!screenSelected) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Typography
          variant="display-xs"
          color={primaryColors.gray[400]}
          fontWeight="bold"
        >
          Select a Screen
        </Typography>
      </Box>
    );
  }

  switch (screenType) {
    case screenTypes.welcomeScreenPage: {
        console.log(viewData[currentViewLanguage], 'whyyyyyyyyy')
      return (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* image */}
          <Box display="flex" flexDirection="column" textAlign="center">
            <img
              src={viewData[currentViewLanguage].image}
              width="360px"
              height="182px"
              alt="welcome-screen-logo"
              style={{ marginTop: "24px", marginBottom: "20px" }}
            />
            <Typography
              variant="display-xs"
              color={primaryColors.gray[400]}
              sx={{ marginBottom: "20px" }}
            >
              {viewData[currentViewLanguage].mainText}
            </Typography>
            <Typography variant="text-xl" color={primaryColors.gray[400]}>
              {viewData[currentViewLanguage].subText}
            </Typography>
          </Box>

          <Button
            variant="contained"
            width="100%"
            sx={{ width: "100%", marginBottom: "24px", textTransform: "none" }}
          >
            <Typography variant="text-md">
              {currentViewLanguage === templateViewGroupLanguages.English
                ? "Start"
                : "إبدأ"}
            </Typography>
          </Button>
        </Box>
      );
    }
    case screenTypes.question: {
      console.log(viewData[currentViewLanguage], "question");
      return (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* image */}
          <Box display="flex" flexDirection="column" textAlign="center">
            {/* <img
              src={viewData[currentViewLanguage].media_link}
              width="360px"
              height="182px"
              alt="welcome-screen-logo"
              style={{ marginTop: "24px", marginBottom: "20px" }}
            /> */}
            {renderAnimation({
              mediaType: viewData[currentViewLanguage].media_type,
              mediaLink: viewData[currentViewLanguage].media_link,
              animationFile,
            })}

            {renderQuestion({
              questionData: viewData[currentViewLanguage],
              currentViewLanguage,
            })}
          </Box>
          {/* 
          <Button
            variant="contained"
            width="100%"
            sx={{ width: "100%", marginBottom: "24px", textTransform: "none" }}
          >
            <Typography رشقهشىف="text-md">
              {currentViewLanguage === templateViewGroupLanguages.English
                ? "Start"
                : "إبدأ"}
            </Typography>
          </Button> */}
        </Box>
      );
    }
    case screenTypes.detailedScreenPage: {
      console.log(viewData[currentViewLanguage], "detailedScreenPage");
      return (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          px="16px"
          gap={2}
        >
          {viewData[currentViewLanguage].questions.map(
            function renderQuestionForDetailedSection(questionData) {
              return renderQuestion({ questionData, currentViewLanguage });
            }
          )}
          {viewData[currentViewLanguage].showVoiceNote &&
            renderQuestion({
              questionData: {
                answer_type: "voice_note",
                text: viewData[currentViewLanguage].voiceNoteLabel,
              },
              currentViewLanguage,
            })}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="text-xl"
                color={primaryColors.gray[900]}
                textAlign={isArabic ? "right" : "left"}
              >
                {isArabic ? "معلومات شخصية" : "Personal Information"}
              </Typography>
              {viewData[currentViewLanguage].personalInfo.questions.map(
                function renderPersonalInfoQuestion(personalInfoQuestion) {
                  return renderQuestion({
                    questionData: personalInfoQuestion,
                    currentViewLanguage,
                  });
                }
              )}
            </Box>
            <Box display="flex" flexDirection="column" mb="24px" gap={2}>
              <UserErrorSuccessFormDisplay
                style={{
                  background: "transparent",
                  width: "90%",
                }}
                width={"100%"}
                fontSize={fontSizeVariations["text-sm"]}
                bg={primaryColors.warning[25]}
                color={primaryColors.warning[700]}
                type="disclaimer"
                message={viewData[currentViewLanguage].personalInfo.disclaimer}
                language={isArabic}
              />
              <Button
                variant="contained"
                width="100%"
                sx={{
                  width: "100%",
                  textTransform: "none",
                }}
              >
                <Typography رشقهشىف="text-md">
                  {currentViewLanguage === templateViewGroupLanguages.English
                    ? "Submit"
                    : "إبعت"}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }
    case screenTypes.endingScreenPage: {
      console.log(viewData[currentViewLanguage], "endingScreenPage");
      return (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          alignItems="center"
        >
          {/* image */}
          <Box
            display="flex"
            flexDirection="column"
            textAlign="center"
            mt="120px"
          >
            <img
              src={viewData[currentViewLanguage].image}
              width="360px"
              height="182px"
              alt="welcome-screen-logo"
              style={{ marginTop: "24px", marginBottom: "20px" }}
            />
            <Typography
              variant="display-xs"
              color={primaryColors.gray[400]}
              sx={{ marginBottom: "20px" }}
            >
              {viewData[currentViewLanguage].mainText}
            </Typography>
            <Typography variant="text-xl" color={primaryColors.gray[400]}>
              {viewData[currentViewLanguage].subText}
            </Typography>
          </Box>
        </Box>
      );
    }
    default: {
      return null;
    }
  }
}

const answerTypes = {
  longText: "long_text",
  multipleChoices: "multiple_choices",
  NPS: "nps",
  voiceNote: "voice_note",
  shortText: "short_text",
  number: "numbers",
  phoneNumber: "phone_number",
};

function renderQuestion({ questionData, currentViewLanguage }) {
  const answerType = questionData.answer_type;
  const isArabic = currentViewLanguage === templateViewGroupLanguages.Arabic;

  switch (answerType) {
    case answerTypes.longText: {
      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            variant="text-xl"
            color={primaryColors.gray[900]}
            textAlign={isArabic ? "right" : "left"}
          >
            {questionData.text}
          </Typography>
          <TextField
            helperText={""}
            placeholder={questionData.details.placeholder}
            size="small"
            multiline
            rows={4}
            variant="outlined"
          />
        </Box>
      );
    }
    case answerTypes.shortText: {
      return (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="text-sm" textAlign={isArabic ? "right" : "left"}>
            {questionData.text}
          </Typography>
          <TextField
            placeholder={questionData.details.placeholder}
            size="medium"
            variant="outlined"
            required={questionData.required}
            fullWidth
            sx={{
              "& .MuiInputBase-root": {
                "& input": {
                  textAlign: isArabic ? "right" : "left",
                  fontFamily: isArabic ? "BalooBhaijaan2-medium" : "Nunito",
                },
              },
            }}
          />
        </Box>
      );
    }
    case answerTypes.number: {
      return (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="text-sm" textAlign={isArabic ? "right" : "left"}>
            {questionData.text}
          </Typography>
          <TextField
            label={questionData.text}
            placeholder={questionData.details.placeholder}
            size="medium"
            variant="outlined"
            required={questionData.required}
            type="number"
            fullWidth
            sx={{
              "& .MuiInputBase-root": {
                "& input": {
                  textAlign: isArabic ? "right" : "left",
                  fontFamily: isArabic ? "BalooBhaijaan2-medium" : "Nunito",
                },
              },
            }}
          />
        </Box>
      );
    }
    case answerTypes.phoneNumber: {
      return (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="text-sm" textAlign={isArabic ? "right" : "left"}>
            {questionData.text}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            border={`1px solid ${primaryColors.gray[300]}`}
            borderRadius="5px"
            px="10px"
            py="12px"
          >
            <Typography variant="text-md" color={primaryColors.gray[300]}>
              +2 0XXX XXX XXXX
            </Typography>
          </Box>
        </Box>
      );
    }
    case answerTypes.multipleChoices: {
      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            variant="text-xl"
            color={primaryColors.gray[900]}
            textAlign={isArabic ? "right" : "left"}
          >
            {questionData.text}
          </Typography>
          <Grid container direction="row" alignItems="center">
            {questionData.choices.map((choice, i) => (
              <Grid
                item
                key={i}
                xs={questionData.choices.length === 2 ? 6 : 12}
                display="flex"
                alignItems="center"
                gap={0.5}
                width="100%"
              >
                {choice.emoji && (
                  <img
                    src={choice.emoji}
                    alt={choice.text}
                    style={{
                      width: "40px",
                      height: "auto",
                      margin: "5px 10px",
                      order: isArabic ? 2 : 1,
                    }}
                  />
                )}
                <Button
                  key={"source" + choice.id}
                  sx={{
                    padding: "10px 8px",
                    margin: "5px",
                    borderRadius: "6px",
                    width: "100%",
                    height: "44px",
                    bgcolor: choice.color,
                    "&:hover": {
                      bgcolor: choice.color,
                    },
                    order: isArabic ? 1 : 2,
                  }}
                >
                  <Typography
                    variant="text-md"
                    sx={{
                      fontFamily: isArabic ? "BalooBhaijaan2-medium" : "Nunito",
                      fontWeight: fontWeightVariations.medium,
                      color: primaryColors.gray[800],
                      width: "100%",
                      textAlign: isArabic ? "right" : "left",
                      textTransform: "none",
                    }}
                  >
                    {choice.text}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }
    case answerTypes.NPS: {
      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            variant="text-xl"
            color={primaryColors.gray[900]}
            textAlign={isArabic ? "right" : "left"}
          >
            {questionData.text}
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="end"
            height="fit-content"
            gap={1}
          >
            {questionData.choices?.map((choice, index) => {
              const firstChoice = index === 0;
              const lastChoice = index === questionData.choices.length - 1;

              return (
                <Box display="flex" flexDirection="column" mt="20px">
                  {firstChoice && (
                    <Typography
                      variant="text-xs"
                      sx={{
                        fontFamily: isArabic
                          ? "BalooBhaijaan2-medium"
                          : "Nunito",
                        fontWeight: fontWeightVariations.semiBold,
                        color: primaryColors.gray[800],
                        width: "100%",
                        textAlign: isArabic ? "right" : "left",
                        textTransform: "none",
                      }}
                    >
                      {isArabic ? "سيئة جدا" : "Least Likely"}
                    </Typography>
                  )}
                  {lastChoice && (
                    <Typography
                      variant="text-xs"
                      sx={{
                        fontFamily: isArabic
                          ? "BalooBhaijaan2-medium"
                          : "Nunito",
                        fontWeight: fontWeightVariations.semiBold,
                        color: primaryColors.gray[800],
                        width: "100%",
                        textAlign: isArabic ? "right" : "left",
                        textTransform: "none",
                      }}
                    >
                      {isArabic ? "ممتاز" : "Most Likely"}
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    key={choice.id}
                    sx={{
                      height: "56px",
                      width: "56px",
                      left: "0px",
                      top: "22px",
                      borderRadius: "8px",
                      padding: "12px 20px",
                      position: "unset",
                      bgcolor: firstChoice
                        ? primaryColors.error[100]
                        : lastChoice
                        ? primaryColors.success[100]
                        : primaryColors.gray[100],
                      "&:hover": {
                        bgcolor: firstChoice
                          ? primaryColors.error[100]
                          : lastChoice
                          ? primaryColors.success[100]
                          : primaryColors.gray[100],
                      },
                    }}
                    size="small"
                  >
                    <Typography
                      variant="display-xs"
                      sx={{
                        fontFamily: "Nunito",
                        fontWeight: fontWeightVariations.semiBold,
                        color: primaryColors.gray[800],
                        width: "100%",
                        textAlign: "center",
                        textTransform: "none",
                      }}
                    >
                      {choice.text}
                    </Typography>
                  </Button>
                </Box>
              );
            })}
          </Box>
        </Box>
      );
    }
    case answerTypes.voiceNote: {
      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="text-sm" textAlign={isArabic ? "right" : "left"}>
            {questionData.text}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            border={`1px solid ${primaryColors.gray[300]}`}
            borderRadius="5px"
            px="20px"
            py="12px"
          >
            <KeyboardVoice sx={{ color: primaryColors.brand[500] }} />
            <Box display="flex" justifyContent="center" width="100%">
              <Typography
                variant="text-sm"
                color={primaryColors.gray[800]}
                textAlign="center"
              >
                00:00
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
    default: {
      return null;
    }
  }
}
