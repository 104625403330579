import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { primaryColors } from "../../../../helpers/customColors";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

export const FormDropdown = ({
  id,
  name,
  label,
  options,
  enableSearch = false,
  sx,
  menuProps,
  width = undefined,
  defaultValueByLabel,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  let [searchText, setSearchText] = useState("");

  const errorMessage = errors[name] ? errors[name].message : null;

  return (
    <FormControl
      size="small"
      error={errorMessage !== null}
      style={{
        width: width ? width : "100%",
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: fieldProps }) => {
          return (
            <Select
              id={id}
              {...fieldProps}
              value={fieldProps.value ? fieldProps.value : ""}
              label={label}
              sx={sx}
              MenuProps={menuProps}
            >
              {enableSearch && SearchField(setSearchText)}
              {options.map((option, index) => {
                if (option.label.toLowerCase().includes(searchText))
                  return (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
              })}
            </Select>
          );
        }}
        control={control}
        name={name}
      />
      <Box display={"block"}>
        <FormHelperText style={{ color: "red" }}>
          {errorMessage ? errorMessage : ""}
        </FormHelperText>
      </Box>
    </FormControl>
  );
};

const SearchField = (setSearchText) => {
  return (
    <ListSubheader>
      <TextField
        autoFocus
        size="small"
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color={primaryColors.gray[500]} />
            </InputAdornment>
          ),
        }}
        sx={{
          color: primaryColors.gray[500],
          borderColor: primaryColors.gray[300],
          borderRadius: "8px",
          gap: "8px",
          width: "95%",
          marginInline: "2.5%",
        }}
        onChange={(e) => setSearchText(e.target.value.toLowerCase())}
        onKeyDown={(e) => {
          if (e.key !== "Escape") {
            e.stopPropagation();
          }
        }}
      />
    </ListSubheader>
  );
};
