import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { API_URL, melior } from "../../../helpers/constants";
import {
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NotificationContext,
  NOTIFICATIONS_HORIZONTAL_POSITION,
} from "../../../contexts/NotificationContext";

export const editTemplateGroupName = ({
  templateGroupID,
  editedTemplateGroupName,
}) => {
  let requestURL = `${API_URL}/template-group/${templateGroupID}`;

  return melior.patch(requestURL, {
    name: editedTemplateGroupName,
  });
};

export const useEditTemplateGroupName = ({ config } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["template-groups"], { type: "active" });
      fireNotification({
        title: "Template Group Name has been updated Successfully",
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: (error) => {
      console.error(error.response);
      fireNotification({
        title: "Failed to update Template Group name, please try again later",
        description: error.response.data.detail,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: editTemplateGroupName,
  });
};
