import { Box, Typography } from "@mui/material";
import { primaryColors } from "../../helpers/customColors";

export const TableContainer = ({
  label,
  tableActionsNextToName,
  tableFiltersBelowName,
  children,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius={3}
      border={`1px solid ${primaryColors.gray[200]}`}
      width="100%"
      bgcolor={primaryColors.base.white}
      pb="1rem"
    >
      <Box
        px={"24px"}
        py={"28px"}
        borderBottom={`1px solid ${primaryColors.gray[100]}`}
      >
        <Box display="flex" flexDirection="column">
          {/* Start: Table Label and right Actions */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="text-lg" fontWeight="bold">
              {label}
            </Typography>
            {tableActionsNextToName}
          </Box>
          {/* End: Table Label and right Actions */}
          {tableFiltersBelowName && (
            <Box
              pt={2}
              mt={3}
              borderTop={`1px solid ${primaryColors.gray[200]}`}
            >
              {tableFiltersBelowName}
            </Box>
          )}
        </Box>
      </Box>
      {children}
    </Box>
  );
};
