import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import {
  FormDropdown,
  FormMultiSelect,
  FormTextInput,
} from "../../../../reusable/components/form-inputs/controlled";
import { useEffect, useState, useContext } from "react";
import {
  useGetSilentTimes,
  useGetIssues,
  useEditMemberResponsibilities,
} from "../../api/members";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../contexts/NotificationContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useGetAllBranchesWithoutPagination } from "../../api/branches-divisions/getAllBranchesWithoutPagination";

export const AllMembersTableEditDetails = ({ memberDetails }) => {
  const submitting = false;
  const [allBranchesData, setAllBranchesData] = useState([]);
  const { fireNotification } = useContext(NotificationContext);

  const validationSchema = yup.object({
    // phone_number: yup
    //   .string()
    //   .matches(
    //     /(201)[0-2,5]{1}[0-9]{8}/,
    //     "Please enter a valid phone number, it must have 12 numbers and starts with 20"
    //   )
    //   .max(
    //     12,
    //     "Please enter a valid phone number, it must have 12 numbers and starts with 20"
    //   ),
    issues: yup.array(),
    silent_time: yup.string(),
    divisions: yup
      .array()
      .min(1, "select at least one division")
      .required("select at least one division"),
  });
  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    defaultValues: {
      phone_number: memberDetails.phone_number
        ? memberDetails.phone_number
        : "",
      issues: memberDetails.wa_profile ? memberDetails.wa_profile.issues : [],
      silent_time: memberDetails.wa_profile
        ? memberDetails.wa_profile.silent_time
        : "",
      divisions: memberDetails.divisions.map((divisionData) => divisionData.id),
    },
  });
  const { reset, handleSubmit, watch, formState } = reactHookFormMethods;

  const { data: allBranchesResponse, isLoading: isLoadingAllBranches } =
    useGetAllBranchesWithoutPagination();

  const { data: issuesResponse, isLoading: isLoadingIssues } = useGetIssues();
  const { data: silentTimesResponse, isLoading: isLoadingSilentTimes } =
    useGetSilentTimes();

  const editMemberInfoMutation = useEditMemberResponsibilities();
  const editMemberInfoHandler = (memberData) => {
    editMemberInfoMutation
      .mutateAsync({
        memberID: memberDetails.id,
        body: {
          divisions: memberData.divisions,
          phone_number: memberData.phone_number,
          wa_profile: {
            issues: memberData.issues,
            silent_time: memberData.silent_time,
          },
        },
      })
      .then(() => {
        fireNotification({
          title: "Member info updated successfully",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      })
      .catch(() => {
        fireNotification({
          title: "An error occurred",
          description: "Please try again later",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      });
  };

  useEffect(() => {
    //get branches and divisions
    if (allBranchesResponse && !isLoadingAllBranches) {
      let tempAllBranchesData = [];

      allBranchesResponse.data.forEach(function getBranchesAsOptions(
        branchData
      ) {
        tempAllBranchesData.push({
          value: branchData.id,
          isBranch: true,
          label: branchData.name,
        });

        if (branchData.divisions.length > 0) {
          branchData.divisions.forEach(function getDivisionsAsFilterOptions(
            divisionData
          ) {
            tempAllBranchesData.push({
              value: divisionData.id,
              label: divisionData.name,
            });
          });
        }
      });
      setAllBranchesData([...tempAllBranchesData]);
    }
  }, [allBranchesResponse, isLoadingAllBranches]);

  return (
    <FormProvider {...reactHookFormMethods}>
      <form method="POST" onSubmit={handleSubmit(editMemberInfoHandler)}>
        <Grid
          container
          bgcolor={primaryColors.base.white}
          display="flex"
          alignItems="flex-start"
          padding="24px"
          gap="40px"
          height="fit-content"
          columns={12}
          width="100%"
        >
          {/* phone number */}
          <Grid item xs={3} display="flex" flexDirection="column" gap="16px">
            <Typography
              sx={{
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations["bold-700"],
                color: primaryColors.gray[700],
              }}
            >
              Phone Number
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Whatsapp Notifications.
                    <br />
                    By providing your number, you will receive WhatsApp
                    notifications with a complaint link and optional voice note
                    whenever you have a complaint.
                  </div>
                }
                placement="top"
              >
                <HelpOutlineIcon
                  sx={{
                    height: "16px",
                    width: "16px",
                    color: primaryColors.gray[400],
                    marginInline: "8px",
                    marginBlock: "auto",
                  }}
                />
              </Tooltip>
            </Typography>

            <FormTextInput
              name="phone_number"
              placeholder="+20XXXXXXXXXX"
              type="text"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                border: `1px solid ${primaryColors.gray[300]}`,
                borderRadius: "8px",
              }}
            />
          </Grid>
          {/* issue */}
          <Grid item xs={3} display="flex" flexDirection="column" gap="16px">
            <Typography
              sx={{
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations["bold-700"],
                color: primaryColors.gray[700],
              }}
            >
              Issue
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Specific Issues.
                    <br />
                    By selecting the issues, you will receive WhatsApp
                    notifications with a complaint link and optional voice note
                    whenever you have a related issue complaint.
                  </div>
                }
                placement="top"
              >
                <HelpOutlineIcon
                  sx={{
                    height: "16px",
                    width: "16px",
                    color: primaryColors.gray[400],
                    marginInline: "8px",
                    marginBlock: "auto",
                  }}
                />
              </Tooltip>
            </Typography>
            <FormMultiSelect
              name="issues"
              label="Select issues"
              sx={{
                borderRadius: "8px",
                color: primaryColors.gray[700],
              }}
            >
              {issuesResponse &&
                !isLoadingIssues &&
                issuesResponse.data.map((issueData) => {
                  return (
                    <MenuItem key={issueData.id} value={issueData.name}>
                      <Checkbox
                        size="small"
                        checked={watch("issues")?.includes(issueData.id)}
                      />
                      <ListItemText
                        primary={issueData.name}
                        sx={{
                          fontSize: "14px",
                        }}
                      />
                    </MenuItem>
                  );
                })}
            </FormMultiSelect>
          </Grid>
          {/* silent time */}
          <Grid item xs={3} display="flex" flexDirection="column" gap="16px">
            <Typography
              sx={{
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations["bold-700"],
                color: primaryColors.gray[700],
              }}
            >
              Silent time
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Silent time.
                    <br />
                    By selecting the silent time, you won't receive WhatsApp
                    notifications
                  </div>
                }
                placement="top"
              >
                <HelpOutlineIcon
                  sx={{
                    height: "16px",
                    width: "16px",
                    color: primaryColors.gray[400],
                    marginInline: "8px",
                    marginBlock: "auto",
                  }}
                />
              </Tooltip>
            </Typography>

            <FormDropdown
              name="silent_time"
              label="Select a period"
              options={!isLoadingSilentTimes ? silentTimesResponse.data : []}
              sx={{
                borderRadius: "8px",
                color: primaryColors.gray[700],
              }}
            ></FormDropdown>
          </Grid>
          {/* branches & divisions */}
          <Grid item xs={3} display="flex" flexDirection="column" gap="16px">
            <Typography
              sx={{
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations["bold-700"],
                color: primaryColors.gray[700],
              }}
            >
              Branch
            </Typography>

            <FormMultiSelect
              name="divisions"
              label="Select branches & divisions"
              sx={{
                borderRadius: "8px",
                color: primaryColors.gray[700],
              }}
            >
              {allBranchesData.map(function renderDivisionsOptionsGroup(
                branchDivOption,
                index
              ) {
                return (
                  <MenuItem
                    aria-invalid={branchDivOption.isBranch}
                    disabled={branchDivOption.isBranch}
                    key={index}
                    value={
                      branchDivOption.isBranch ? "" : branchDivOption.value
                    }
                    sx={{
                      pointerEvents: branchDivOption.isBranch
                        ? "none"
                        : undefined,
                      paddingLeft: branchDivOption.isBranch ? undefined : 2,
                    }}
                  >
                    {!branchDivOption.isBranch && (
                      <Checkbox
                        size="small"
                        checked={watch("divisions")?.includes(
                          branchDivOption.value
                        )}
                      />
                    )}
                    <ListItemText
                      primary={branchDivOption.label}
                      sx={{
                        fontSize: "14px",
                        fontWeight: branchDivOption.isBranch ? "bold" : "",
                      }}
                    />
                  </MenuItem>
                );
              })}
            </FormMultiSelect>
          </Grid>
          {/* submit & reset */}
          <Grid
            container
            justifyContent="flex-end"
            direction="row"
            padding="20px 0px"
            borderBottom={`1px solid ${primaryColors.gray[200]}`}
            gap="10px"
          >
            <Button
              variant="text"
              disabled={!formState.isDirty || submitting}
              onClick={() => {
                reset();
              }}
              sx={{
                backgroundColor: primaryColors.base.white,
                border: "1px solid",
                borderColor: formState.isDirty
                  ? primaryColors.base.black
                  : primaryColors.gray[300],
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: primaryColors.base.white,
                },
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",

                  fontFamily: "Nunito",
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations["medium-500"],
                  color: formState.isDirty
                    ? primaryColors.base.black
                    : primaryColors.gray[300],
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              variant="text"
              type="submit"
              disabled={!formState.isDirty || submitting}
              sx={{
                backgroundColor: formState.isDirty
                  ? primaryColors.warning[500]
                  : primaryColors.warning[200],
                border: "1px solid",
                borderColor: formState.isDirty
                  ? primaryColors.warning[500]
                  : primaryColors.warning[200],
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: primaryColors.warning[500],
                },
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "Nunito",
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations["medium-500"],
                  color: primaryColors.base.white,
                }}
              >
                {submitting ? <CircularProgress size={20} /> : "Save"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
