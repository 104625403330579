import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GenericDialog } from "../../../reusable/components/GenericDialog";
import { primaryColors } from "../../../helpers/customColors";
import { invalidNumbersHelperSection } from "./GenerateBulkSMSAccordion";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

export const InvalidPhoneNumbersDialog = ({
  invalidPhoneNumbers,
  triggerButtonLabel,
  submitValidNumbers,
  isSubmittingValidNumbers,
}) => {
  return invalidPhoneNumbers.length === 0 ? null : (
    <GenericDialog
      dialogTitle={`Invalid Phone Numbers`}
      maxWidth="sm"
      onOpenCallback={function cancelOnClickEffect(e) {
        e.preventDefault();
        e.stopPropagation();
      }}
      onCloseCallback={function cancelOnClickEffect(e) {
        e.preventDefault();
        e.stopPropagation();
      }}
      stopPropagation
      openByDefault
      triggerButton={
        <Button
          variant="text"
          title="Click to View a List of the Invalid Phone Numbers"
          sx={{
            display: "flex",
            gap: 1,
            textTransform: "none",
            textDecoration: "underline",
            color: primaryColors.error[600],
          }}
        >
          {triggerButtonLabel}
        </Button>
      }
    >
      <Box
        display="flex"
        justifyContent="center"
        maxHeight="600px"
        width="100%"
        onClick={function (e) {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          gap={2}
          p={1}
          width="fit-content"
        >
          {invalidNumbersHelperSection}

          <TableContainer
            component={Paper}
            sx={{ borderRadius: 3, boxShadow: 2 }}
          >
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      bgcolor: primaryColors.gray[200],
                      color: primaryColors.gray[500],
                    }}
                  >
                    Phone Number
                  </TableCell>
                  <TableCell
                    sx={{
                      bgcolor: primaryColors.gray[200],
                      color: primaryColors.gray[500],
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invalidPhoneNumbers.map((invalidPhoneNumber, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ bgcolor: primaryColors.base.white }}
                    >
                      {invalidPhoneNumber}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ bgcolor: primaryColors.base.white }}
                    >
                      Invalid
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" flexDirection="column" gap={1} width="100%">
            {/* <CSVLink
              data={invalidNumbersFormattedForCSVExport}
              target="_blank"
              href="#"
              filename={"my-file.csv"}
            > */}
            <Button
              variant="contained"
              target="_blank"
              onClick={function () {
                // 1- Format the Invalid Phone numbers to be ready for CSV export
                const invalidNumbersFormattedForCSVExport = [];

                invalidPhoneNumbers.forEach(function addInvalidNumberAsObject(
                  invalidPhoneNumber
                ) {
                  invalidNumbersFormattedForCSVExport.push({
                    "Phone Number": invalidPhoneNumber,
                  });
                });

                // 2- Prepare and download the CSV File
                const keys = Object.keys(
                  invalidNumbersFormattedForCSVExport[0]
                );
                const commaSeparatedString = [
                  keys.join(","),
                  invalidNumbersFormattedForCSVExport
                    .map((row) => keys.map((key) => row[key]).join(","))
                    .join("\n"),
                ].join("\n");
                const csvBlob = new Blob([commaSeparatedString]);

                const url = window.URL.createObjectURL(csvBlob);

                const link = document.createElement("a");
                link.href = url;
                const fileName = `Invalid Numbers ${moment(new Date()).format(
                  "DD-MM-YYYY @ HH:MM"
                )}.csv`;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
              }}
              sx={{
                textTransform: "capitalize",
                bgcolor: primaryColors.brand[200],
                color: primaryColors.base.black,
                height: "48px",
                boxShadow: "none",
                width: "100%",
                "&:hover": {
                  bgcolor: primaryColors.brand[300],
                },
              }}
            >
              Download Invalid Numbers
            </Button>
            {/* </CSVLink> */}

            {/* {submitValidNumbers && (
              <LoadingButton
                loading={isSubmittingValidNumbers}
                variant="contained"
                onClick={submitValidNumbers}
                sx={{
                  textTransform: "none",
                  color: primaryColors.base.white,
                  height: "48px",
                  boxShadow: "none",
                  width: "100%",
                }}
              >
                Send SMSes to Valid Numbers
              </LoadingButton>
            )} */}
          </Box>
        </Box>
      </Box>
    </GenericDialog>
  );
};
