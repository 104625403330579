import { useContext, useState, useEffect } from "react";
import { COLORS } from "../helpers/Colors";
import clsx from "clsx";

import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";

import Grid from "@mui/material/Grid";

import { AuthenticationContext } from "../features/login/contexts/AuthenticationContext";

// ICONS
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelIcon from "@mui/icons-material/Build";
import Message from "@mui/icons-material/Message";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import { Button, Tooltip } from "@mui/material";
import { InsertChart, Send, ViewTimeline } from "@mui/icons-material";
import { primaryColors } from "../helpers/customColors";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { FEEDBACK_ONLY, FACILITY_MANAGER, DEMO } from "../helpers/constants";

const useStyles = makeStyles(() => ({
  navIcon: {
    width: 25,
    height: 25,
    // color: primaryColors.base.white,
    // "&.active": {

    //       color: primaryColors.brand[500],
    //   },
  },
  navButton: {
    maxWidth: "inherit",
    minWidth: "50px",
    color: primaryColors.base.white,
    display: "block",
    margin: "7px 15px 0",
    textAlign: "center",
    textDecoration: "none",
    "&.active": {
      color: primaryColors.brand[500],
      "& h3": {
        color: primaryColors.brand[500],
      },
    },
  },
  logoutButton: {
    padding: 0,
  },
  logoutButtonText: {
    marginTop: "2px !important",
  },
  selectedNavButton: {
    maxWidth: "inherit",
    color: primaryColors.brand[500],
    display: "block",
    margin: "7px 15px 0",
    textAlign: "center",
    textDecoration: "none",
    "& h3": {
      color: primaryColors.brand[500],
    },
  },
  navIconLabel: {
    color: COLORS.white,
    marginTop: "8px",
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    fontFamily: "Avenir-Medium",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  largeNavIconLabel: {
    maxWidth: "160px",
  },
  nav: {
    marginRight: "60px",
    paddingTop: "0px",
    maxHeight: "70px",
  },
  menuItem: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    height: "50px",
  },
  swap: {
    display: "block",
  },
  menu: {
    color: COLORS.white,
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    "& $swap": {
      display: "none",
    },
  },
  selectedMenu: {
    color: primaryColors.brand[500],
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    "& $swap": {
      display: "none",
    },
  },
  menuItemLink: {
    color: "inherit",
    textDecoration: "none",
    fontSize: "16px",
    "&.active": {
      fontFamily: "Avenir-Heavy",
    },
  },
  dropdownIcon: {
    color: COLORS.white,
  },
  active: {
    fontFamily: "Avenir-Heavy",
  },
}));

const BigScreenNav = ({ forSMSUsersOnly }) => {
  const classes = useStyles();
  const { logout, user } = useContext(AuthenticationContext);

  const { setFirebaseInactive } = useContext(AuthenticationContext);

  const useLogout = async () => {
    if (localStorage.getItem("firebasePermission") === "allow") {
      await setFirebaseInactive();
    }
    logout();
  };

  return (
    // big screen nav links
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-start"
      className={classes.nav}
    >
      {user.role !== FEEDBACK_ONLY && (
        <Tooltip title={"Home"}>
          <NavLink className={classes.navButton} to="/">
            <InsertChart className={classes.navIcon} />
            <h3 className={classes.navIconLabel}>Home</h3>
          </NavLink>
        </Tooltip>
      )}

      <Tooltip title={"Overall View"}>
        <NavLink className={classes.navButton} to="/overall-view">
          <ViewTimeline className={classes.navIcon} />
          <h3 className={classes.navIconLabel}>Overall View</h3>
        </NavLink>
      </Tooltip>

      {user.role !== FEEDBACK_ONLY && (
        <NavLink
          to="/distribution"
          // style={({isActive}) => ({fontFamily: isActive ? 'Avenir-Heavy' : 'inherit'})}
          className={classes.navButton}
        >
          <Send className={classes.navIcon} />
          <h3 className={classes.navIconLabel}>Distribution</h3>
        </NavLink>
      )}

      {!forSMSUsersOnly && (
        <NavLink
          //   style={({isActive}) => ({fontFamily: isActive ? 'Avenir-Heavy' : 'inherit'})}
          className={classes.navButton}
          to="/workspace/template-groups"
        >
          <ViewQuiltIcon className={classes.navIcon} />
          <h3 className={clsx(classes.navIconLabel, classes.largeNavIconLabel)}>
            Workspace
          </h3>
        </NavLink>
      )}

      <NavLink
        to="/outbound"
        // style={({isActive}) => ({fontFamily: isActive ? 'Avenir-Heavy' : 'inherit'})}
        className={classes.navButton}
      >
        <PermPhoneMsgIcon className={classes.navIcon} />
        <h3 className={classes.navIconLabel}>Outbound</h3>
      </NavLink>

      {/* {!forSMSUsersOnly && (
        <NavLink
          //   style={({isActive}) => ({fontFamily: isActive ? 'Avenir-Heavy' : 'inherit'})}
          className={classes.navButton}
          to="/reports-hub"
        >
          <Assessment className={classes.navIcon} />
          <h3 className={clsx(classes.navIconLabel, classes.largeNavIconLabel)}>
            Reports Hub
          </h3>
        </NavLink>
      )} */}

      {(user?.role === FACILITY_MANAGER || user?.role === DEMO) && (
        <NavLink
          //   style={({isActive}) => ({fontFamily: isActive ? 'Avenir-Heavy' : 'inherit'})}
          className={classes.navButton}
          to="/facility-management"
        >
          <AdminPanelIcon className={classes.navIcon} />
          <h3 className={clsx(classes.navIconLabel, classes.largeNavIconLabel)}>
            Facility Management
          </h3>
        </NavLink>
      )}

      {!forSMSUsersOnly && (
        <NavLink
          //   style={({isActive}) => ({fontFamily: isActive ? 'Avenir-Heavy' : 'inherit'})}
          className={classes.navButton}
          to="/settings"
        >
          <SettingsIcon className={classes.navIcon} />
          <h3 className={classes.navIconLabel}>Settings</h3>
        </NavLink>
      )}

      {!forSMSUsersOnly && (
        <NavLink
          //   style={({isActive}) => ({fontFamily: isActive ? 'Avenir-Heavy' : 'inherit'})}
          className={classes.navButton}
          to="/onboarding"
        >
          <RocketLaunchIcon className={classes.navIcon} />
          <h3 className={classes.navIconLabel}>Onboarding</h3>
        </NavLink>
      )}
      {/* {!forSMSUsersOnly && (
        <NavLink
          //   style={({isActive}) => ({fontFamily: isActive ? 'Avenir-Heavy' : 'inherit'})}
          className={classes.navButton}
          to="/reports-hub"
        >
          <Assessment className={classes.navIcon} />
          <h3 className={clsx(classes.navIconLabel, classes.largeNavIconLabel)}>
            Reports Hub
          </h3>
        </NavLink>
      )} */}

      <Button
        // to="/"
        className={clsx(classes.navButton, classes.logoutButton)}
        onClick={useLogout}
      >
        <KeyboardTabIcon className={classes.navIcon} />
        <h3 className={clsx(classes.navIconLabel, classes.logoutButtonText)}>
          Logout
        </h3>
      </Button>
    </Grid>
  );
};

export default BigScreenNav;
