import { Box, Typography } from "@mui/material";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { useContext } from "react";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { GenericAccordion } from "../../../reusable/components/GenericAccordion";
import { DescriptionWithGuidance } from "./DescriptionWithGuidance";
import { LogoAndCountryForm } from "./LogoAndCountryForm";

//guidance images
import { ReactComponent as RecieveYourFirstResponseGuidance } from "../../../assets/onboarding-guidances/recieve-first-response-guidance.svg";
import { ReactComponent as ReviewYourResponseVisuallyGuidance } from "../../../assets/onboarding-guidances/discover-responses-visually-guidance.svg";
import { ReactComponent as ExportYourResponsesGuidance } from "../../../assets/onboarding-guidances/export-responses-guidance.svg";
import { ReactComponent as CreateYourBranchesAndDivisionsGuidance } from "../../../assets/onboarding-guidances/create-branches-and-divisions-guidance.svg";
import { ReactComponent as CreateYourOwnTemplateGuidance } from "../../../assets/onboarding-guidances/preview-template-guidance.svg";
import { ReactComponent as SendUsYourThoughtsGuidance } from "../../../assets/onboarding-guidances/send-us-thoughts-guidance.svg";
import { InviteYourTeamBody } from "./InviteYourTeamBody";
import { GenerateLinkBody } from "./GenerateLinkBody";

export const OnboardingPage = () => {
  const { user } = useContext(AuthenticationContext);

  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap="42px">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="display-md"
          color={primaryColors.gray[900]}
          fontWeight={fontWeightVariations.semiBold}
          fontFamily="Nunito"
          display="block"
          marginBottom="12px"
        >
          {`Hello ${user.username}, Welcome to melior`}
        </Typography>
        <Box width="596px" marginX="6px">
          <Typography
            variant="display-sm"
            color={primaryColors.gray[700]}
            fontWeight={fontWeightVariations.medium}
            fontFamily="Nunito"
            display="inline"
          >
            Follow these simple steps to get started with
          </Typography>
          <Typography
            variant="display-sm"
            color={primaryColors.brand[500]}
            fontWeight={fontWeightVariations.medium}
            fontFamily="Nunito"
            display="inline"
          >
            {" "}
            Melior!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "82%", lg: "48%" },
        }}
      >
        <OnboardingStepsAccordion />
      </Box>
    </Box>
  );
};

const OnboardingStepsAccordion = () => {
  return getOnboardingStepsList().map(function renderOnboardingSteps(
    onboardingSectionData,
    index
  ) {
    return (
      <GenericAccordion
        key={index}
        title={
          <Typography
            variant="text-lg"
            fontFamily="Nunito"
            fontWeight={fontWeightVariations.medium}
            color={primaryColors.base.black}
          >
            {`${index + 1}. ${onboardingSectionData.title}`}
          </Typography>
        }
        body={onboardingSectionData.body}
        backgroundColor={primaryColors.base.white}
      />
    );
  });
};

function getOnboardingStepsList() {
  return [
    {
      title: "Upload your Logo",
      body: (
        <DescriptionWithGuidance textDescription="Let’s start with your branding. Upload your logo in the possible available quality and a white background for best results.">
          <LogoAndCountryForm />
        </DescriptionWithGuidance>
      ),
    },
    {
      title: "Generate link / QR code",
      body: <GenerateLinkBody />,
    },
    {
      title: "Receive your first response (Review/Complaint)",
      body: (
        <DescriptionWithGuidance
          textDescription={
            <span>
              As soon as your customers respond to your survey, you can easily
              check their responses on the Melior dashboard. Check the{" "}
              <strong>Overview</strong> tab for a bird’s eye view or the{" "}
              <strong>Results</strong> tab for more details.
            </span>
          }
          guidanceImage={<RecieveYourFirstResponseGuidance width="100%" />}
        />
      ),
    },
    {
      title: "Export",
      body: (
        <DescriptionWithGuidance
          textDescription={
            <span>
              You can quickly export your data as an Excel sheet. Just click on{" "}
              <strong>“Export”</strong> to export specific results of specific
              branches or divisions or you can click on{" "}
              <strong>“Export all”</strong> to export all results.
            </span>
          }
          guidanceImage={<ExportYourResponsesGuidance width="100%" />}
        />
      ),
    },
    {
      title: "Discover your responses visually",
      body: (
        <DescriptionWithGuidance
          textDescription="Now you can check the performance of your facilities and get access to detailed analytics. Just follow these steps:"
          guidanceImage={<ReviewYourResponseVisuallyGuidance width="100%" />}
        />
      ),
    },
    {
      title: "Create your Branches & Divisions",
      body: (
        <DescriptionWithGuidance
          textDescription="Depending on your plan, you can set up branches and divisions by following these steps:"
          guidanceImage={
            <CreateYourBranchesAndDivisionsGuidance width="100%" />
          }
        />
      ),
    },
    {
      title: "Invite your team",
      body: <InviteYourTeamBody />,
    },
    {
      title: "Preview your template",
      body: (
        <DescriptionWithGuidance textDescription="Depending on your subscription, you can create new templates and assign them specific branches, and divisions." />
      ),
    },
    {
      title: "Send us your thoughts!",
      body: (
        <DescriptionWithGuidance
          textDescription="Finally, we're here to help and listen. If you come across any bugs, have suggestions or want to request a call, please let us know by following these steps:"
          guidanceImage={<SendUsYourThoughtsGuidance width="100%" />}
        />
      ),
    },
  ];
}
