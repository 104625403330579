import { Box, Button, IconButton, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import WelcomeScreenIconSVG from "../../../../assets/icons/workspace/template-groups/WelcomeScreenIcon.svg";
import DetailedScreenIconSVG from "../../../../assets/icons/workspace/template-groups/DetailedScreenIcon.svg";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { CallSplit, HelpCenter, Visibility } from "@mui/icons-material";
import {
  selectedTemplateScreenSectionTypes,
  staticNavigationMenuKeys,
  templateGroupStateManagementActions,
} from "./TemplateGroupManagementPage";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";

export const TemplateGroupNavigationMenu = ({
  templateGroupStateManagement,
  screensSectionsData,
}) => {
  const readyToRenderNavigationMenu =
    screensSectionsData.complaintSection &&
    screensSectionsData.reviewSection &&
    screensSectionsData.welcomeSection &&
    screensSectionsData.detailedSection &&
    screensSectionsData.endingSection;

  return (
    <IsLoadingContainer isLoading={!readyToRenderNavigationMenu}>
      {readyToRenderNavigationMenu && (
        <Box
          display="flex"
          flexDirection="column"
          width="95%"
          height="fit-content"
          boxSizing="border-box"
        >
          {/* Start: Welcome Screen */}
          <NavigationMenuSectionButton
            title="Welcome Screen"
            icon={
              <img
                src={WelcomeScreenIconSVG}
                style={{ width: "20px", paddingLeft: "2px" }}
                alt="resolved-complaint-img"
              />
            }
            onClickHandler={function markWelcomeScreenAsSelected() {
              templateGroupStateManagement.dispatcher({
                type: templateGroupStateManagementActions.updateSelectedScreen,
                payload: {
                  selectedScreen: {
                    type: selectedTemplateScreenSectionTypes.welcomeSection,
                    viewData:
                      screensSectionsData.welcomeSection.welcomeScreenViewData,
                    key: staticNavigationMenuKeys.welcomeScreen,
                  },
                },
              });
            }}
            selected={
              templateGroupStateManagement.state?.selectedScreen.key ===
              staticNavigationMenuKeys.welcomeScreen
            }
          />
          {/* End: Welcome Screen */}

          {/* Start: Branching Question Screen */}
          <NavigationMenuSectionButton
            title={
              `Branching Q: ${screensSectionsData.welcomeSection.mainQuestion.question.label}`
            }
            icon={<CallSplit />}
            onClickHandler={function markWelcomeScreenMainQuestionAsSelected() {
              templateGroupStateManagement.dispatcher({
                type: templateGroupStateManagementActions.updateSelectedScreen,
                payload: {
                  selectedScreen: {
                    type: selectedTemplateScreenSectionTypes.welcomeSection,
                    viewData:
                      screensSectionsData.welcomeSection.mainQuestion
                        .questionViewData,
                    key: generateNavigationMenuKeyWithID({
                      sectionType:
                        selectedTemplateScreenSectionTypes.welcomeSection,
                      id: screensSectionsData.welcomeSection.mainQuestion
                        .question.id,
                    }),
                  },
                },
              });
            }}
            selected={
              generateNavigationMenuKeyWithID({
                sectionType: selectedTemplateScreenSectionTypes.welcomeSection,
                id: screensSectionsData.welcomeSection.mainQuestion.question.id,
              }) === templateGroupStateManagement.state?.selectedScreen.key
            }
          />
          {/* End: Branching Question Screen */}

          {/* Start: Review Section Questions */}
          <Box display="flex" flexDirection="column">
            <NavigationMenuSectionButton
              title={"Review Questions Section"}
              icon={<TurnedInIcon />}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              height={"150px"}
              maxHeight={"150px"}
              overflow="auto"
              pl={1}
            >
              {screensSectionsData.reviewSection.questions.map(
                function renderReviewQuestionInNavMenu(reviewQuestion) {
                  return (
                    <NavigationMenuScreenButton
                      title={reviewQuestion.label}
                      icon={<HelpCenter />}
                      selected={
                        generateNavigationMenuKeyWithID({
                          sectionType:
                            selectedTemplateScreenSectionTypes.reviewSection,
                          id: reviewQuestion.id,
                        }) ===
                        templateGroupStateManagement.state?.selectedScreen.key
                      }
                      onClickHandler={function markReviewQuestionAsSelected() {
                        templateGroupStateManagement.dispatcher({
                          type: templateGroupStateManagementActions.updateSelectedScreen,
                          payload: {
                            selectedScreen: {
                              type: selectedTemplateScreenSectionTypes.reviewSection,
                              viewData: reviewQuestion.questionViewData,
                              key: generateNavigationMenuKeyWithID({
                                sectionType:
                                  selectedTemplateScreenSectionTypes.reviewSection,
                                id: reviewQuestion.id,
                              }),
                            },
                          },
                        });
                      }}
                    />
                  );
                }
              )}
            </Box>
          </Box>
          {/* End: Review Section Questions */}

          {/* Start: Complaint Section Questions */}
          <Box display="flex" flexDirection="column">
            <NavigationMenuSectionButton
              title={"Complaint Questions Section"}
              icon={<TurnedInIcon />}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              height={"150px"}
              maxHeight={"150px"}
              overflow="auto"
              pl={1}
            >
              {screensSectionsData.complaintSection.questions.map(
                function renderComplaintQuestionInNavMenu(complaintQuestion) {
                  return (
                    <NavigationMenuScreenButton
                      title={complaintQuestion.label}
                      icon={<HelpCenter />}
                      selected={
                        generateNavigationMenuKeyWithID({
                          sectionType:
                            selectedTemplateScreenSectionTypes.complaintSection,
                          id: complaintQuestion.id,
                        }) ===
                        templateGroupStateManagement.state?.selectedScreen.key
                      }
                      onClickHandler={function markComplaintQuestionAsSelected() {
                        templateGroupStateManagement.dispatcher({
                          type: templateGroupStateManagementActions.updateSelectedScreen,
                          payload: {
                            selectedScreen: {
                              type: selectedTemplateScreenSectionTypes.complaintSection,
                              viewData: complaintQuestion.questionViewData,
                              key: generateNavigationMenuKeyWithID({
                                sectionType:
                                  selectedTemplateScreenSectionTypes.complaintSection,
                                id: complaintQuestion.id,
                              }),
                            },
                          },
                        });
                      }}
                    />
                  );
                }
              )}
            </Box>
          </Box>
          {/* End: Complaint Section Questions */}

          {/* Start: Detailed Screen */}
          <NavigationMenuSectionButton
            title={"Detailed Screen"}
            icon={
              <img
                src={DetailedScreenIconSVG}
                style={{ width: "20px", paddingLeft: "2px" }}
                alt="resolved-complaint-img"
              />
            }
            onClickHandler={function markDetailedScreenAsSelected() {
              templateGroupStateManagement.dispatcher({
                type: templateGroupStateManagementActions.updateSelectedScreen,
                payload: {
                  selectedScreen: {
                    type: selectedTemplateScreenSectionTypes.detailedSection,
                    viewData:
                      screensSectionsData.detailedSection
                        .detailedScreenViewData,
                    key: staticNavigationMenuKeys.detailedScreen,
                  },
                },
              });
            }}
            selected={
              templateGroupStateManagement.state?.selectedScreen.key ===
              staticNavigationMenuKeys.detailedScreen
            }
            headerAction={
              <IconButton aria-label="delete" disabled>
                <Visibility />
              </IconButton>
            }
          />
          {/* End: Detailed Screen */}

          {/* Start: Ending Screen */}
          <NavigationMenuSectionButton
            title={"Ending Screen"}
            icon={
              <img
                src={WelcomeScreenIconSVG}
                style={{ width: "20px", paddingLeft: "2px" }}
                alt="resolved-complaint-img"
              />
            }
            onClickHandler={function markEndingScreenAsSelected() {
              templateGroupStateManagement.dispatcher({
                type: templateGroupStateManagementActions.updateSelectedScreen,
                payload: {
                  selectedScreen: {
                    type: selectedTemplateScreenSectionTypes.endingScreenSection,
                    viewData:
                      screensSectionsData.endingSection.endingScreenViewData,
                    key: staticNavigationMenuKeys.endingScreen,
                  },
                },
              });
            }}
            selected={
              templateGroupStateManagement.state?.selectedScreen.key ===
              staticNavigationMenuKeys.endingScreen
            }
          />
          {/* End: Ending Screen */}
        </Box>
      )}
    </IsLoadingContainer>
  );
};

function generateNavigationMenuKeyWithID({ sectionType, id }) {
  return `${sectionType}-${id}`;
}

function NavigationMenuScreenButton({ title, icon, onClickHandler, selected }) {
  return (
    <Button
      title={title}
      variant="text"
      onClick={onClickHandler}
      sx={{
        textTransform: "capitalize",
        color: primaryColors.base.black,
        bgcolor: selected ? primaryColors.gray[300] : "",
        height: "40px",
        width: "100%",
        justifyContent: "left",
        "&:hover": {
          bgcolor: selected ? primaryColors.gray[300] : "",
        },
      }}
      startIcon={icon}
    >
      <Typography
        variant="text-sm"
        textAlign="left"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        // sx={{
        //   textDecoration:
        //     onClickHandler === undefined ? undefined : "underline",
        // }}
      >
        {title}
      </Typography>
    </Button>
  );
}

function NavigationMenuSectionButton({
  title,
  icon,
  onClickHandler,
  selected,
}) {
  return (
    <Button
      title={title}
      variant="text"
      onClick={onClickHandler ? onClickHandler : undefined}
      sx={{
        color: primaryColors.base.black,
        bgcolor: selected ? primaryColors.gray[300] : primaryColors.gray[100],
        height: "60px",
        width: "100%",
        justifyContent: "left",
        "&:hover": {
          bgcolor: selected ? primaryColors.gray[300] : primaryColors.gray[100],
        },
        cursor: onClickHandler === undefined ? "default" : "pointer",
        borderBottom: `2px solid ${primaryColors.gray[300]}`,
      }}
      startIcon={icon}
      disableRipple={onClickHandler === undefined}
    >
      <Typography
        variant="text-md"
        textAlign="left"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        textTransform="none"
        fontWeight="bold"
        color={primaryColors.base.black}
        // sx={{
        //   textDecoration:
        //     onClickHandler === undefined ? undefined : "underline",
        // }}
      >
        {title}
      </Typography>
    </Button>
  );
}

const navigationButtonTypes = {
  section: "section",
  screen: "screen",
};
