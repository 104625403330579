import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { useDeleteTemplateGroup } from "../../api/deleteTemplateGroup";
import { GenericDialog, SUBMIT_BUTTON_TYPES } from "../../../../reusable/components/GenericDialog";
import { primaryColors } from "../../../../helpers/customColors";

export const DeleteTemplateGroup = ({ templateGroupToDelete }) => {
  const deleteTemplateGroupMutation = useDeleteTemplateGroup();

  const deleteTemplateGroupHandler = () => {
    deleteTemplateGroupMutation
      .mutateAsync({
        templateGroupID: templateGroupToDelete.id,
      })
  };

  return (
    <GenericDialog
      dialogTitle={`Delete Template Group (${templateGroupToDelete.name})`}
      dialogSubtitle={
        "Are you sure you want to delete this Template Group? This action cannot be undone."
      }
      maxWidth="xs"
      triggerButton={
        <Tooltip title="Delete Template Group">
          <IconButton
            sx={{ padding: "10px", borderRadius: "16px" }}
            aria-label="delete"
          >
            <DeleteIcon
              sx={{
                color: primaryColors.base.black,
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Tooltip>
      }
      triggerButtonDisplay="inline"
      submitButtonType={SUBMIT_BUTTON_TYPES.delete}
      submitButtonText={"Delete"}
      onSubmitCallback={deleteTemplateGroupHandler}
      closeButtonText={"Cancel"}
      isDone={deleteTemplateGroupMutation.isSuccess}
      isSubmitting={deleteTemplateGroupMutation.isLoading}
      stopPropagation
    />
  );
};
