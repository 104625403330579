import { Button, Grid, Typography } from "@mui/material";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../reusable/components/GenericDialog";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import * as yup from "yup";
import { FormProvider } from "react-hook-form";
import { primaryColors } from "../../../../helpers/customColors";
import { useEffect, useState, useContext } from "react";
import { FormDropdown } from "../../../../reusable/components/form-inputs/controlled";
import { useEditMemberResponsibilities } from "../../api/members";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../contexts/NotificationContext";

export const SeeMoreBranchesDialogButton = ({ memberDetails }) => {
  const { fireNotification } = useContext(NotificationContext);

  let [branchesOptions, setBranchesOptions] = useState([]);
  let [divisionsIDsList, setDivisionsIDsList] = useState([]);

  const validationSchema = yup.object({
    branch: yup.string().required("select a branch"),
  });
  const reactHookFormMethods = useReactHookFormWithYup({ validationSchema });
  const { reset, handleSubmit, watch, formState } = reactHookFormMethods;

  const removeDivisionMutation = useEditMemberResponsibilities();
  const removeDivisionHandler = () => {
    removeDivisionMutation
      .mutateAsync({
        memberID: memberDetails.id,
        body: { divisions: divisionsIDsList },
      })
      .then(() => {
        reset();
        fireNotification({
          title: "Divisions removed successfully",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      })
      .catch(() =>
        fireNotification({
          title: "An error occurred",
          description: "Please try again later",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        })
      );
  };

  const prepareBranchesOptions = () => {
    let branchesTemp = [];
    memberDetails?.branches.forEach(function getBranchesAsOptions(branchData) {
      branchesTemp.push({
        value: branchData.id,
        label: branchData.name,
      });
    });
    setBranchesOptions(branchesTemp);
  };

  const prepareDivisionsList = () => {
    let divisionsTemp = [];
    memberDetails?.divisions.forEach(function getDivisionsIDs(divisionData) {
      divisionsTemp.push(divisionData.id);
    });
    setDivisionsIDsList(divisionsTemp);
  };

  useEffect(() => {
    // set branches options
    prepareBranchesOptions();
    // set divisions IDs list
    prepareDivisionsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenericDialog
      dialogTitle="Division Access"
      dialogSubtitle={"The user has access to the following divisions:"}
      maxWidth="xs"
      triggerButton={
        <Button>
          <Typography
            sx={{
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations["semiBold-600"],
              textTransform: "capitalize",
            }}
          >
            See more
          </Typography>
        </Button>
      }
      triggerButtonDisplay="inline"
      submitButtonType={SUBMIT_BUTTON_TYPES.submit}
      disableSubmitCondition={!formState.isDirty}
      submitButtonText={"Save"}
      onSubmitCallback={handleSubmit(removeDivisionHandler)}
      onCloseCallback={() => {
        reset();
        prepareDivisionsList();
      }}
      closeButtonText={"Cancel"}
    >
      <FormProvider {...reactHookFormMethods}>
        <form method="POST">
          <Grid container gap={"20px"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: primaryColors.gray[700],
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations["bold-700"],
                  marginBottom: "6px",
                }}
              >
                Branch
              </Typography>
              <FormDropdown
                name="branch"
                label="Select a branch"
                options={branchesOptions}
                enableSearch={true}
                sx={{
                  borderRadius: "8px",
                  color: primaryColors.gray[700],
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              bgcolor={primaryColors.gray[50]}
              display="flex"
              flexDirection="column"
              padding="10px"
              minHeight="240px"
              gap="10px"
            >
              {formState.isDirty
                ? DivisionsComponent(
                    watch("branch"),
                    memberDetails,
                    divisionsIDsList,
                    setDivisionsIDsList
                  )
                : NoBranchSelectedComponent()}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </GenericDialog>
  );
};

const NoBranchSelectedComponent = () => {
  return (
    <Typography
      sx={{
        color: primaryColors.gray[700],
        fontSize: fontSizeVariations["text-md"],
        fontWeight: fontWeightVariations["semiBold-600"],
      }}
    >
      Before reviewing the assigned division, please select a branch first.
    </Typography>
  );
};
const DivisionsComponent = (
  selectedBranch,
  memberDetails,
  divisionsIDsList,
  setDivisionsIDsList
) => {
  return memberDetails.divisions.map((divisionData) => {
    if (divisionData.branch_id == selectedBranch) {
      return (
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          key={divisionData.id}
        >
          <Grid
            item
            sx={{
              color: divisionsIDsList.includes(divisionData.id)
                ? primaryColors.gray[700]
                : primaryColors.gray[300],
            }}
          >
            {divisionData.name}
          </Grid>
          <Grid item>
            <Button
              disabled={!divisionsIDsList.includes(divisionData.id)}
              onClick={() => {
                let divisionaTemp = divisionsIDsList.filter(
                  (divisionID) => divisionID != divisionData.id
                );
                setDivisionsIDsList(divisionaTemp);
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color: divisionsIDsList.includes(divisionData.id)
                    ? primaryColors.error[700]
                    : primaryColors.error[200],
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations["semiBold-600"],
                }}
              >
                Remove
              </Typography>
            </Button>
          </Grid>
        </Grid>
      );
    }
  });
};
