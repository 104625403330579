import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { OverallViewTable } from "../../../revamped-overall-view/components/shared/OverallViewTable";
import { CloudDownload } from "@mui/icons-material";
import { useMemo } from "react";
import moment from "moment";
import { primaryColors } from "../../../../helpers/customColors";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { EmptyTableView } from "../../reusable/EmptyTableView";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import { DateRangePickerInput } from "../../../../reusable/components/DateRangePickerInput";
import { useInvoicesFiltersState } from "./useInvoicesFiltersState";
import {
  defaultInvoicesListParams,
  useGetInvoices,
} from "../../api/billing/getInvoices";
import { UpgradePaymentPlanDialog } from "./BillingCards";
import { TableContainer } from "../../../../reusable/components/TableContainer";

export const InvoicesTable = ({ userOnFreePlan }) => {
  const {
    apiListArgsState,
    updatePageHandler,
    initiateSearchHandler,
    resetSearchFilterState,
  } = useListAPIsStateManagement({
    initialState: {
      ...defaultInvoicesListParams,
    },
  });

  const {
    selectedFiltersState,
    updateSelectedDateRange,
    resetDateRange,
    updateSelectedStatus,
  } = useInvoicesFiltersState();

  const {
    data: invoicesResponse,
    isLoading: isLoadingInvoicesResponse,
    isFetching: isFetchingInvoicesResponse,
    isError: isErrorInvoicesResponse,
  } = useGetInvoices({
    params: { page: apiListArgsState.page, ...selectedFiltersState },
  });

  const invoicesTableColumns = useMemo(
    () => [
      {
        header: "Date",
        id: "date",
        accessorFn: function renderInvoiceDate(invoiceData) {
          return moment(invoiceData.date).format("YYYY-MM-DD");
        },
        size: 50,
      },
      {
        header: "Plan",
        accessorKey: "plan",
        size: 50,
      },
      {
        header: "Total active users",
        accessorKey: "total_active_users",
        size: 50,
      },
      {
        header: "Total Branches",
        accessorKey: "total_branches",
        size: 50,
      },
      {
        header: "Total Divisions",
        accessorKey: "total_divisions",
        size: 50,
      },
      {
        header: "Invoice Total",
        accessorKey: "invoice_total",
        size: 50,
      },
      {
        header: "Status",
        id: "status",
        accessorFn: function renderInvoiceStatus(invoiceData) {
          switch (invoiceData.status.semantic) {
            case invoiceStatusSemantics.positive: {
              return (
                <InvoiceStatus
                  lightColor={primaryColors.success[50]}
                  solidColor={primaryColors.success[700]}
                  value={invoiceData.status.text}
                />
              );
            }
            case invoiceStatusSemantics.negative: {
              return (
                <InvoiceStatus
                  lightColor={primaryColors.error[50]}
                  solidColor={primaryColors.error[700]}
                  value={invoiceData.status.text}
                />
              );
            }
            case invoiceStatusSemantics.neutral: {
              return (
                <InvoiceStatus
                  lightColor={primaryColors.warning[50]}
                  solidColor={primaryColors.warning[700]}
                  value={invoiceData.status.text}
                />
              );
            }
            default: {
              return null;
            }
          }
        },
        size: 50,
      },
      {
        header: "",
        id: "download-invoice-button",
        accessorFn: function renderInvoiceDownloadButton(invoiceData) {
          return (
            <IconButton
              title={
                invoiceData.invoice_url === null
                  ? "Downloadable Invoice not available"
                  : "Download Invoice"
              }
              size="small"
              onClick={function onClickDownloadInvoiceHandler(e) {
                e.stopPropagation();
                if (invoiceData.invoice_url !== null) {
                  window.open(invoiceData.invoice_url, "_blank");
                }
              }}
              sx={{
                opacity: invoiceData.invoice_url === null ? 0.5 : 1,
              }}
            >
              <CloudDownload />
            </IconButton>
          );
        },
        size: 50,
      },
    ],
    []
  );

  const invoicesTableViewForFreePlan = (
    <EmptyTableView
      title="Upgrade now to the STANDARD PLAN"
      description="Time to level up! Get your hands on the STANDARD PLAN and let the magic happen! With a bunch of features at your disposal, you can turn your patients' complaints into pure satisfaction and growth."
      actionButton={
        <UpgradePaymentPlanDialog
          triggerButton={
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                color: primaryColors.base.white,
              }}
            >
              Try it now!
            </Button>
          }
        />
      }
    />
  );

  const invoicesTable = (
    <OverallViewTable
      columns={invoicesTableColumns}
      data={invoicesResponse?.data.results || []}
      totalRecords={invoicesResponse?.data.count}
      isLoading={isLoadingInvoicesResponse}
      isError={isErrorInvoicesResponse}
      externalPaginationProps={{
        externalCurrentPage: apiListArgsState.page,
        updateExternalCurrentPage: updatePageHandler,
        isFetching: isFetchingInvoicesResponse,
      }}
    />
  );

  const invoicesFilters = (
    <Box display="flex" gap={3}>
      <DateRangePickerInput
        callbackHandler={updateSelectedDateRange}
        resetDateHandler={resetDateRange}
        defaultDate={{
          startDate: selectedFiltersState?.date_from
            ? moment(selectedFiltersState.date_from, "YYYY-MM-DD").toDate()
            : undefined,
          endDate: selectedFiltersState?.date_to
            ? moment(selectedFiltersState.date_to, "YYYY-MM-DD").toDate()
            : undefined,
        }}
        disabled={userOnFreePlan}
      />
      <FormControl
        size="small"
        sx={{ width: "200px" }}
        disabled={userOnFreePlan}
      >
        <InputLabel>{"Select a Status"}</InputLabel>
        <Select
          value={selectedFiltersState.status ? selectedFiltersState.status : ""}
          onChange={updateSelectedStatus}
          label={"Select a Status"}
        >
          {statusesOptions.map((option, index) => {
            return (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );

  return (
    <TableContainer label={"Invoices"} tableFiltersBelowName={invoicesFilters}>
      {userOnFreePlan ? invoicesTableViewForFreePlan : invoicesTable}
    </TableContainer>
  );
};

const invoiceStatusSemantics = {
  positive: "positive",
  neutral: "neutral",
  negative: "negative",
};

const InvoiceStatus = ({ lightColor, solidColor, value }) => {
  return (
    <Box
      p={1}
      borderRadius={4}
      width="fit-content"
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={0.7}
      // width="72px"
      height="24px"
      bgcolor={lightColor}
      color={solidColor}
      boxSizing="border-box"
    >
      <Box
        width="6px"
        height="6px"
        bgcolor={solidColor}
        display="inline-block"
        borderRadius="50%"
      ></Box>
      <Typography
        variant="text-sm"
        sx={{ fontWeight: fontWeightVariations["medium-500"] }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const statusesOptions = [
  { label: "Show All", value: undefined },
  { label: "Next Payment", value: "next_payment" },
  { label: "Successful", value: "successful" },
  { label: "Failed", value: "failed" },
];
