import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { GenericAccordion } from "../../../reusable/components/GenericAccordion";
import { primaryColors, secondaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { useContext, useMemo, useReducer } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useDropzone } from "react-dropzone";
import {
  CloudUpload,
  FileDownloadDone,
  Warning,
  WbIncandescent,
} from "@mui/icons-material";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
} from "../../../contexts/NotificationContext";
import readXlsxFile from "read-excel-file";
import { useEffect } from "react";
import { useCallback } from "react";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  SURVEY_TYPES,
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "./sharedVariables";
import { useSendBulkQRCodesForUnits } from "../api/sendBulkdQRCodesForUnits";
import { LoadingButton } from "@mui/lab";

export const GenerateQRCodesForUnitsAccordion = ({
  disabled,
  selectedBranchData,
  selectedDivisionData,
  selectedTemplateData,
}) => {
  const { fireNotification, closeNotification, notificationState } =
    useContext(NotificationContext);

  const sendBulkQRCodesForUnitsMutation = useSendBulkQRCodesForUnits();

  // Start: Reducer State Management
  const [stateOfQRCodesForUnits, dispatchAction] = useReducer(
    qrCodesGenerationForUnitsReducer,
    qrCodesGenerationForUnitsDefaultState
  );

  function updateSelectedSurveyTypeHandler(updatedSurveyType) {
    dispatchAction({
      type: qrCodesGenerationForUnitsDispatchActions[
        "UPDATE-SELECTED-SURVEY-TYPE"
      ],
      payload: { surveyType: updatedSurveyType },
    });
  }

  function updateImportedUnitsHandler(extractedUnitsNames) {
    dispatchAction({
      type: qrCodesGenerationForUnitsDispatchActions[
        "UPDATE-IMPORTED-ROOMS-DATA"
      ],
      payload: { importedUnitsData: extractedUnitsNames },
    });
  }

  function updateEmailHandler(updatedEmail) {
    dispatchAction({
      type: qrCodesGenerationForUnitsDispatchActions["UPDATE-EMAIL"],
      payload: { email: updatedEmail },
    });
  }
  // End: Reducer State Management

  // Start: React Dropzone
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".csv",
          ".xlsx",
        ],
        "application/vnd.ms-excel": [".csv", ".xlsx"],
      },
      onDropRejected: function () {
        fireNotification({
          title: "Error in Uploaded Unit Names Sheet",
          description: "Invalid File Type (Must be .csv or .xlsx)",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
          horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
          target: NOTIFICATIONS_TARGET.admin,
          width: NOTIFICATION_WIDTH.small,
        });
      },
      onDropAccepted: function (uploadedFiles) {
        try {
          readXlsxFile(uploadedFiles[0])
            .then((currSheet) => {
              let columnNames = currSheet[0];
              let index = columnNames.indexOf(
                columnNames.find((rowName) =>
                  rowName.toLowerCase().includes("unit")
                )
              );

              if (index === -1) {
                console.error("Column for units not found");

                fireNotification({
                  title: "Error in Uploaded Unit Names Sheet",
                  description: "Couldn't find a column labeled (Unit)",
                  type: NOTIFICATIONS_TYPE.error,
                  verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                  horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                  target: NOTIFICATIONS_TARGET.admin,
                  width: NOTIFICATION_WIDTH.small,
                });
              } else {
                const extractedUnitsNames = currSheet.reduce(
                  (accumlator, currRow) => {
                    const unitName = currRow[index]?.toString();
                    if (
                      !!unitName &&
                      unitName?.trim().length &&
                      unitName !== columnNames[index]
                    ) {
                      accumlator.push(unitName);
                    }
                    return accumlator;
                  },
                  []
                );

                if (extractedUnitsNames.length === 0) {
                  fireNotification({
                    title: "Error in Uploaded Unit Names Sheet",
                    description:
                      "No Units found in the imported Excel Sheet (Add at least 1)",
                    type: NOTIFICATIONS_TYPE.error,
                    verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                    horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                    target: NOTIFICATIONS_TARGET.admin,
                    width: NOTIFICATION_WIDTH.small,
                  });
                } else {
                  updateImportedUnitsHandler(extractedUnitsNames);
                }
              }
            })
            .catch((error) =>
              console.error("Failed to extract Unit Names", error)
            );
        } catch (exception) {
          console.error(
            "Error in try block for extracting Unit Names",
            exception
          );
        }
      },
      disabled,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  // End: React Dropzone

  const readyToGenerateQRCodes =
    stateOfQRCodesForUnits.surveyType !== undefined &&
    stateOfQRCodesForUnits.importedUnitsData !== undefined &&
    stateOfQRCodesForUnits.importedUnitsData.length > 0 &&
    stateOfQRCodesForUnits.emailIsValid === true;

  // Start: Dynamic Content for Unit Extracting Excel Sheet Import section
  let iconForUnitsExcelSheetSection = undefined;
  let helperContentForUnitsExcelSheetSection = undefined;
  if (stateOfQRCodesForUnits.importedUnitsData?.length >= 0) {
    // It means there's a file that was imported successfully
    iconForUnitsExcelSheetSection = (
      <FileDownloadDone
        sx={{
          width: "20px",
          height: "20px",
          mb: 2,
          p: 1,
          borderRadius: "50%",
          bgcolor: primaryColors.success[50],
          color: primaryColors.success[600],
        }}
      />
    );
    helperContentForUnitsExcelSheetSection = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="text-md" color={primaryColors.success[600]}>
          Units Excel Sheet Imported Successfully
        </Typography>
        <Typography cariant="text-xs" color={primaryColors.gray[500]}>
          Units Count:{" "}
          <span>{stateOfQRCodesForUnits.importedUnitsData?.length}</span>
        </Typography>
      </Box>
    );
  } else {
    iconForUnitsExcelSheetSection = (
      <CloudUpload
        sx={{
          width: "20px",
          height: "20px",
          mb: 2,
          p: 1,
          borderRadius: "50%",
          bgcolor: primaryColors.gray[100],
          color: primaryColors.gray[600],
        }}
      />
    );
    helperContentForUnitsExcelSheetSection = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="text-md" color={primaryColors.gray[500]}>
          <span style={{ color: primaryColors.brand[500] }}>
            Click to upload
          </span>{" "}
          or drag and drop
        </Typography>
        <Typography cariant="text-xs" color={primaryColors.gray[500]}>
          5 MB max file size. Supported extensions .csv .xlsx
        </Typography>
      </Box>
    );
  }
  // End: Dynamic Content for Unit Extracting Excel Sheet Import section

  useEffect(
    function closeNotificationErrorWhenTemplateIsSelected() {
      if (
        selectedTemplateData !== undefined &&
        notificationState.isOpen &&
        notificationState.type === NOTIFICATIONS_TYPE.error
      ) {
        closeNotification();
      }
    },
    [
      closeNotification,
      notificationState.isOpen,
      notificationState.type,
      selectedTemplateData,
    ]
  );

  return (
    <GenericAccordion
      title={
        <Typography
          variant="text-md"
          color={primaryColors.base.black}
          fontWeight={fontWeightVariations["semiBold-600"]}
        >
          QR Codes for Operation Units
        </Typography>
      }
      backgroundColor={primaryColors.gray[200]}
      body={
        <Box display="flex" flexDirection="column" gap={1}>
          {/* Start: Helper Text */}
          <Box
            display="flex"
            p={2}
            gap={2}
            bgcolor={secondaryColors.blueSecondary[100]}
            border={1}
            borderColor={secondaryColors.blueSecondary[700]}
            borderRadius={3}
            sx={{ cursor: "default" }}
          >
            <WbIncandescent
              sx={{
                width: "20px",
                height: "20px",
                color: secondaryColors.blueSecondary[700],
              }}
            />
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                variant="text-sm"
                color={secondaryColors.blueSecondary[700]}
              >
                Smallest unit of operation in your facility (Ex: Rooms,Tables,
                etc.)
              </Typography>
            </Box>
          </Box>
          {/* End: Helper Text */}
          {/* Start: Survey Type */}
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography
              variant="text-sm"
              fontWeight={fontWeightVariations["medium-500"]}
            >
              Survey Type
            </Typography>
            <FormControl
              fullWidth
              sx={{
                bgcolor: primaryColors.base.white,
                height: "44px",
                borderRadius: 1,
              }}
            >
              <Select
                value={stateOfQRCodesForUnits.surveyType}
                onChange={function updateSurveryType(event) {
                  updateSelectedSurveyTypeHandler(event.target.value);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "44px",
                  //   boxShadow: "none",
                  //   ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                disabled={disabled}
              >
                <MenuItem value={SURVEY_TYPES.Both}>Both</MenuItem>
                <MenuItem value={SURVEY_TYPES.Review}>Review</MenuItem>
                <MenuItem value={SURVEY_TYPES.Complaint}>Complaint</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* End: Survey Type */}
          {/* Start: CSV File Dropzone */}
          {/* <DropzoneArea
                useChipsForPreview={true}
                filesLimit={1}
                Icon={CloudUploadOutlinedIcon}
                showPreviews={false}
                showPreviewsInDropzone={false}
              /> */}
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box display="flex" flexDirection="column" alignItems="center">
              {iconForUnitsExcelSheetSection}
              {helperContentForUnitsExcelSheetSection}
            </Box>
          </Box>
          <Box
            display="flex"
            p={2}
            gap={2}
            bgcolor={primaryColors.warning[25]}
            border={1}
            borderColor={primaryColors.warning[300]}
            borderRadius={3}
            sx={{ cursor: "default" }}
          >
            <Warning
              sx={{
                width: "20px",
                height: "20px",
                color: primaryColors.warning[500],
              }}
            />
            <Typography variant="text-md" color={primaryColors.warning[700]}>
              The column that contains the unit name must have a header title of{" "}
              <span
                style={{ fontWeight: fontWeightVariations["semiBold-600"] }}
              >
                Unit
              </span>
              . (With at least 1 unit)
            </Typography>
          </Box>
          {/* End: CSV File Dropzone */}
          {/* Start: Email */}
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography
              variant="text-sm"
              fontWeight={fontWeightVariations["medium-500"]}
            >
              Email
            </Typography>
            <Box display="flex" gap={2}>
              <FormControl
                fullWidth
                sx={{ bgcolor: primaryColors.base.white, height: "44px" }}
                disabled={disabled}
              >
                <OutlinedInput
                  placeholder="Please enter a valid Email to Receive the QR Codes"
                  value={stateOfQRCodesForUnits.email}
                  onChange={function (event) {
                    updateEmailHandler(event.target.value);
                  }}
                  sx={{ height: "44px" }}
                />
              </FormControl>
              <LoadingButton
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  color: primaryColors.base.white,
                  height: 42,
                  boxShadow: "none",
                }}
                disabled={!readyToGenerateQRCodes || disabled}
                onClick={function () {
                  sendBulkQRCodesForUnitsMutation.mutateAsync({
                    branchID: selectedBranchData.id,
                    divisionID: selectedDivisionData.id,
                    templateID: selectedTemplateData.id,
                    validUnitsData: stateOfQRCodesForUnits.importedUnitsData,
                    type: stateOfQRCodesForUnits.surveyType,
                    email: stateOfQRCodesForUnits.email,
                  });
                }}
                loading={sendBulkQRCodesForUnitsMutation.isLoading}
              >
                <Typography
                  variant="text-md"
                  fontWeight={fontWeightVariations["medium-500"]}
                >
                  Send
                </Typography>
              </LoadingButton>
            </Box>
          </Box>
          {/* End: Email */}
        </Box>
      }
      preventExpansion={disabled}
      onClickCallback={function showNotificationErrorForMissinTemplateForQRCodes() {
        if (disabled) {
          fireNotification({
            title: "Please, Select first a Branch, Division, and Template",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
            horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
            width: NOTIFICATION_WIDTH.small,
            target: NOTIFICATIONS_TARGET.admin,
            closeAfter: 60000,
          });
        }
      }}
    />
  );
};

const qrCodesGenerationForUnitsDefaultState = {
  surveyType: SURVEY_TYPES.Both,
  importedUnitsData: undefined,
  email: undefined,
  emailIsValid: false,
};

const qrCodesGenerationForUnitsReducer = (state, action) => {
  switch (action.type) {
    case qrCodesGenerationForUnitsDispatchActions[
      "UPDATE-SELECTED-SURVEY-TYPE"
    ]: {
      return {
        ...state,
        surveyType: action.payload.surveyType,
      };
    }
    case qrCodesGenerationForUnitsDispatchActions[
      "UPDATE-IMPORTED-ROOMS-DATA"
    ]: {
      return {
        ...state,
        importedUnitsData: action.payload.importedUnitsData,
      };
    }
    case qrCodesGenerationForUnitsDispatchActions["UPDATE-EMAIL"]: {
      const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      return {
        ...state,
        email: action.payload.email,
        emailIsValid: emailRegex.test(action.payload.email),
      };
    }
    default: {
      return state;
    }
  }
};

export const qrCodesGenerationForUnitsDispatchActions = {
  "UPDATE-SELECTED-SURVEY-TYPE": "UPDATE-SELECTED-SURVEY-TYPE",
  "UPDATE-IMPORTED-ROOMS-DATA": "UPDATE-IMPORTED-ROOMS-DATA",
  "UPDATE-EMAIL": "UPDATE-EMAIL",
};
