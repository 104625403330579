import { useCallback, useReducer } from "react";

export const useDynamicFiltersState = ({
  isEachFilterIndependent,
  serverFiltersData = [],
  initialFiltersState,
}) => {
  const [selectedFiltersState, dispatchToSelectedFilters] = useReducer(
    filtersReducer,
    getFiltersInitialState({ serverFiltersData, initialFiltersState })
  );
  
  const replaceFilterState = useCallback(({ newFiltersData }) => {
    dispatchToSelectedFilters({
      type: filtersActions.REPLACE_FILTER_STATE,
      payload: {
        newFiltersData,
      },
    });
  }, []);
  
  const updateSelectedFilters = (event) => {
    const changedFilterIndex = serverFiltersData.findIndex(
      function isFilterChanged(filter) {
        return filter.dropdownName === event.target.name;
      }
    );
    if (event.target.value === "all") {
      dispatchToSelectedFilters({
        type: filtersActions.SHOW_ALL,
        payload: {
          dropdownName: event.target.name,
          selectedOption: event.target.value,
          serverFiltersData,
        },
      });
    } else {
      const isHighestLevelChange = changedFilterIndex === 0;
      const isLowestLevelChange =
        changedFilterIndex === serverFiltersData.length - 1;
      const actionType = isEachFilterIndependent
        ? filtersActions.UPDATE_INDEPENDENT_FILTER
        : isHighestLevelChange
        ? filtersActions.UPDATE_HIGHEST_LEVEL_FILTER
        : isLowestLevelChange
        ? filtersActions.UPDATE_LOWEST_LEVEL_FILTER
        : filtersActions.UPDATE_MID_LEVEL_FILTER;

      dispatchToSelectedFilters({
        type: actionType,
        payload: {
          dropdownName: event.target.name,
          selectedOption: event.target.value,
          serverFiltersData,
        },
      });
    }
  };

  return {
    selectedFiltersState,
    updateSelectedFilters,
    replaceFilterState,
  };
};

function initiateParentOptionsList({
  selectedFiltersState,
  changedDropdownName,
  selectedOption,
}) {
  if (selectedOption === "all") {
    // get changed filter data from current selected state
    const selectedDropdownCurrentState = selectedFiltersState.filtersData.find(
      function isFilterChanged(filterData) {
        return filterData.dropdownName === changedDropdownName;
      }
    );
    return selectedDropdownCurrentState.dropdownOptions.map(
      function getOptionId(optionData) {
        return optionData.value;
      }
    );
  } else {
    return [selectedOption];
  }
}
function prepareLowerLevelFiltersState({
  serverFiltersData,
  selectedDropdownServerData,
  newSelectedFiltersState,
  parentOptionsIds,
}) {
  for (
    let currentFilter =
      serverFiltersData.indexOf(selectedDropdownServerData) + 1;
    currentFilter <= serverFiltersData.length - 1;
    currentFilter++
  ) {
    let filteredDropdownOptions = serverFiltersData[
      currentFilter
    ].dropdownOptions.filter(function isChildOfSelectedOption(optionData) {
      if (optionData.value === "select all") return true;
      // if the parent of current option exists in parents list add the option to filtered dropdown optionss
      else if (parentOptionsIds.includes(optionData.parent)) {
        // add current option id to parents list
        parentOptionsIds.push(optionData.value);
        return true;
      } else return false;
    });

    // replace server options with filtered options
    newSelectedFiltersState.filtersData.push({
      ...serverFiltersData[currentFilter],
      dropdownOptions: filteredDropdownOptions,
    });

    // set selected option empty
    newSelectedFiltersState[serverFiltersData[currentFilter].dropdownName] = "";
  }
  return newSelectedFiltersState;
}
function prepareFiltersDataDownwards({
  serverFiltersData,
  dropdownName,
  selectedOption,
  selectedFiltersState,
}) {
  let newSelectedFiltersState = {
    [dropdownName]: selectedOption,
    filtersData: [],
  };
  // get changed filter data from server response
  const selectedDropdownServerData = serverFiltersData.find(
    function isFilterChanged(filterData) {
      return filterData.dropdownName === dropdownName;
    }
  );

  // add server dropdowns in their original state to newSelectedFiltersState
  for (
    let currentFilter = 0;
    currentFilter <= serverFiltersData.indexOf(selectedDropdownServerData) - 1;
    currentFilter++
  ) {
    newSelectedFiltersState.filtersData.push(serverFiltersData[currentFilter]);
  }
  //push changed filter options to filtersData
  newSelectedFiltersState.filtersData.push(
    ...selectedFiltersState.filtersData.filter(function isChangedFilter(
      filterData
    ) {
      return filterData.dropdownName === dropdownName;
    })
  );

  let parentOptionsIds = initiateParentOptionsList({
    selectedFiltersState,
    changedDropdownName: dropdownName,
    selectedOption,
  });

  // set selected Option + filter dropdowns options to display children of selected parent only
  newSelectedFiltersState = prepareLowerLevelFiltersState({
    serverFiltersData,
    selectedDropdownServerData,
    newSelectedFiltersState,
    parentOptionsIds,
  });

  return newSelectedFiltersState;
}

function prepareFiltersDataUpwards({
  serverFiltersData,
  dropdownName,
  selectedOption,
}) {
  let selectedFiltersState = {
    [dropdownName]: selectedOption,
  };
  // get changed filter and get parent id of the selected option
  const selectedDropdown = serverFiltersData.find(function isFilterChanged(
    filterData
  ) {
    return filterData.dropdownName === dropdownName;
  });
  let parentOptionId = selectedDropdown.dropdownOptions.find(
    function isOptionSelected(optionData) {
      return optionData.value === selectedOption;
    }
  ).parent;

  for (
    let currentFilter = serverFiltersData.indexOf(selectedDropdown) - 1;
    currentFilter >= 0;
    currentFilter--
  ) {
    // select parent of selected option in the current level
    selectedFiltersState[serverFiltersData[currentFilter].dropdownName] =
      parentOptionId;
    // get the parent in the upper level
    parentOptionId = serverFiltersData[currentFilter].dropdownOptions.find(
      function isParentOption(optionData) {
        return optionData.value === parentOptionId;
      }
    ).parent;
  }

  return selectedFiltersState;
}

function getFiltersInitialState({
  serverFiltersData = [],
  initialFiltersState,
  clearSelectedFilters,
}) {
  let initialState = {
    ...initialFiltersState,
    filtersData: serverFiltersData,
  };
  if (clearSelectedFilters) {
    for (const filterData of serverFiltersData) {
      initialState[filterData.dropdownName] = "";
    }
  }
  return initialState;
}

const filtersReducer = (selectedFiltersState, action) => {
  switch (action.type) {
    case filtersActions.REPLACE_FILTER_STATE: {
      return {
        ...selectedFiltersState,
        ...getFiltersInitialState({
          clearSelectedFilters: true,
          serverFiltersData: action.payload.newFiltersData,
          initialFiltersState: selectedFiltersState,
        }),
      };
    }
    case filtersActions.UPDATE_INDEPENDENT_FILTER: {
      return {
        ...selectedFiltersState,
        [action.payload.dropdownName]: action.payload.selectedOption,
      };
    }
    case filtersActions.UPDATE_HIGHEST_LEVEL_FILTER: {
      const { filtersData, ...selectedChildrenFilters } =
        prepareFiltersDataDownwards({
          ...action.payload,
          selectedFiltersState,
        });
      return {
        ...selectedFiltersState,
        ...selectedChildrenFilters,
        filtersData,
      };
    }
    case filtersActions.UPDATE_MID_LEVEL_FILTER: {
      const { filtersData, ...selectedChildrenFilters } =
        prepareFiltersDataDownwards({
          ...action.payload,
          selectedFiltersState,
        });
      const selectedParentsFilters = prepareFiltersDataUpwards({
        ...action.payload,
      });
      return {
        ...selectedFiltersState,
        ...selectedParentsFilters,
        ...selectedChildrenFilters,
        filtersData,
      };
    }
    case filtersActions.UPDATE_LOWEST_LEVEL_FILTER: {
      const selectedParentsFilters = prepareFiltersDataUpwards({
        ...action.payload,
      });
      return {
        ...selectedFiltersState,
        ...selectedParentsFilters,
      };
    }
    case filtersActions.SHOW_ALL: {
      const { filtersData, ...selectedChildrenFilters } =
        prepareFiltersDataDownwards({
          ...action.payload,
          selectedFiltersState,
        });

      return {
        ...selectedFiltersState,
        ...selectedChildrenFilters,
        filtersData,
        [action.payload.dropdownName]: action.payload.selectedOption, //all
      };
    }
    default:
      return selectedFiltersState;
  }
};

const filtersActions = {
  REPLACE_FILTER_STATE: "replace_filter_state",
  UPDATE_INDEPENDENT_FILTER: "update_independent_filter",
  UPDATE_HIGHEST_LEVEL_FILTER: "update_highest_level_filter",
  UPDATE_MID_LEVEL_FILTER: "update_mid_level_filter",
  UPDATE_LOWEST_LEVEL_FILTER: "update_lowest_level_filter",
  SHOW_ALL: "show-all",
};

export const getLowestLevelSelectedFilter = ({
  selectedFiltersState,
  filtersData = [],
}) => {
  for (
    let currentFilterIndex = filtersData.length - 1;
    currentFilterIndex >= 0;
    currentFilterIndex--
  ) {
    const currentFilterName = filtersData[currentFilterIndex].dropdownName;
    if (
      selectedFiltersState[currentFilterName] &&
      selectedFiltersState[currentFilterName] !== "all"
    )
      return selectedFiltersState[currentFilterName];
  }
};

export const renameDropdownFields = (dropdownsData) => {
  return dropdownsData?.map(function rewriteFieldsInCamelCase(dropdownData) {
    return {
      dropdownName: dropdownData.dropdown_name,
      dropdownOptions: dropdownData.dropdown_options,
    };
  });
};
