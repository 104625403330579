import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const changeFacilityInfo = ({
  facility_name,
  facility_logo,
  facility_country,
}) => {
  let requestURL = `${API_URL}/facility/info/change`;
  var bodyFormData = new FormData();
  facility_name && bodyFormData.append("facility_name", facility_name);
  facility_country && bodyFormData.append("facility_country", facility_country);
  if (facility_logo) bodyFormData.append("facility_logo", facility_logo);

  return melior.patch(requestURL, bodyFormData);
};

export const useChangeFacilityInfo = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: changeFacilityInfo,
  });
};
