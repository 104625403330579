import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";

export const FormContainer = ({
  formTitle,
  formState,
  submitting,
  resetCallback,
  children,
}) => {
  return (
    <>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: fontSizeVariations["text-lg"],
          fontWeight: fontWeightVariations["bold-700"],
          color: primaryColors.gray[900],
          marginBottom: "20px",
        }}
      >
        {formTitle}
      </Typography>
      {children}
      <Grid
        container
        justifyContent="flex-end"
        direction="row"
        padding="20px 0px"
        borderBottom={`1px solid ${primaryColors.gray[200]}`}
        gap="10px"
      >
        <Button
          variant="text"
          disabled={!formState.isDirty || submitting}
          onClick={resetCallback}
          sx={{
            backgroundColor: primaryColors.base.white,
            border: "1px solid",
            borderColor: formState.isDirty
              ? primaryColors.base.black
              : primaryColors.gray[300],
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: primaryColors.base.white,
            },
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",

              fontFamily: "Nunito",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations["medium-500"],
              color: formState.isDirty
                ? primaryColors.base.black
                : primaryColors.gray[300],
            }}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          variant="text"
          type="submit"
          disabled={!formState.isDirty || submitting}
          sx={{
            backgroundColor: formState.isDirty
              ? primaryColors.warning[500]
              : primaryColors.warning[200],
            border: "1px solid",
            borderColor: formState.isDirty
              ? primaryColors.warning[500]
              : primaryColors.warning[200],
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: primaryColors.warning[500],
            },
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",

              fontFamily: "Nunito",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations["medium-500"],
              color: primaryColors.base.white,
            }}
          >
            {submitting ? <CircularProgress size={20} /> : "Save"}
          </Typography>
        </Button>
      </Grid>
    </>
  );
};
