import { useContext, useState } from "react";
import UserSettingsIcon from "@mui/icons-material/SupervisedUserCircle";
import BranchSettingsIcon from "@mui/icons-material/AccountTree";
import { useParams } from "react-router-dom";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { MeliorTabs } from "../../../reusable/components/MeliorTabs";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import { COLORS } from "../../../helpers/Colors";
import { IssuesAdminPage } from "../components/IssuesAdminPage";
import TemplatesAdminPage from "../components/TemplatesAdminPage";
import { ScreenContainer } from "../../../layout/ScreenContainer";

export const WorkspaceScreen = () => {
  const { isFacilityManager, isDemo } = useContext(AuthenticationContext);
  const [isAllowedToAllSettings] = useState(isFacilityManager || isDemo);

  const { facilityManTab: facilityManTabRouteParam } = useParams();

  const tabsData = [
    // {
    //   label: "Main Settings",
    //   routeParam: "main",
    //   tabContent: <MainSettings disableEdits={isDemo} />,
    //   icon: MainSettingsIcon,
    // },
  ];

  if (isAllowedToAllSettings) {
    tabsData.push({
      label: "Issues",
      routeParam: "issues",
      tabContent: <IssuesAdminPage disableEdits={isDemo} />,
      icon: BranchSettingsIcon,
    });
    tabsData.push({
      label: "Templates",
      routeParam: "templates",
      tabContent: null,
      icon: UserSettingsIcon,
    });
  }

  return (
    <ScreenContainer>
      <MeliorTabs
        rootRouteParam="facility-management"
        dynamicRouteParam={facilityManTabRouteParam}
        tabsData={tabsData}
        customMessage={
          isDemo && (
            <UserErrorSuccessFormDisplay
              bg={COLORS.meliorYellowGentle}
              type="info"
              message={`This is a demo account, Some features might be disabled`}
              width="80%"
              margin="10px auto"
            />
          )
        }
      />
    </ScreenContainer>
  );
};
