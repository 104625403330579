import { FormControl, OutlinedInput, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useEditTemplateGroupName } from "../../api/editTemplateGroupName";

export const EditTemplateGroupName = ({ templateGroupToEdit, muiTableRowObject }) => {
  const [editedTemplateGroupName, setEditedTemplateGroupName] = useState("");

  const updateTemplateGroupNameMutation = useEditTemplateGroupName();

  useEffect(
    function initializeEditedTemplateGroupNameState() {
      setEditedTemplateGroupName(templateGroupToEdit.name);
    },
    [templateGroupToEdit]
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="end"
      p={4}
      bgcolor={primaryColors.gray[100]}
    >
      <Box display="flex" flexDirection="column" alignItems="start" gap={1}>
        <Typography>Template Name</Typography>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <OutlinedInput
            value={editedTemplateGroupName}
            onChange={function updateNewTemplateGroupNameHandler(event) {
              setEditedTemplateGroupName(event.target.value);
            }}
            sx={{
              bgcolor: primaryColors.base.white,
            }}
          />
        </FormControl>
      </Box>
      <LoadingButton
        title={"Save Edited Template Group Name"}
        loading={updateTemplateGroupNameMutation.isLoading}
        onClick={function saveEditedTemplateGroupNameHandler(e) {
          updateTemplateGroupNameMutation.mutateAsync({
            templateGroupID: templateGroupToEdit.id,
            editedTemplateGroupName,
          }).then(function templateGroupNameSuccessfulMutationHandler(){
            muiTableRowObject.toggleExpanded(false);
          });
          e.stopPropagation();
        }}
        sx={{
          borderRadius: "8px",
          padding: "10px 16px",
          height: "fit-content",
          backgroundColor: primaryColors.brand[500],
          "&:hover": {
            backgroundColor: primaryColors.brand[500],
          },

          textTransform: "none",
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations.semiBold,
          color: primaryColors.base.white,
        }}
        startIcon={<Save sx={{ color: primaryColors.base.white }} />}
      >
        Save
      </LoadingButton>
    </Box>
  );
};
