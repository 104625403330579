import { primaryColors } from "../../../helpers/customColors";

export const SURVEY_TYPES = {
  Both: "both",
  Review: "review",
  Complaint: "complaint",
};

export const baseStyle = {
  flex: 1,
  display: "flex",
  height: "128px",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  cursor: "pointer",
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: primaryColors.base.white,
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export const focusedStyle = {
  borderColor: primaryColors.brand[500],
};

export const acceptStyle = {
  borderColor: primaryColors.success[500],
};

export const rejectStyle = {
  borderColor: primaryColors.error[500],
};
