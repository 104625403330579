import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { primaryColors } from "../../../helpers/customColors";
import { useContext, useEffect, useRef, useState } from "react";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { ComplaintsOverViewSubScreen } from "./ComplaintsOverviewSubScreen";
import { ReputationDashboardSubScreen } from "./ReputationDashboardSubScreen";
import { ReviewsOverviewSubScreen } from "./ReviewsOverviewSubScreen";
import { RevampedLandingPage } from "../../revamped-landing-page/components/RevampedLandingPage";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { RevampedInsightsFilters } from "../components/shared/RevampedInsightsFilters";
import {
  splitBranchesAndDivisionsDataIntoSeprateLists,
  useInsightsFiltersState,
} from "../hooks/useInsightsFiltersState";
import { BROWSERS, whichBrowser } from "../../../helpers/browserChecking";
import { useGetAllBranchesWithoutPagination } from "../../settings/api/branches-divisions/getAllBranchesWithoutPagination";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { Print } from "@mui/icons-material";
import { useGetTeams } from "../../revamped-overall-view/api/getTeams";

export const TabbedLandingPageScreen = () => {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams(
    window.location.hash.substring(1)
  );
  const { landingPageTab } = useParams();
  const [selectedTab, setSelectedTab] = useState(
    LANDING_PAGE_TABS[landingPageTab]
  );
  const { user, canUseReputationDashboard } = useContext(AuthenticationContext);
  const [branchesAndDivisionsData, setBranchesAndDivisionsData] = useState([]);
  //   const [relatedTeamsOptions, setRelatedTeamsOptions] = useState([]);

  const printingAreaRef = useRef();
  const {
    data: branchesAndDivisionsServerResponse,
    isFetching: isFetchingBranchesAndDivisions,
    refetch: refetchBranchesAndDivisions,
  } = useGetAllBranchesWithoutPagination({
    config: { enabled: false },
  });
  const {
    data: relatedTeamsServerResponse,
    isFetching: isFetchingRelatedTeams,
    refetch: refetchRelatedTeams,
  } = useGetTeams({
    config: { enabled: false },
  });

  const {
    selectedFiltersState,
    replaceFilterState,
    updateSelectedDateRange,
    resetSelectedDateRange,
    updateSelectedBranches,
    removeSpecificBranch,
    updateSelectedDivisions,
    removeSpecificDivision,
    updateSelectedRelatedTeams,
    removeSpecificRelatedTeam,
  } = useInsightsFiltersState({
    branchesData: branchesAndDivisionsData?.branches,
    divisionsData: branchesAndDivisionsData?.divisions,
    relatedTeamsData: relatedTeamsServerResponse?.data.map(
      function mapRelatedTeamRawData(relatedTeamRawData) {
        return {
          id: relatedTeamRawData.id,
          name: relatedTeamRawData.name,
        };
      }
    ),
  });

  // Start: Set printing default settings for Chrome and other Browsers
  const currentBrowser = whichBrowser();
  const pageStyle =
    currentBrowser === BROWSERS.Chrome
      ? `
        @media print {
            @page {
                size: A5;
            }
            body {
                zoom: 35%;
                margin: 30px 30px 30px 30px;
            }
        } 
    `
      : `
        @media print {
            body {
                margin: 30px 30px 30px 30px;
            }
        } 
    `;
  // End: Set printing default settings for Chrome and Browsers

  const printHandler = useReactToPrint({
    content: () => printingAreaRef.current,
    documentTitle: `Reviews Insights From ${moment(
      selectedFiltersState.startDate
    ).format("DD-MMM-YYYY")} | To ${moment(selectedFiltersState.endDate).format(
      "DD-MMM-YYYY"
    )}`,
    // pageStyle={`{ size: 2.5in 4in }`}
  });

  useEffect(
    function splitAndSetServerBranchesAndDivisionsWhenReady() {
      if (!branchesAndDivisionsServerResponse) refetchBranchesAndDivisions();
      if (!relatedTeamsServerResponse) refetchRelatedTeams();

      if (
        !isFetchingBranchesAndDivisions &&
        branchesAndDivisionsServerResponse &&
        !isFetchingRelatedTeams &&
        relatedTeamsServerResponse
      ) {
        const { branches, divisions } =
          splitBranchesAndDivisionsDataIntoSeprateLists(
            branchesAndDivisionsServerResponse
          );
        setBranchesAndDivisionsData({ branches, divisions });
        replaceFilterState({
          branchesData: branches,
          divisionsData: divisions,
          relatedTeamsData: relatedTeamsServerResponse.data,
        });
      }
    },
    [
      branchesAndDivisionsServerResponse,
      isFetchingBranchesAndDivisions,
      isFetchingRelatedTeams,
      refetchBranchesAndDivisions,
      refetchRelatedTeams,
      relatedTeamsServerResponse,
      relatedTeamsServerResponse?.data,
      replaceFilterState,
    ]
  );
  useEffect(() => {
    setSelectedTab(LANDING_PAGE_TABS[landingPageTab]);
  }, [landingPageTab]);

  useEffect(
    function redirectToSettings() {
      const authorizationCode = queryParams.get("code");
      if (authorizationCode) {
        localStorage.setItem("googleOAuthCode", authorizationCode);
        navigate("/settings/profile#platforms");
      }
    },
    [navigate, queryParams]
  );

  return (
    <ScreenContainer>
      <Typography
        variant="display-md"
        fontWeight={fontWeightVariations.semiBold}
      >
        Hello, {user.username}
      </Typography>
      {/* Start: Separator */}
      <Box
        width="100%"
        marginY="24px"
        borderBottom={`1px solid ${primaryColors.gray[300]}`}
      ></Box>
      {/* End: Separator */}
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={function changeSelectedTab(event, newSelectedTab) {
          navigate(`/${LANDING_PAGE_TABS[newSelectedTab]}`);
          setSelectedTab(newSelectedTab);
        }}
        textColor="inherit"
        indicatorColor="transparent"
        sx={{
          background: primaryColors.gray[100],
          borderRadius: "8px",
          padding: "6px",
          "& .MuiTab-root": {
            textTransform: "none",
            color: primaryColors.gray[700],
            background: primaryColors.base.white,
            fontSize: fontSizeVariations["text-md"],
            fontWeight: fontWeightVariations.bold,
            borderRadius: "6px",
            marginInline: "4px",
          },
          "& .Mui-selected": {
            color: primaryColors.base.white,
            background: primaryColors.brand[500],
          },
        }}
      >
        <Tab
          label="Overview"
          value={LANDING_PAGE_TABS.overview}
          sx={{ transition: "0.5s" }}
        />
        <Tab
          label="Reviews"
          value={LANDING_PAGE_TABS.reviews}
          sx={{ transition: "0.5s" }}
        />
        <Tab
          label="Complaints"
          value={LANDING_PAGE_TABS.complaints}
          sx={{ transition: "0.5s" }}
        />
        {canUseReputationDashboard && (
          <Tab
            label="Reputation"
            value={LANDING_PAGE_TABS.reputation}
            sx={{ transition: "0.5s" }}
          />
        )}
      </Tabs>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap={2}
        width="100%"
        position="relative"
        top={45}
      >
        {/* <Link to="reports-hub">
            <Button
              variant="outlined"
              startIcon={<Assessment />}
              sx={{
                textTransform: "none",
                color: primaryColors.brand[800],
                borderColor: primaryColors.base.white,
                bgcolor: primaryColors.brand[50],
                boxShadow: "none",
                // width: "92px",
                height: "40px",
                borderRadius: "8px",
                "&:hover": {
                  borderColor: primaryColors.base.white,
                },
              }}
            >
              Reports Hub
            </Button>
          </Link> */}
        <Button
          onClick={printHandler}
          variant="contained"
          startIcon={<Print />}
          sx={{
            textTransform: "none",
            color: primaryColors.base.white,
            boxShadow: "none",
            //   width: "92px",
            height: "40px",
            borderRadius: "8px",
          }}
        >
          Print or Save as PDF
        </Button>
      </Box>
      <Box ref={printingAreaRef}>
        <style>{pageStyle}</style>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          mb="24px"
        >
          <RevampedInsightsFilters
            dateRangeFilter={{
              selectedDateRange: {
                startDate: selectedFiltersState.startDate,
                endDate: selectedFiltersState.endDate,
              },
              onChange: updateSelectedDateRange,
              onReset: resetSelectedDateRange,
            }}
            branchesFilter={{
              options: selectedFiltersState.branchesDropdownOptions,
              selectedBranches: selectedFiltersState.branches,
              onChange: updateSelectedBranches,
              removeSpecificBranch,
            }}
            divisionsFilter={{
              options: selectedFiltersState.divisionsDropdownOptions,
              selectedDivisions: selectedFiltersState.divisions,
              onChange: updateSelectedDivisions,
              removeSpecificDivision,
              hidden: selectedTab === LANDING_PAGE_TABS.reputation,
            }}
            relatedTeamsFilter={{
              options: selectedFiltersState.relatedTeamsOptions,
              selectedRelatedTeams: selectedFiltersState.relatedTeams,
              onChange: updateSelectedRelatedTeams,
              removeSpecificRelatedTeam,
              hidden: selectedTab !== LANDING_PAGE_TABS.complaints,
            }}
          />
        </Box>

        {getSelectedTabContent({
          selectedTab,
          selectedFiltersState,
          branchesAndDivisionsData,
        })}
      </Box>
    </ScreenContainer>
  );
};

function getSelectedTabContent({
  selectedTab,
  selectedFiltersState,
  branchesAndDivisionsData,
}) {
  switch (selectedTab) {
    case LANDING_PAGE_TABS.overview:
      return (
        <RevampedLandingPage
          selectedFiltersState={selectedFiltersState}
          branchesAndDivisionsData={branchesAndDivisionsData}
        />
      );
    case LANDING_PAGE_TABS.reviews:
      return (
        <ReviewsOverviewSubScreen selectedFiltersState={selectedFiltersState} />
      );
    case LANDING_PAGE_TABS.complaints:
      return (
        <ComplaintsOverViewSubScreen
          selectedFiltersState={selectedFiltersState}
          branchesAndDivisionsData={branchesAndDivisionsData}
        />
      );
    case LANDING_PAGE_TABS.reputation:
      return (
        <ReputationDashboardSubScreen
          selectedFiltersState={selectedFiltersState}
        />
      );
    default:
      break;
  }
}

export const LANDING_PAGE_TABS = {
  overview: "overview",
  reputation: "reputation",
  complaints: "complaints",
  reviews: "reviews",
};
