import { OverallProgress } from "../../overall-view/components/OverallProgress";

export const ReviewsOverviewSubScreen = ({ selectedFiltersState }) => {
  return (
    <OverallProgress
      filters={{
        startDate: selectedFiltersState.startDate,
        endDate: selectedFiltersState.endDate,
        divisions: selectedFiltersState.divisions,
        branches: selectedFiltersState.branches,
        //   channels: selectedFiltersState.channels,
      }}
    />
  );
};
