import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../contexts/NotificationContext";
import { API_URL, melior } from "../../../helpers/constants";

const sendBulkSMSes = ({
  branchID,
  divisionID,
  templateID,
  validPhoneNumbers,
  scheduledDate,
  type,
}) => {
  const requestURL = `${API_URL}/feedback-invitation/send-sms`;

  return melior.post(requestURL, {
    branch_id: branchID,
    division_id: divisionID,
    template_id: templateID,
    mobile_numbers: validPhoneNumbers,
    scheduled_date: scheduledDate,
    type,
  });
};

export const useSendBulkSMSes = ({ config, params } = {}) => {
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: (data, variables) => {
      fireNotification({
        title: `SMSes will be sent to the submitted valid Numbers`,
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: (error) => {
      fireNotification({
        title:
          "Failed to Submit the request to send SMSes, please try again later.",
        description: error.response.data.detail,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: sendBulkSMSes,
  });
};
