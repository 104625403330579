import { IconButton, TextField } from "@mui/material";
import { primaryColors, secondaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import { Send } from "@mui/icons-material";
import { useState } from "react";
import { useSubmitSMSProvider } from "../api/submitSMSProvider";

export const SMSProviderInput = () => {
  const [SMSProvider, setSMSProvider] = useState();

  const {
    mutateAsync: submitSMSProviderMutation,
    isLoading: isSubmitSMSProviderLoading,
  } = useSubmitSMSProvider();

  return (
    <TextField
      label="Type your SMS provider website"
      variant="outlined"
      size="small"
      value={SMSProvider}
      onChange={function chnageSMSProvider(event) {
        setSMSProvider(event.target.value);
      }}
      sx={{
        width: "300px",
        borderRadius: "8px",
        background: primaryColors.base.white,
        fontFamily: "Nunito",
        fontWeight: fontWeightVariations.medium,
        fontSize: fontSizeVariations["text-md"],
        color: primaryColors.gray[500],
      }}
      InputProps={{
        endAdornment: (
          <IconButton
            disabled={isSubmitSMSProviderLoading}
            onClick={function submitSMSProvider() {
              submitSMSProviderMutation({ SMSProvider })
                .then(async () => {
                  //clear text field
                  setSMSProvider("");
                })
                .catch((error) => console.log(error));
            }}
            sx={{
              background: secondaryColors.blueSecondary[500],
              borderRadius: "8px",
              "&:hover": {
                background: secondaryColors.blueSecondary[500],
              },
            }}
          >
            <Send
              sx={{
                width: "20px",
                height: "20px",
                color: primaryColors.base.white,
              }}
            />
          </IconButton>
        ),
        sx: {
          padding: "2px 6px 2px 0px",
        },
      }}
    />
  );
};
