import { Container } from "@mui/material";
import { primaryColors } from "../../../helpers/customColors";
import { TemplateGroupsListTable } from "../components/template-groups-list/TemplateGroupsListTable";
import { ScreenContainer } from "../../../layout/ScreenContainer";

export const TemplateGroupsListScreen = () => {
  return (
    <ScreenContainer>
      <TemplateGroupsListTable />
    </ScreenContainer>
  );
};
