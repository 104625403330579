import { Box, Button, Typography } from "@mui/material";
import AnswerSettingsImage from "./AnswerOptionsImage.jpg";
import { primaryColors } from "../../../../helpers/customColors";
import { UpgradeToHigherPlanDialog } from "../template-groups-list/UpgradeToHigherPlanDialog";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { useContext } from "react";

export const TemplateGroupSettingsMenu = () => {
  const { canManageWorkspace } = useContext(AuthenticationContext);

  return (
    <Box width="100%" height="100%" position="relative">
      {!canManageWorkspace && upgradeOverlay}
      <img src={AnswerSettingsImage} height="fit-content" alt="nice" />
    </Box>
  );
};

const upgradeOverlay = (
  <Box
    position="absolute"
    display="block"
    width="100%"
    height="100%"
    top={0}
    bottom={0}
    right={0}
    left={0}
    bgcolor="rgba(0,0,0,0.8)"
    zIndex={10}
  >
    <Box
      display="flex"
      flexDirection="column"
      //   justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      zIndex={11}
      color={primaryColors.base.white}
      textAlign="center"
      gap={2}
      mt={30}
    >
      <Typography variant="text-md">
        Want to choose different sentences or questions?{" "}
      </Typography>
      <Typography variant="text-md">
        Upgrade now to have that flexibility!
      </Typography>
      <UpgradeToHigherPlanDialog
        triggerButton={
          <Button
            variant="contained"
            sx={{ color: primaryColors.base.white, textTransform: "none" }}
          >
            Upgrade
          </Button>
        }
      />
    </Box>
  </Box>
);
