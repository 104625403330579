import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

export const submitSMSProvider = ({ SMSProvider }) => {
  let requestURL = `${API_URL}/vendor-info-submission`;
  return melior.post(requestURL, { typed_text: SMSProvider });
};

export const useSubmitSMSProvider = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: submitSMSProvider,
  });
};
