import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { API_URL, melior } from "../../../helpers/constants";
import {
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NotificationContext,
  NOTIFICATIONS_HORIZONTAL_POSITION,
} from "../../../contexts/NotificationContext";

export const deleteTemplateGroup = ({ templateGroupID, templateGroupName }) => {
  let requestURL = `${API_URL}/template-group/${templateGroupID}`;

  return melior.delete(requestURL);
};

export const useDeleteTemplateGroup = ({ config } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries(["template-groups"], { type: "active" });
      fireNotification({
        title: `Template Group has been deleted`,
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: (error) => {
      console.error(error.response);
      fireNotification({
        title: `Failed to delete Template Group, please try again later`,
        description: error.response.data.detail,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: deleteTemplateGroup,
  });
};
