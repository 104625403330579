import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../contexts/NotificationContext";
import { API_URL, melior } from "../../../helpers/constants";

const generateLinkOrQRCode = ({
  forQRCode,
  branchID,
  divisionID,
  templateID,
  type,
}) => {
  const requestURL = `${API_URL}/feedback-invitation/generate/link?${
    forQRCode ? "qr_code" : ""
  }`;

  return melior.post(requestURL, {
    branch_id: branchID,
    division_id: divisionID,
    template_id: templateID,
    type,
  });
};

export const useGenerateLinkOrQRCode = ({ config, params } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["all-branches-paginated"], {
        type: "active",
      });
    //   fireNotification({
    //     title: "General Link Generate Successfully",
    //     type: NOTIFICATIONS_TYPE.success,
    //     verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
    //     target: NOTIFICATIONS_TARGET.admin,
    //   });
    },
    onError: (error) => {
      console.log(error.response, "onError error.response");
      fireNotification({
        title: "Failed to Generate General Link",
        description: error.response.data.detail,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: generateLinkOrQRCode,
  });
};
