import { Box, Typography } from "@mui/material";
import { DescriptionWithGuidance } from "./DescriptionWithGuidance";
import { primaryColors, secondaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { InsightComponentUI } from "./InsightComponentUI";
import { SMSProviderInput } from "./SMSProviderInput";
import { ReactComponent as GenerateLinkOrQRGuidance } from "../../../assets/onboarding-guidances/generate-link-guidance.svg";

export const GenerateLinkBody = () => {
  const distributionChannelsList = [
    {
      title: "General link / QR code",
      description:
        "You can make a survey link and share it on your social media or through automatic messages on WhatsApp for everyone to check out.",
    },
    {
      title: "Bulk SMS",
      action: <SMSProviderInput />,
      description: (
        <span>
          Depending on your plan, you can let us know your SMS provider, or
          contact us to use ours for sending a survey link via SMS. This way,
          you can track response rates, see how many SMS you sent, and check the
          number of survey responses you received.
          <br />
          <br />
          <Typography
            variant="text-sm"
            fontWeight={fontWeightVariations.medium}
            color={primaryColors.gray[900]}
          >
            Contact us at info@mymelior.com or get in touch with our team to
            know more about the SMS packages.
          </Typography>
        </span>
      ),
    },
    {
      title: "Bulk links",
      description:
        "You can also create individual links to each of your customers. To do so, you can simply upload an Excel sheet with the phone numbers you would like to send the survey to. In return, you'll get a sheet with two columns—one for the phone numbers and the other for the corresponding survey links. You can use this sheet with your SMS service provider to send individual messages to your customers, so even if they submit a response without a name or number, you'll still the source of each review or complaint.",
    },
    {
      title: "QR codes for Operation Units",
      description:
        "Depending on your plan, you can upload an Excel sheet with the numbers of your smallest operational units, like rooms or tables. In return, you'll get a zip file with a PDF of QR codes, each linked to its corresponding number. This is very useful for hospital rooms.",
    },
  ];
  return (
    <DescriptionWithGuidance
      textDescription={
        <span>
          You can send your short survey to your customers by simply creating a
          general link. Just click <strong>'copy'</strong> to copy the link to
          use.
          <br />
          <br />
          You can also create a <strong>'QR code'</strong>' to print on your
          publications or posters. Click “Create QR Code” to download it. Place
          it wherever your customer can scan it. By following these steps:
          <br />
          <br />
          <Typography
            color={primaryColors.gray[700]}
            variant="text-lg"
            fontFamily="Nunito"
            fontWeight={fontWeightVariations.bold}
          >
            Test it out by following these steps from the Distribution tab. To
            try it out, you must open the link on a different browser or using
            an Incognito tab. Give it a try and see how it looks!
          </Typography>
        </span>
      }
      guidanceImage={<GenerateLinkOrQRGuidance width="100%" />}
    >
      <InsightComponentUI title="Distribution channels:">
        {distributionChannelsList.map(function renderChannelsList(
          channelData,
          index
        ) {
          return channelData.channelDetails ? (
            channelData.channelDetails
          ) : (
            <Box display="flex" flexDirection="column" gap="4px" key={index}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  variant="text-sm"
                  fontFamily="Nunito"
                  fontWeight={fontWeightVariations.bold}
                  color={secondaryColors.blueSecondary[700]}
                >
                  {channelData.title}
                </Typography>
                {channelData.action}
              </Box>
              <Typography
                variant="text-sm"
                fontFamily="Nunito"
                fontWeight={fontWeightVariations.medium}
                color={secondaryColors.blueSecondary[600]}
              >
                {channelData.description}
              </Typography>
            </Box>
          );
        })}
      </InsightComponentUI>
    </DescriptionWithGuidance>
  );
};
