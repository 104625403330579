import {
  Box,
  Button,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useMemo } from "react";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import { OverallViewTable } from "../../../revamped-overall-view/components/shared/OverallViewTable";
import {
  useGetTemplateGroups,
  defaultTemplateGroupsListParams,
} from "../../api/getTemplateGroups";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  primaryColors,
  secondaryColors,
} from "../../../../helpers/customColors";
import { TableContainer } from "../../../../reusable/components/TableContainer";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { UpgradeToHigherPlanDialog } from "./UpgradeToHigherPlanDialog";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import moment from "moment";
import { AssignTemplateGroupToDivisionsDialog } from "./AssignTemplateGroupToDivisionsDialog";
import { Refresh, Warning } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { CreateTemplateGroupDialog } from "./CreateTemplateGroupDialog";
import { EditTemplateGroupName } from "./EditTemplateGroupName";
import { DeleteTemplateGroup } from "./DeleteTemplateGroup";

export const TemplateGroupsListTable = () => {
  const { canManageWorkspace } = useContext(AuthenticationContext);

  const navigate = useNavigate();

  const {
    apiListArgsState,
    updatePageHandler,
    initiateSearchHandler,
    resetSearchFilterState,
  } = useListAPIsStateManagement({
    initialState: {
      ...defaultTemplateGroupsListParams,
    },
  });

  const {
    data: templateGroupsServerResponse,
    isLoading: isLoadingTemplateGroups,
    isFetching: isFetchingTemplateGroups,
    isError: isErrorTemplateGroups,
    refetch: refetchTemplateGroups,
  } = useGetTemplateGroups({
    params: { page: apiListArgsState.page },
  });

  const templateGroupsTableColumns = useMemo(
    () => [
      {
        header: "Template Name",
        accessorFn: function renderTemplateGroupViewButton(templateGroupItem) {
          return (
            <Button
              title="Go to Template Group View"
              variant="text"
              sx={{
                color: primaryColors.base.black,
                textTransform: "capitalize",
                textDecoration: "underline",
                width: "100%",
                justifyContent: "start",
              }}
              onClick={function redirectToTemplateGroupViewScreen() {
                navigate(`${templateGroupItem.id}`);
              }}
            >
              {templateGroupItem.name}
            </Button>
          );
        },
        size: 50,
      },
      {
        header: "Questions Count",
        accessorKey: "questions",
        size: 50,
      },
      {
        header: "Created By",
        accessorKey: "created_by",
        size: 50,
      },
      {
        header: "Created At",
        accessorFn: function render(templateGroupItem) {
          return moment(templateGroupItem.created_at).format(
            "DD-MM-YYYY | HH:MM"
          );
        },
        size: 50,
      },

      {
        header: "",
        id: "actions",
        accessorFn: function renderTemplateGroupActionsColumnCell(
          templateGroupItem
        ) {
          return (
            <Box
              display="flex"
              gap={2}
              width="90%"
              justifyContent="flex-end"
              alignItems="center"
            >
              {templateGroupItem.divisions.length === 0 && (
                <Tooltip title="This Template Group is not assigned to any Division">
                  <Warning
                    sx={{
                      color: primaryColors.brand[600],
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </Tooltip>
              )}
              <DeleteTemplateGroup templateGroupToDelete={templateGroupItem} />
              <Tooltip title="Edit Template Group's Name">
                <IconButton
                  sx={{ padding: "10px", borderRadius: "16px" }}
                  aria-label="delete"
                  disabled={!canManageWorkspace}
                >
                  <EditIcon
                    sx={{
                      color: canManageWorkspace
                        ? primaryColors.base.black
                        : primaryColors.gray[400],
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <AssignTemplateGroupToDivisionsDialog
                templateGroupID={templateGroupItem.id}
                assignedDivisionsProp={templateGroupItem.divisions}
                disabled={!canManageWorkspace}
              />
            </Box>
          );
        },
        size: 50,
      },
    ],
    [navigate, canManageWorkspace]
  );

  return (
    <TableContainer
      label={"Template Groups"}
      tableActionsNextToName={
        <Box display="flex" gap={2}>
          {/* <LoadingButton
            title={"Refresh"}
            disabled={!canManageWorkspace}
            loading={isFetchingTemplateGroups}
            onClick={function refetchTemplateGroupsHandler() {
              refetchTemplateGroups();
            }}
            startIcon={<Refresh />}
            sx={{
              borderRadius: "8px",
              padding: "10px 16px",
              opacity: !canManageWorkspace ? 0.6 : 1,
              backgroundColor: primaryColors.success[500],
              "&:hover": {
                backgroundColor: primaryColors.success[500],
              },
              textTransform: "none",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations.semiBold,
              color: primaryColors.base.white,
            }}
          >
            Reload
          </LoadingButton> */}

          <UpgradeToHigherPlanDialog
            triggerButton={
              <Button
                sx={{
                  bgcolor: secondaryColors.blue[50],
                  "&:hover": {
                    backgroundColor: secondaryColors.blue[100],
                  },
                  textTransform: "none",
                  borderRadius: "8px",
                  padding: "10px 16px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    color: secondaryColors.blue[800],
                    fontSize: fontSizeVariations["text-sm"],
                  }}
                  fontWeight="bold"
                >
                  Upgrade now!
                </Typography>
              </Button>
            }
          />

          <CreateTemplateGroupDialog disabled={!canManageWorkspace} />
        </Box>
      }
    >
      <OverallViewTable
        columns={templateGroupsTableColumns}
        data={templateGroupsServerResponse?.data || []}
        totalRecords={templateGroupsServerResponse?.data.count}
        isLoading={isLoadingTemplateGroups}
        isError={isErrorTemplateGroups}
        externalPaginationProps={{
          externalCurrentPage: apiListArgsState.page,
          updateExternalCurrentPage: updatePageHandler,
          isFetching: isFetchingTemplateGroups,
        }}
        renderRowDetails={({ row }) => {
          return (
            <EditTemplateGroupName
              templateGroupToEdit={row.original}
              muiTableRowObject={row}
            />
          );
        }}
      />
    </TableContainer>
  );
};
