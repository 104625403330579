import { Box, Button, Typography } from "@mui/material";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { useReactHookFormWithYup } from "../../../reusable/hooks";
import {
  FormDropdown,
  FormImageInput,
} from "../../../reusable/components/form-inputs/controlled";
import countryList from "react-select-country-list";
import { useChangeFacilityInfo } from "../../settings/api/profile/changeFacilityInfo";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { useContext } from "react";

export const LogoAndCountryForm = () => {
  const { user, getUser } = useContext(AuthenticationContext);

  const {
    mutateAsync: changeFacilityInfoMutation,
    isLoading: isChangeFacilityInfoLoading,
  } = useChangeFacilityInfo();

  const validationSchema = yup.object({
    country: yup.string(),
  });
  const reactHookFormMethods = useReactHookFormWithYup(validationSchema, {
    defaultValues: {
      country: user.profile["country"],
      facility_logo: user.profile["facility_logo"],
    },
  });
  const { handleSubmit } = reactHookFormMethods;

  const setFacilityLogoAndCountry = async (facilityData) => {
    console.log("facility data", facilityData);

    if (!(facilityData.facility_logo instanceof File))
      delete facilityData.facility_logo;

    //TODO: uncomment after changing country from this endpoint is supported
    // changeFacilityInfoMutation(facilityData)
    //   .then(async () => {
    //     getUser();
    //   .catch((error) =>
    // console.log(error)
    //   );
  };
  return (
    <FormProvider {...reactHookFormMethods}>
      <form method="PATCH" onSubmit={handleSubmit(setFacilityLogoAndCountry)}>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            gap="22px"
          >
            <Box sx={{ width: { xs: "90%", md: "45%" } }}>
              <FormImageInput
                name="facility_logo"
                label="Upload your logo"
                helperText="upload image .jpg .png"
                disabled={user.profile["facility_logo"] !== undefined}
                showDeleteButton={true}
              />
            </Box>
            {/* <Box
              sx={{ width: { xs: "90%", md: "45%" } }}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <FormDropdown
                label="Select a country"
                name="country"
                sx={{
                  width: "230px",
                }}
                menuProps={{
                  PaperProps: {
                    sx: { maxHeight: 200, maxWidth: 200 },
                  },
                }}
                options={countryList().getData()}
              />
              <Typography
                variant="text-md"
                fontFamily="Inter"
                fontWeight={fontWeightVariations.regular}
                color={primaryColors.gray[500]}
                width="230px"
              >
                That’s will help us to validate the end-customer phone number
              </Typography>
            </Box> */}
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              type="submit"
              sx={{
                borderRadius: "8px",
                background: primaryColors.brand[500],
                "&:hover": {
                  bgcolor: primaryColors.brand[500],
                },
              }}
            >
              <Typography
                textTransform="capitalize"
                fontFamily="Inter"
                variant="text-sm"
                fontWeight={fontWeightVariations.medium}
                color={primaryColors.base.white}
              >
                Submit
              </Typography>
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
