import {
  Box,
  Button,
  Divider,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { CheckCircle, ContentCopy, Link } from "@mui/icons-material";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useGenerateLinkOrQRCode } from "../api/generateLinkOrQRCode";
import { LoadingButton } from "@mui/lab";
import { GeneralLinkQRCodeDialog } from "./GeneralLinkQRCodeDialog";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../contexts/NotificationContext";

export const GenerateGeneralLinkBox = ({
  disabled,
  selectedBranchData,
  selectedDivisionData,
  selectedTemplateData,
}) => {
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const [generalLink, setGeneralLink] = useState("");

  const generateLinkOrQRCodeMutation = useGenerateLinkOrQRCode();

  const { fireNotification, closeNotification, notificationState } =
    useContext(NotificationContext);

  useEffect(
    function resetLinkIsCopiedStateUponChangeInTemplate() {
      setLinkIsCopied(false);
      setGeneralLink("");
    },
    [selectedTemplateData]
  );

  useEffect(
    function switchBackToCopyButtonAfter12Seconds() {
      if (linkIsCopied) {
        setTimeout(function switchToCopyAfter12Seconds() {
          setLinkIsCopied(false);
          setGeneralLink("");
        }, 12000);
      }
    },
    [linkIsCopied]
  );

  useEffect(
    function closeNotificationErrorWhenTemplateIsSelected() {
      if (
        selectedTemplateData !== undefined &&
        notificationState.isOpen &&
        notificationState.type === NOTIFICATIONS_TYPE.error
      ) {
        closeNotification();
      }
    },
    [
      closeNotification,
      notificationState.isOpen,
      notificationState.type,
      selectedTemplateData,
    ]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Paper
        sx={{
          px: "8px",
          height: "44px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "570px",
        }}
      >
        {generalLink !== "" ? (
          <Typography
            variant="text-sm"
            color={primaryColors.gray[500]}
            sx={{ textTransform: "capitalize" }}
          >
            {generalLink}
          </Typography>
        ) : (
          <Typography
            variant="text-sm"
            color={primaryColors.gray[500]}
            sx={{ textTransform: "capitalize" }}
          >
            Generate a link by clicking on copy
          </Typography>
        )}
        <Box display="flex" height="inherit" alignItems="center">
          <GeneralLinkQRCodeDialog
            disabled={disabled}
            selectedBranchData={selectedBranchData}
            selectedDivisionData={selectedDivisionData}
            selectedTemplateData={selectedTemplateData}
          />

          <Divider sx={{ m: 0.5 }} orientation="vertical" />

          <LoadingButton
            variant="text"
            loading={generateLinkOrQRCodeMutation.isLoading}
            sx={{
              display: "flex",
              gap: 1,
              "&:hover": { bgcolor: "transparent" },
            }}
            disabled={linkIsCopied}
            disableRipple
            onClick={function () {
              if (disabled) {
                fireNotification({
                  title:
                    "Please, Select first a Branch, Division, and Template",
                  type: NOTIFICATIONS_TYPE.error,
                  verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                  horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                  width: NOTIFICATION_WIDTH.small,
                  target: NOTIFICATIONS_TARGET.admin,
                  closeAfter: 60000,
                });
              }
              if (!linkIsCopied) {
                generateLinkOrQRCodeMutation
                  .mutateAsync({
                    branchID: selectedBranchData.id,
                    divisionID: selectedDivisionData.id,
                    templateID: selectedTemplateData.id,
                    type: "both",
                  })
                  .then(function (response) {
                    setGeneralLink(response.data.link);

                    navigator.clipboard.writeText(response.data.link);
                    setLinkIsCopied(true);
                  });
              }
            }}
            title="Click to Generate and Copy a General Link"
          >
            {linkIsCopied ? (
              <CheckCircle
                sx={{ color: primaryColors.success[500], width: "20px" }}
              />
            ) : (
              <ContentCopy
                sx={{ color: primaryColors.gray[700], width: "20px" }}
              />
            )}

            {linkIsCopied ? (
              <Typography
                variant="text-sm"
                color={primaryColors.success[500]}
                sx={{ textTransform: "capitalize" }}
              >
                Copied
              </Typography>
            ) : (
              <Typography
                variant="text-sm"
                color={primaryColors.gray[700]}
                sx={{ textTransform: "capitalize" }}
              >
                Copy
              </Typography>
            )}
          </LoadingButton>
        </Box>
      </Paper>
    </Box>
  );
};
