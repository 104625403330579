import { WbIncandescent } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { secondaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";

export const InsightComponentUI = ({ title, children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      borderRadius="8px"
      border={`1px solid ${secondaryColors.blueSecondary[300]}`}
      bgcolor={secondaryColors.blueSecondary[100]}
      padding="16px"
      width="95%"
    >
      <Box display="flex" flexDirection="row" gap="10px">
        <WbIncandescent
          sx={{
            width: "20px",
            height: "20px",
            color: secondaryColors.blueSecondary[700],
          }}
        />
        <Typography
          variant="text-md"
          fontFamily="Nunito"
          fontWeight={fontWeightVariations.bold}
          color={secondaryColors.blueSecondary[700]}
        >
          {title}
        </Typography>
      </Box>

      {children}
    </Box>
  );
};
