import { OverallViewTable } from "../../../revamped-overall-view/components/shared/OverallViewTable";
import { AllMembersTableRowDetails } from "./AllMembersTableRowDetails";
import { AllMembersTableEditDetails } from "./AllMembersTableEditDetails";

import { DeleteMember } from "./DeleteMember";
import EditIcon from "@mui/icons-material/Edit";
import { NoMembersPage } from "./NoMembersPage";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import {
  defaultMembersListParams,
  useGetMembers,
  useGetUserRoles,
} from "../../api/members";
import { Box, IconButton, Tooltip } from "@mui/material";
import LoadingPage from "../../../../layout/LoadingPage";
import { primaryColors } from "../../../../helpers/customColors";
import { useState } from "react";
import { EmptyTableView } from "../../reusable/EmptyTableView";
import { CreateBranchDialog } from "../branches-divisions/CreateBranchDialog";
import { InvitationDialogButton } from "./InvitationDialogButton";
import { TableContainer } from "../../../../reusable/components/TableContainer";

export const AllMembersTable = () => {
  const [rowPanelDetailsType, setRowPanelDetailsType] = useState(
    rowPanelDetailsTypes.default
  );

  const { apiListArgsState, updatePageHandler } = useListAPIsStateManagement({
    initialState: {
      ...defaultMembersListParams,
    },
  });
  const {
    data: membersResponse,
    isFetching: isFetchingMembers,
    isError: membersFetchingError,
    isLoading: isLoadingMembers,
  } = useGetMembers({ params: { type: "users", page: apiListArgsState.page } });
  const { data: userRolesResponse, isLoading: isLoadingUserRoles } =
    useGetUserRoles();

  function renderDynamicRowPanelDetails({ rowPanelDetailsTypeArg, row }) {
    switch (rowPanelDetailsTypeArg) {
      case rowPanelDetailsTypes.default:
        return <AllMembersTableRowDetails memberDetails={row.original} />;
      case rowPanelDetailsTypes.edit:
        return <AllMembersTableEditDetails memberDetails={row.original} />;
      default: {
        return null;
      }
    }
  }

  const membersTableColumns = [
    {
      header: "Email",
      id: "email",
      accessorKey: "email",
      accessorFn: function renderUserEmail(memberData) {
        if (memberData.email.length > 0) return memberData.email;
        else return "N/A";
      },
      minSize: 50,
      size: 300,
    },
    {
      header: "Username",
      accessorKey: "username",
      minSize: 50,
      size: 300,
    },
    {
      header: "Role",
      id: "role",
      accessorFn: function renderUserRole(memberData) {
        for (let userRole of userRolesResponse.data) {
          if (userRole.value === memberData.role) return userRole.label;
        }
      },
      minSize: 50,
      size: 300,
    },
    {
      header: "",
      id: "actions",
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      accessorFn: function renderMemberActions(memberData) {
        return (
          <Box>
            <Tooltip title="Edit Member">
              <IconButton
                sx={{ padding: "10px", borderRadius: "16px" }}
                aria-label="edit"
                onClick={() =>
                  setRowPanelDetailsType(rowPanelDetailsTypes.edit)
                }
              >
                <EditIcon
                  sx={{
                    color: primaryColors.base.black,
                    height: "20px",
                    width: "20px",
                  }}
                />
              </IconButton>
            </Tooltip>
            <DeleteMember memberData={memberData} />
          </Box>
        );
      },
      minSize: 50,
      size: 350,
    },
  ];

  const allMembersTableView = (
    <OverallViewTable
      columns={membersTableColumns}
      data={membersResponse?.data.results || []}
      totalRecords={membersResponse?.data.count}
      isLoading={isLoadingMembers || isLoadingUserRoles}
      isError={membersFetchingError}
      externalPaginationProps={{
        externalCurrentPage: apiListArgsState.page,
        updateExternalCurrentPage: updatePageHandler,
        isFetching: isFetchingMembers,
      }}
      uniqueColumnName="username"
      resetRowPanelDetailsTypeCallback={function setRowPanelDetailsTypeToDefaultOnClose() {
        setRowPanelDetailsType(rowPanelDetailsTypes.default);
      }}
      renderRowDetails={({ row }) => {
        return renderDynamicRowPanelDetails({
          rowPanelDetailsTypeArg: rowPanelDetailsType,
          row,
        });
      }}
    />
  );

  const noMembersView = (
    <EmptyTableView
      title="Start by adding new members"
      description="Get your team on board and let the journey begin! There are various
      roles available, so you can assign each member to a specific branch
      and division."
      actionButton={<InvitationDialogButton />}
    />
  );

  let membersTableView = null;

  if (
    isLoadingMembers ||
    isLoadingUserRoles ||
    membersResponse?.data.results.length > 0
  ) {
    membersTableView = allMembersTableView;
  }

  if (
    !isLoadingMembers &&
    !isLoadingUserRoles &&
    membersResponse?.data.results.length === 0
  ) {
    membersTableView = noMembersView;
  }

  return (
    <TableContainer
      label={"Team"}
      tableActionsNextToName={
        <Box display="flex" flexDirection={"row"} alignItems="center">
          <InvitationDialogButton
            disabled={
              membersResponse?.data.count >=
              membersResponse?.data.users_max_count
            }
          />
        </Box>
      }
    >
      {membersTableView}
    </TableContainer>
  );
};
const rowPanelDetailsTypes = {
  default: "default",
  edit: "edit",
};
