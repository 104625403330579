import { Button, FormControl, OutlinedInput, Typography } from "@mui/material";
import { GenericDialog } from "../../../../reusable/components/GenericDialog";
import { primaryColors } from "../../../../helpers/customColors";
import { useState } from "react";
import { useUpdateAssignedDivisionsToTemplateGroup } from "../../api/updateAssignedDivisionsToTemplateGroup";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useCreateNewTemplateGroup } from "../../api/createNewTemplateGroup";

export const CreateTemplateGroupDialog = ({ disabled }) => {
  const [newTemplateGroupName, setNewTemplateGroupName] = useState("");

  const createNewTemplateGroupMutation = useCreateNewTemplateGroup();

  return (
    <GenericDialog
      dialogTitle={`Create new Template Group`}
      dialogSubtitle={`Give it a name`}
      maxWidth="sm"
      preventOpening={disabled}
      submitButtonText="Create"
      closeButtonText="Cancel"
      isSubmitting={createNewTemplateGroupMutation.isLoading}
      isDone={createNewTemplateGroupMutation.isSuccess}
      onSubmitCallback={function updateAssignedDivisionsHandler() {
        createNewTemplateGroupMutation.mutateAsync({
          newTemplateGroupName,
        });
      }}
      onCloseCallback={function clearNewTemplateStateOnCloseDialogHandler() {
        setNewTemplateGroupName("");
        createNewTemplateGroupMutation.reset();
      }}
      triggerButton={
        <Button
          title={"Add new Template Group"}
          disabled={disabled}
          sx={{
            borderRadius: "8px",
            padding: "10px 16px",
            opacity: disabled ? 0.6 : 1,
            backgroundColor: primaryColors.brand[500],
            "&:hover": {
              backgroundColor: primaryColors.brand[500],
            },
          }}
          startIcon={<AddIcon sx={{ color: primaryColors.base.white }} />}
        >
          <Typography
            sx={{
              textTransform: "none",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations.semiBold,
              color: primaryColors.base.white,
            }}
          >
            Add new Template Group
          </Typography>
        </Button>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="start" gap={1}>
        <Typography>Template Name</Typography>
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <OutlinedInput
            value={newTemplateGroupName}
            onChange={function updateNewTemplateGroupNameHandler(event) {
              setNewTemplateGroupName(event.target.value);
            }}
          />
        </FormControl>
      </Box>
    </GenericDialog>
  );
};
