import { createTheme } from "@mui/material";
import { COLORS } from "./Colors";
import { primaryColors } from "./customColors";
import { fontSizeVariations, fontWeightVariations } from "./customFont";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: primaryColors.brand[500],
    },
    secondary: {
      main: primaryColors.brand[700],
    },
    error: {
      main: primaryColors.error[500],
    },
    warning: {
      main: primaryColors.warning[500],
    },
    success: {
      main: primaryColors.success[500],
    },
  },
  typography: {
    fontFamily: "Nunito",
    "display-2xl": fontSizeVariations["display-2xl"],
    "display-xl": fontSizeVariations["display-xl"],
    "display-lg": fontSizeVariations["display-lg"],
    "display-md": fontSizeVariations["display-md"],
    "display-sm": fontSizeVariations["display-sm"],
    "display-xs": fontSizeVariations["display-xs"],
    "text-xl": fontSizeVariations["text-xl"],
    "text-lg": fontSizeVariations["text-lg"],
    "text-md": fontSizeVariations["text-md"],
    "text-sm": fontSizeVariations["text-sm"],
    "text-xs": fontSizeVariations["text-xs"],
    fontWeightLight: fontWeightVariations["light-300"],
    fontWeightRegular: fontWeightVariations["regular-400"],
    fontWeightMedium: fontWeightVariations["medium-500"],
    fontWeightBold: fontWeightVariations["bold-700"],
  },
});
