import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

const getTemplateGroupDetails = ({ templateGroupID }) => {
  const requestURL = `${API_URL}/template-group/${templateGroupID}`;

  return melior.get(requestURL);
};

export const useGetTemplateGroupDetails = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["template-group-details", params],
    queryFn: () => getTemplateGroupDetails(params),
    keepPreviousData: true,
  });
};
