import { useState, useRef, useContext } from "react";
import clsx from "clsx";
import jwt from "jwt-decode";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../helpers/Colors";
import OnlineSurveyGreetingsCard from "./OnlineSurveyGreetingsCard";
import { setCookie } from "../../helpers/cookies";
import OnlineSurveyQuestionCard from "./OnlineSurveyQuestionCard";
import OnlineSurveyIssuePickerCard from "./OnlineSurveyIssuePickerCard";
import { AuthenticationContext } from "../login";
import { NotFoundPage } from "../../reusable/components/NotFoundPage";
import {
  getTemplateQuestionsForOnlineSurvey,
  submitOnlineSurvey,
  submitSatisfactionSurvey,
  validateOnlineSurveyToken,
  validateSatisfactionSurveyToken,
} from "./api";
import { SurveyHeader } from "./components/SurveyHeader";
import { ANSWER_LAYOUT_TYPES } from "./helper-functions/AnswerLayoutPicker";
import { useEffect } from "react";
import { OnlineSurveyInputCard } from "./OnlineSurveyInputCard";

const useStyles = makeStyles(() => ({
  body: {
    minHeight: "100vh",
    background: COLORS.black,
    overflow: "hidden",
  },
  card: {
    // borderRadius: "20px",
    boxShadow: "5px 5px 10px 0px rgb(200, 200, 200, 0.3)",
    maxWidth: "500px",
    background: "white",
    height: "100%",
    width: "400px",
    // height: "100%",
    overflowY: "auto",
  },
  moveIn: {
    animation: `$slideLeftToCenter 350ms cubic-bezier(0.4, 0, 1, 1)`,
  },
  moveOut: {
    animation: `$slideCenterToRight 350ms cubic-bezier(0.4, 0, 1, 1)`,
  },
  moveBack: {
    animation: `$slideCenterToLeft 350ms cubic-bezier(0.4, 0, 1, 1)`,
  },

  "@keyframes slideLeftToCenter": {
    from: {
      transform: "translate3d(-100vw, 0, 0);",
    },
    to: { transform: "translate3d(0, 0, 0);" },
  },

  "@keyframes slideCenterToLeft": {
    from: {
      transform: "translate3d(0, 0, 0);",
    },
    to: { transform: "translate3d(-100vw, 0, 0);" },
  },
  "@keyframes slideCenterToRight": {
    from: {
      transform: "translate3d(0, 0, 0);",
    },
    to: { transform: "translate3d(100vw, 0, 0);" },
  },
}));

export const OnlineSurveyController = ({ nonce, visited = false }) => {
  const classes = useStyles();
  const [surveyType, setSurveyType] = useState("");
  const [status, setStatus] = useState({
    welcome: true,
    // Complaints only
    issuePicker: false,
    // Complaints only
    inSurvey: false,
    comments: false,
    thanks: false,
  });
  const [currPage, setCurrPage] = useState(0);
  const card = useRef(0);
  const [surveyInfo, setSurveyInfo] = useState({
    choice_answers: [],
    input_answers: [],
    voice_answers: [],
  });
  const [templateId, setTemplateId] = useState(null);
  const [contactInfo, setContactInfo] = useState({});
  const [reviewDynamicText, setReviewDynamicText] = useState();
  const [complaintDynamicText, setComplaintDynamicText] = useState();
  const [disclaimer, setDisclaimer] = useState();
  const [Questions, setQuestions] = useState([]);
  const [Issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [error, setError] = useState({ isError: false, errorMessage: "" });
  const [submitting, setSubmitting] = useState(false);
  const [landingPageWelcome, setLandingPageWelcome] = useState({});
  const [language, setLanguage] = useState(0);
  const [facilityData, setFacilityData] = useState({
    name: "",
    image: "",
  });
  const [verified, setVerified] = useState();

  const { user } = useContext(AuthenticationContext);

  const isOts = () => {
    const pathname = window.location.pathname;
    const token = pathname.split("/")[2];
    return jwt(token).token_type === "ots";
  };

  const submitOnlineSurveyData = (answer) => {
    const pathname = window.location.pathname;
    setSubmitting(true);

    surveyInfo.answers = surveyInfo.choice_answers;
    delete surveyInfo.choice_answers;

    const data =
      surveyType === ONLINE_SURVEY_TYPES.review
        ? {
            ...surveyInfo,
            ...answer,
            submitted_at: new Date(),
            template: templateId,
            id: jwt(pathname.split("/")[2]).id,
            nonce,
          }
        : {
            ...surveyInfo,
            ...answer,
            submitted_at: new Date(),
            id: jwt(pathname.split("/")[2]).id,
            channel: "External Survey",
            template: templateId,
            issue: selectedIssue,
            nonce,
          };

    submitOnlineSurvey({
      surveyType: surveyType,
      surveyData: {
        ...data,
        // Filter out "Skipped questions"
        answers: data.answers?.filter((answer) => answer.choice !== undefined),
      },
    })
      .then(() => {
        goToCategory({ nextCategory: "thanks" });
        setSubmitting(false);
        const info = jwt(window.location.pathname.split("/")[2]);
        if (info.token_type === "link") {
          setCookie(info.jti, true, 30);
        } else if (info.token_type === "qr_code") {
          setCookie(info.jti, true);
        }
      })
      .catch(() => {
        setSubmitting(false);
        setError({
          isError: true,
          errorMessage: `We couldn't receive your ${surveyType}, please try again later`,
        });
      });
  };

  const submitSatisfactionSurveyData = ({ question, choice }) => {
    setSubmitting(true);
    const data = {
      ...surveyInfo,
      question,
      choice,
      submitted_at: new Date(),
    };
    submitSatisfactionSurvey(data)
      .then(() => {
        goToCategory({ nextCategory: "thanks" });
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
        setError({
          isError: true,
          errorMessage: `We couldn't receive your , please try again later`,
        });
      });
  };

  const fillQuestions = () => {
    getTemplateQuestionsForOnlineSurvey(templateId)
      .then((response) => {
        let new_format = {};
        response.forEach((q) => (new_format[q.lang] = q.questions));
        setQuestions(new_format);
        setSurveyType(ONLINE_SURVEY_TYPES.review);
        goToCategory({ nextCategory: "inSurvey" });
      })
      .catch((error) => {
        setError({
          isError: true,
          errorMessage: language
            ? "لا يمكننا بدأ الاستطلاع، حاول فى وقت لاحق"
            : "We couldn't start you review, please try again later ",
        });
      });
  };
  const startComplaint = () => {
    setSurveyType(ONLINE_SURVEY_TYPES.complaint);
    if (Issues.en.length > 0) {
      goToCategory({ nextCategory: "issuePicker" });
    } else {
      goToCategory({ nextCategory: "comments" });
    }
  };
  const moveCardOut = () => {
    card.current.className = clsx(classes.card, classes.moveOut);
  };
  const moveCardIn = () => {
    card.current.className = clsx(classes.card, classes.moveIn);
  };
  const moveCardBack = () => {
    card.current.className = clsx(classes.card, classes.moveBack);
  };

  const addAnswerToSurveyInfo = ({ answerInfo, questionType }) => {
    if (answerInfo) {
      switch (questionType) {
        case ANSWER_LAYOUT_TYPES.multiple_choices:
        case ANSWER_LAYOUT_TYPES.nps: {
          let newAnswers = [...surveyInfo.choice_answers];
          let previousAnswerIndex = newAnswers.findIndex(
            (currAnswer) => currAnswer.question === answerInfo.question
          );
          if (previousAnswerIndex === -1)
            newAnswers.push({
              choice: answerInfo.answer,
              question: answerInfo.question,
            });
          else newAnswers[previousAnswerIndex].choice = answerInfo.answer;

          setSurveyInfo({ ...surveyInfo, choice_answers: newAnswers });
          break;
        }
        case ANSWER_LAYOUT_TYPES.voice_note: {
          let newAnswers = [...surveyInfo.voice_answers];
          let previousAnswerIndex = newAnswers.findIndex(
            (currAnswer) => currAnswer.question === answerInfo.question
          );
          if (previousAnswerIndex === -1)
            newAnswers.push({
              voice: answerInfo.answer,
              question: answerInfo.question,
            });
          else newAnswers[previousAnswerIndex].voice = answerInfo.answer;

          setSurveyInfo({ ...surveyInfo, voice_answers: newAnswers });
          break;
        }
        case ANSWER_LAYOUT_TYPES.long_text:
        case ANSWER_LAYOUT_TYPES.short_text:
        case ANSWER_LAYOUT_TYPES.numbers:
        case ANSWER_LAYOUT_TYPES.phone_number: {
          let newAnswers = [...surveyInfo.input_answers];
          let previousAnswerIndex = newAnswers.findIndex(
            (currAnswer) => currAnswer.question === answerInfo.question
          );
          if (previousAnswerIndex === -1)
            newAnswers.push({
              input: answerInfo.answer,
              question: answerInfo.question,
            });
          else newAnswers[previousAnswerIndex].input = answerInfo.answer;
          setSurveyInfo({ ...surveyInfo, input_answers: newAnswers });
          break;
        }
        default:
          break;
      }
    }
  };

  const nextPage = ({ answerInfo, questionType }) => {
    if (answerInfo) {
      addAnswerToSurveyInfo({ answerInfo, questionType });
    }
    moveCardOut();
    setTimeout(() => {
      setCurrPage(currPage + 1);
    }, 300);
    setTimeout(() => {
      moveCardIn();
    }, 340);
  };

  const prevPage = () => {
    moveCardBack();
    setTimeout(() => {
      setCurrPage(currPage - 1);
    }, 300);
    setTimeout(() => {
      moveCardIn();
    }, 340);
  };

  const goToCategory = ({ nextCategory, answerInfo, questionType }) => {
    if (answerInfo) {
      addAnswerToSurveyInfo({ answerInfo, questionType });
    }
    moveCardOut();
    setTimeout(() => {
      setStatus({
        welcome: false,
        issuePicker: false,
        inSurvey: false,
        comments: false,
        thanks: false,
        [nextCategory]: true,
      });
      moveCardIn();
    }, 300);
  };

  const goBackToCategory = (prevCategory) => {
    moveCardBack();
    setTimeout(() => {
      setStatus({
        welcome: false,
        issuePicker: false,
        inSurvey: false,
        comments: false,
        thanks: false,
        [prevCategory]: true,
      });
      moveCardIn();
    }, 300);
  };

  function getSatisfactionSurveyData(token) {
    validateSatisfactionSurveyToken(token)
      .then((response) => {
        setFacilityData({
          name: response["facility_name"],
          image: response["facility_logo"],
        });
        let new_format = {};
        response["question"].forEach(
          (q) => (new_format[q.lang] = [q.question])
        );
        setQuestions(new_format);
        setVerified(true);
        goToCategory({ nextCategory: "inSurvey" });
      })
      .catch((e) => {
        setVerified(false);
        if (e.response.status === 401) {
          setError({
            isError: true,
            errorMessage: "This link is expired or invalid",
          });
        } else {
          setError({
            isError: true,
            errorMessage:
              "We couldn't verify your token, please try again later",
          });
        }
      });
  }
  function getOnlineSurveyData(token) {
    validateOnlineSurveyToken(token)
      .then((response) => {
        setTemplateId(response?.template);
        setContactInfo(
          response["additional_settlings"] &&
            response["additional_settlings"]["contact_fields"]
        );
        setLandingPageWelcome(
          response["additional_settlings"] &&
            response["additional_settlings"]["feedback_landing_page"]
        );
        setReviewDynamicText(response["additional_settlings"]["review"]);
        setDisclaimer(response["additional_settlings"]["disclaimer"]);
        setComplaintDynamicText(response["additional_settlings"]["complaint"]);
        setFacilityData({
          name: response["facility_name"],
          image: response["facility_logo"],
          country: response["country"],
        });
        setSurveyInfo({
          ...surveyInfo,
          id: jwt(token).id,
        });
        if (response.issues.available) {
          let new_format = {};
          response.issues.data.forEach((q) => (new_format[q.lang] = q.issues));
          setIssues(new_format);
        }
        setVerified(true);
      })
      .catch((e) => {
        setVerified(false);
        if (e.response.status === 401) {
          setError({
            isError: true,
            errorMessage: "This link is expired or invalid",
          });
        } else {
          setError({
            isError: true,
            errorMessage:
              "We couldn't verify your token, please try again later",
          });
        }
      });
  }

  useEffect(function validateSurveyToken() {
    if (!user) {
      const pathname = window.location.pathname;
      const token = pathname.split("/")[2];
      const decodedToken = jwt(token);
      setSurveyType(decodedToken["type"]);

      if (decodedToken["type"] === ONLINE_SURVEY_TYPES.satisfactionSurvey) {
        getSatisfactionSurveyData(token);
      } else {
        getOnlineSurveyData(token);
        goToCategory({ nextCategory: "welcome" });
      }
    }
  }, []);

  if (visited) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.body}
      >
        <Grid
          container
          justifyContent="center"
          item
          xs={11}
          sm={6}
          md={5}
          lg={3}
          xl={3}
          height="100vh"
        >
          <div className={clsx(classes.card, classes.moveIn)} ref={card}>
            <OnlineSurveyGreetingsCard
              language={language}
              facilityData={facilityData}
              type="thanks"
              visited={true}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  if (user) {
    return <NotFoundPage onlineSurvey="1"></NotFoundPage>;
  }
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.body}
    >
      <Grid
        container
        justifyContent="center"
        item
        xs={11}
        sm={6}
        md={5}
        lg={3}
        xl={3}
        height="100vh"
      >
        <div className={clsx(classes.card, classes.moveIn)} ref={card}>
          <SurveyHeader language={language} setLanguage={setLanguage} />
          {/* Welcome Card */}
          {!status.welcome ? null : (
            <OnlineSurveyGreetingsCard
              templateId={templateId}
              contactInfo={contactInfo}
              complaintDynamicText={complaintDynamicText}
              reviewDynamicText={reviewDynamicText}
              disclaimer={disclaimer}
              facilityData={facilityData}
              error={error}
              type="welcome"
              surveyType={surveyType}
              language={language}
              landingPageWelcome={landingPageWelcome}
              verified={verified}
              start={(type) => {
                if (type === ONLINE_SURVEY_TYPES.review) {
                  fillQuestions();
                } else {
                  startComplaint();
                }
              }}
            />
          )}
          {/* Complaints Only */}
          {status.issuePicker === false ? null : (
            <OnlineSurveyIssuePickerCard
              error={error}
              language={language}
              facilityData={facilityData}
              issues={Issues}
              dynamicText={complaintDynamicText}
              nextPage={(issueId) => {
                setSelectedIssue(issueId);
                goToCategory({ nextCategory: "comments" });
              }}
              prevPage={() => goBackToCategory("welcome")}
            />
          )}
          {/* Reviews Only */}
          {/* Questions */}
          {!status.inSurvey ? null : (
            <OnlineSurveyQuestionCard
              language={language}
              facilityData={facilityData}
              nextPage={function renderNextCard({ answerInfo, questionType }) {
                if (surveyType === ONLINE_SURVEY_TYPES.review) {
                  currPage < Questions.en.length - 1
                    ? nextPage({ answerInfo, questionType })
                    : goToCategory({
                        nextCategory: "comments",
                        answerInfo,
                        questionType,
                      });
                } else if (
                  surveyType === ONLINE_SURVEY_TYPES.satisfactionSurvey
                ) {
                  submitSatisfactionSurveyData({
                    question: answerInfo.question,
                    choice: answerInfo.answer,
                  });
                }
              }}
              prevPage={() =>
                currPage > 0 ? prevPage() : goBackToCategory("welcome")
              }
              currPage={currPage}
              totalPages={language ? Questions.ar.length : Questions.en.length}
              data={language ? Questions.ar[currPage] : Questions.en[currPage]}
            />
          )}
          {/* Comment */}
          {!status.comments ? null : (
            <OnlineSurveyInputCard
              language={language}
              isOts={isOts()}
              error={error}
              submitting={submitting}
              dynamicText={
                surveyType === ONLINE_SURVEY_TYPES.complaint
                  ? complaintDynamicText
                  : reviewDynamicText
              }
              prevPage={() => goBackToCategory("inSurvey")}
              fields={contactInfo && contactInfo[surveyType.toLowerCase()]}
              nextPage={(answer) => {
                submitOnlineSurveyData(answer);
              }}
              disclaimer={disclaimer}
              facilityCountry={facilityData?.country}
            />
          )}
          {/* {!status.comments ? null : type === "Complaint" ? (
          {!status.comments ? null : surveyType ===
            ONLINE_SURVEY_TYPES.complaint ? (
            <ComplaintOnlineSurveyInputCard
              language={language}
              //   changeLanguage={changeLanguage}
              isOts={isOts()}
              error={error}
              submitting={submitting}
              dynamicText={complaintDynamicText}
              prevPage={() => goBackToCategory("inSurvey")}
              fields={contactInfo && contactInfo[surveyType.toLowerCase()]}
              nextPage={(answer) => {
                submitOnlineSurveyData(answer);
              }}
              disclaimer={disclaimer}
            />
          ) : (
            <ReviewOnlineSurveyInputCard
              language={language}
              //   changeLanguage={changeLanguage}
              isOts={isOts()}
              disclaimer={disclaimer}
              error={error}
              submitting={submitting}
              dynamicText={reviewDynamicText}
              prevPage={() => goBackToCategory("inSurvey")}
              fields={contactInfo && contactInfo[surveyType.toLowerCase()]}
              nextPage={(answer) => {
                submitOnlineSurveyData(answer);
              }}
            />
          )}
          {/* Thanks */}
          {!status.thanks ? null : (
            <OnlineSurveyGreetingsCard
              language={language}
              facilityData={facilityData}
              type="thanks"
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export const ONLINE_SURVEY_TYPES = {
  review: "review",
  complaint: "complaint",
  satisfactionSurvey: "satisfaction_survey",
};
