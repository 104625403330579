import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { GenericDialog } from "../../../../reusable/components/GenericDialog";
import { primaryColors } from "../../../../helpers/customColors";
import { useGetAllBranchesWithoutPagination } from "../../../settings/api/branches-divisions/getAllBranchesWithoutPagination";
import { useContext, useEffect, useState } from "react";
import { useUpdateAssignedDivisionsToTemplateGroup } from "../../api/updateAssignedDivisionsToTemplateGroup";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../../contexts/NotificationContext";
import { ArrowForward } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export const AssignTemplateGroupToDivisionsDialog = ({
  templateGroupID,
  assignedDivisionsProp,
  disabled,
}) => {
  const [latestAssignedDivisionsIDs, setLatestAssignedDivisionsIDs] = useState(
    []
  );
  const [accountHasAtLeastOneDivision, setAccountHasAtLeastOneDivision] =
    useState(false);
  const [
    branchesAndDivisionsFilterOptions,
    setBranchesAndDivisionsFilterOptions,
  ] = useState();
  const { fireNotification } = useContext(NotificationContext);

  const { data: allBranchesResponse, isLoading: isLoadingAllBranches, isFetching: isFetchingBranchesData } =
    useGetAllBranchesWithoutPagination();

  const updateAssignedDivisionsMutation =
    useUpdateAssignedDivisionsToTemplateGroup();

  function updateAssignedDivisionsHandler(updatedAssignedDivisionsEvent) {
    setLatestAssignedDivisionsIDs([
      ...updatedAssignedDivisionsEvent.target.value,
    ]);
  }

  useEffect(
    function prepAndSetBranchesAndDivisionsFilterOptions() {
      if (allBranchesResponse && !isLoadingAllBranches) {
        const tempBranchesAndDivisionsFilterOptions = [];

        allBranchesResponse.data.forEach(function getBranchesAsFilterOptions(
          branchData
        ) {
          tempBranchesAndDivisionsFilterOptions.push({
            id: branchData.id,
            isBranch: true,
            name: branchData.name,
          });

          if (branchData.divisions.length > 0) {
            setAccountHasAtLeastOneDivision(true);
            branchData.divisions.forEach(function getDivisionsAsFilterOptions(
              divisionData
            ) {
              tempBranchesAndDivisionsFilterOptions.push({
                id: divisionData.id,
                name: divisionData.name,
              });
            });
          }
        });

        setBranchesAndDivisionsFilterOptions([
          ...tempBranchesAndDivisionsFilterOptions,
        ]);
      }
    },
    [allBranchesResponse, isLoadingAllBranches]
  );

  return (
    <GenericDialog
      dialogTitle={`Assignation`}
      dialogSubtitle={`Now you have the ability to assign the template to your branches and divisions.`}
      maxWidth="sm"
      preventOpening={disabled || !accountHasAtLeastOneDivision}
      submitButtonText="Update Assigned Divisions"
      closeButtonText="Cancel"
      onOpenCallback={function initializeLatestAssignedDivisionsIDsWithProps() {
        if (accountHasAtLeastOneDivision) {
          // Normally prepare for assignation
          setLatestAssignedDivisionsIDs([
            ...assignedDivisionsProp.map(function returnDivisionID(
              divisionItem
            ) {
              return divisionItem.id;
            }),
          ]);
        } else {
          // Notify the use that he has to create some divisions first
          const goToBranchesPageLink = (
            <Box display="flex" gap={1} alignItems="center">
              <ArrowForward sx={{ color: primaryColors.warning[700] }} />
              <Link target="_blank" to="/settings/branches-and-divisions">
                <Typography
                  variant="text-md"
                  color={primaryColors.warning[700]}
                >
                  Go to Branches and Divisions Page
                </Typography>
              </Link>
            </Box>
          );

          fireNotification({
            title: "We couldn’t find any division",
            description:
              "To assign the template, go to the Branches and Divisions page and add at least one branch with one division",
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
            horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.center,
            type: NOTIFICATIONS_TYPE.info,
            extraDetailsToRender: goToBranchesPageLink,
            target: NOTIFICATIONS_TARGET.admin,
            width: NOTIFICATION_WIDTH.fullSize,
          });
        }
      }}
      isSubmitting={updateAssignedDivisionsMutation.isLoading}
      isDone={updateAssignedDivisionsMutation.isSuccess}
      onSubmitCallback={function updateAssignedDivisionsHandler() {
        updateAssignedDivisionsMutation.mutateAsync({
          templateGroupID,
          assignedDivisionsIDs: [...latestAssignedDivisionsIDs],
        });
      }}
      triggerButton={
        <Button
          variant="contained"
          disabled={disabled || isFetchingBranchesData}
          sx={{
            height: "36px",
            width: "100px",
          }}
          
        >
          <Typography
            variant="text-sm"
            sx={{
              color: primaryColors.base.white,
              textTransform: "none",
            }}
          >
            {assignedDivisionsProp.length > 0 ? "Re-assign" : "Assign"}
          </Typography>
        </Button>
      }
      stopPropagation
    >
      <FormControl
        sx={{ m: 1, minWidth: 200, width: "97%", position: "relative" }}
        focused={false}
      >
        <InputLabel
          sx={{
            position: "absolute",
            top: latestAssignedDivisionsIDs.length === 0 && "-9px",
            fontSize: "0.9rem",
          }}
          id="select-divisions"
        >
          Select Divisions
        </InputLabel>
        {branchesAndDivisionsFilterOptions && (
          <Select
            labelId="select-divisions"
            sx={{ height: 35.5 }}
            multiple
            input={<OutlinedInput label={"Select Divisions"} />}
            value={latestAssignedDivisionsIDs}
            renderValue={(selected) => {
              return (
                <Typography sx={{ fontSize: "0.9rem" }}>
                  {selected.length} Selected
                </Typography>
              );
            }}
            onChange={updateAssignedDivisionsHandler}
          >
            {branchesAndDivisionsFilterOptions.map(
              function renderDivisionsOptionsGroup(branchDivOption, index) {
                return (
                  <MenuItem
                    aria-invalid={branchDivOption.isBranch}
                    key={index}
                    value={branchDivOption.isBranch ? "" : branchDivOption.id}
                    sx={{
                      pointerEvents: branchDivOption.isBranch
                        ? "none"
                        : undefined,
                      paddingLeft: branchDivOption.isBranch ? undefined : 2,
                    }}
                  >
                    {!branchDivOption.isBranch && (
                      <Checkbox
                        size="small"
                        checked={latestAssignedDivisionsIDs?.includes(
                          branchDivOption.id
                        )}
                      />
                    )}
                    <ListItemText
                      primary={branchDivOption.name}
                      sx={{
                        fontSize: "14px",
                        fontWeight: branchDivOption.isBranch ? "bold" : "",
                      }}
                    />
                  </MenuItem>
                );
              }
            )}
          </Select>
        )}
      </FormControl>
    </GenericDialog>
  );
};
