import { Box, Typography } from "@mui/material";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";

export const DescriptionWithGuidance = ({
  textDescription,
  guidanceImage,
  children,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="18px">
      {textDescription && (
        <Typography
          color={primaryColors.gray[700]}
          variant="text-lg"
          fontFamily="Nunito"
          fontWeight={fontWeightVariations.medium}
        >
          {textDescription}
        </Typography>
      )}
      {guidanceImage && guidanceImage}
      {children && children}
    </Box>
  );
};
