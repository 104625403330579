import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

const getTemplateQuestions = ({ templateID }) => {
  const requestURL = `${API_URL}/templates/${templateID}/questions`;

  return melior.get(requestURL);
};

export const useGetTemplateQuestions = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["template-questions", params],
    queryFn: () => getTemplateQuestions(params),
    keepPreviousData: true,
  });
};
