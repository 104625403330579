import { GenericDialog } from "../../../../reusable/components/GenericDialog";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";
import { useUpgradePaymentPlanHook } from "../../../../reusable/hooks/useUpgradePaymentPlanHook";

export const UpgradeToHigherPlanDialog = ({ triggerButton }) => {
  const {
    isLoadingAllowedSubscriptionPlansResponse,
    allowedSubscriptionPlansResponse,
    onSubmitPlanUpgradeHandler,
    upgradeSubscriptionPlanMutation,
    planUpgradeOptions,
  } = useUpgradePaymentPlanHook();

  return (
    <IsLoadingContainer isLoading={isLoadingAllowedSubscriptionPlansResponse}>
      {allowedSubscriptionPlansResponse &&
        !isLoadingAllowedSubscriptionPlansResponse && (
          <GenericDialog
            dialogTitle="Upgrade Your Subscription"
            dialogSubtitle="Choose your Plan"
            triggerButton={triggerButton}
            onSubmitCallback={onSubmitPlanUpgradeHandler}
            isSubmitting={upgradeSubscriptionPlanMutation.isLoading}
            isDone={upgradeSubscriptionPlanMutation.isSuccess}
            submitButtonText="Confirm"
            closeButtonText="Cancel"
          >
            {planUpgradeOptions}
          </GenericDialog>
        )}
    </IsLoadingContainer>
  );
};
