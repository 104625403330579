import { API_URL, melior } from "../../../helpers/constants";

export const getOverallProgressData = async (overallProgressQueryStrings) => {
  const { date_from, date_to } = overallProgressQueryStrings;

  const requestURL = `${API_URL}/overall-progress`;

  return await melior
    .get(requestURL, {
      params: {
        ...overallProgressQueryStrings,
      },
    })
    .then(async (response) => {
      return response.data;
    });
};
