import { Box, Typography } from "@mui/material";
import { DescriptionWithGuidance } from "./DescriptionWithGuidance";
import { InsightComponentUI } from "./InsightComponentUI";
import { ReactComponent as InviteYourTeamGuidance } from "../../../assets/onboarding-guidances/invite-team-guidance.svg";
import { fontWeightVariations } from "../../../helpers/customFont";
import { secondaryColors } from "../../../helpers/customColors";

export const InviteYourTeamBody = () => {
  const rolesList = [
    {
      title: "Facility Manager",
      description:
        "This team member can see all branches and divisions in the facility and has access to all the features.",
    },
    {
      title: "App only user",
      description:
        "As a “App only user”, you can only login using the Melior app.",
    },
    {
      title: "Branch Manager",
      description:
        "Branch Managers can review only certain branches or multiple branches along with their divisions. They can also address and resolve complaints, as well as archive or mark reviews as important.",
    },
    {
      title: "Division Manager",
      description:
        "Division Manager who can review only certain divisions, or multiple divisions and can manage and resolve complaints. They also have the option to archive or star reviews.",
    },
    {
      title: "Feedback only",
      description:
        "“Feedback only” users can only input reviews or complaints and can track and preview their own reviews or complaints.",
    },
    {
      title: "Financial Manager",
      description:
        "The “Financial Manager” can check the upcoming invoice date and download the invoice as a PDF.",
    },
  ];
  return (
    <DescriptionWithGuidance
      textDescription="Depending on your plan, you can invite your team members and assign them specific roles, branches, and divisions. Just follow these steps"
      guidanceImage={<InviteYourTeamGuidance width="100%" />}
    >
      <InsightComponentUI title="Roles:">
        {rolesList.map(function renderrolesList(roleData, index) {
          return (
            <Box display="flex" flexDirection="column" gap="4px" key={index}>
              <Typography
                variant="text-sm"
                fontFamily="Nunito"
                fontWeight={fontWeightVariations.bold}
                color={secondaryColors.blueSecondary[700]}
              >
                {roleData.title}
              </Typography>
              <Typography
                variant="text-sm"
                fontFamily="Nunito"
                fontWeight={fontWeightVariations.medium}
                color={secondaryColors.blueSecondary[600]}
              >
                {roleData.description}
              </Typography>
            </Box>
          );
        })}
      </InsightComponentUI>
    </DescriptionWithGuidance>
  );
};
