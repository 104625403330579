import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
import { primaryColors } from "../../helpers/customColors";
import { ScreenContainer } from "../../layout/ScreenContainer";
import { useMediaQuery } from "react-responsive";

/**
 * @param {Object[]} tabsData Tabs and Their Content and routes
 * @param {string} tabsData[].label Tab Label
 * @param {import("react").ReactNode} tabsData[].permitted For Permission
 * @param {string} tabsData[].routeParam Tab Route Param
 * @param {import("react").ReactNode} tabsData[].tabContent Tab COntent
 * @param {string} rootRouteParam Page's root Route Param
 * @param {string} dynamicRouteParam Page's dynamic Route Param (from useParams())
 * @returns
 */
export const MeliorTabsRevamped = ({
  tabsData,
  rootRouteParam,
  dynamicRouteParam,
  customMessage,
  width,
}) => {
  const navigate = useNavigate();

  const tabRouteParamToTabIndex = {};
  const tabIndexToTabRouteParam = {};

  const permittedTabsData = tabsData.filter(function filterOutNonPermittedTabs(
    tabData
  ) {
    return tabData.permitted !== false;
  });

  // Mappers
  permittedTabsData.forEach((tabData, index) => {
    // Map RouteParam to its corresponding Tab Index
    tabRouteParamToTabIndex[tabData.routeParam] = index;
    // Map Tab Index to its corresponding Route Param
    tabIndexToTabRouteParam[index] = tabData.routeParam;
  });

  //Set the default Tab index from the one coming from the current Route Param.
  const [currentTabIndex, setCurrentTabIndex] = useState(
    tabRouteParamToTabIndex[dynamicRouteParam]
  );

  const handleTabChange = (event, newTabIndex) => {
    navigate(`/${rootRouteParam}/${tabIndexToTabRouteParam[newTabIndex]}`);
    setCurrentTabIndex(newTabIndex);
  };

  const tabStyles = {
    fontSize: fontSizeVariations["text-sm"],
    fontWeight: fontWeightVariations["semiBold-600"],
    textTransform: "capitalize",
    color: primaryColors.gray[500],
    "&.Mui-selected": {
      backgroundColor: primaryColors.brand[50],
    },
  };

  const tabs = (
    <Tabs
      value={currentTabIndex}
      onChange={handleTabChange}
      aria-label="icon position tabs example"
      textColor="secondary"
      indicatorColor="secondary"
      TabIndicatorProps={{
        style: {
          height: "2px",
        },
      }}
      variant="fullWidth"
    >
      {permittedTabsData.map((tabData, index) => {
        return (
          <Tab
            key={index}
            label={tabData.label}
            sx={{ ...tabStyles }}
            disableRipple
          />
        );
      })}
    </Tabs>
  );

  const tabsContent = permittedTabsData.filter(
    (tabData) => tabIndexToTabRouteParam[currentTabIndex] === tabData.routeParam
  )[0].tabContent;

  const isSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <Box display="flex" flexDirection="column">
      <Box width={isSmallScreen ? "auto" : width ? width : "35rem"}>
        <Box borderBottom={1} borderColor="divider">
          {tabs}
        </Box>
      </Box>
      <Box width="100%" mt="1rem" mb="3rem">
        {tabsContent}
      </Box>
    </Box>
  );
};
