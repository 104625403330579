import { createContext, useState } from "react";
import {
  getFacilityTemplates,
  getQuestions,
//   getTemplateQuestions,
} from "../api";

export const AdminContext = createContext();

const AdminContextProvider = (props) => {
  const [generalQuestions, setGeneralQuestions] = useState(null);
  const [facilityTemplates, setFacilityTemplates] = useState(null);

  const [templateQuestions, setTemplateQuestions] = useState(null);

  const [loadingGeneralQuestion, setLoadingGeneralQuestion] = useState(false);
  const [loadingFacilityTemplates, setLoadingFacilityTemplates] =
    useState(false);

  const [generalQuestionsError, setGeneralQuestionsError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [facilityTemplatesError, setFacilityTemplatesError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [templateQuestionsError, setTemplateQuestionsError] = useState({
    isError: false,
    errorMessage: "",
  });

  const getGeneralQuestions = (reload) => {
    if ((generalQuestions && reload === false) || loadingGeneralQuestion)
      return;
    setLoadingGeneralQuestion(true);
    getQuestions()
      .then((response) => {
        setGeneralQuestions(response[0]["questions"]);
        setGeneralQuestionsError({
          isError: false,
        });
        setLoadingGeneralQuestion(false);
      })
      .catch(() => {
        setGeneralQuestionsError({
          isError: true,
          errorMessage: "Network Error, please try again later",
        });
        setLoadingGeneralQuestion(false);
      });
  };

  const getFacilityTemplatesHandler = (reload) => {
    if ((facilityTemplates && reload === false) || loadingFacilityTemplates)
      return;
    setLoadingFacilityTemplates(true);
    getFacilityTemplates()
      .then((response) => {
        setFacilityTemplates(response);
        setFacilityTemplatesError({
          isError: false,
        });
        setLoadingFacilityTemplates(false);
      })
      .catch(() => {
        setFacilityTemplatesError({
          isError: true,
          errorMessage: "Network Error, please try again later",
        });
        setLoadingFacilityTemplates(false);
      });
  };

//   const getTemplateQuestionsHandler = (reload, id) => {
//     if (templateQuestions && templateQuestions[id] && reload === false) return;
//     getTemplateQuestions({ selectedTemplate: id })
//       .then((response) => {
//         setTemplateQuestions({
//           ...templateQuestions,
//           [id]: response[0].questions,
//         });
//         setTemplateQuestionsError({
//           isError: false,
//         });
//       })
//       .catch(() => {
//         setTemplateQuestionsError({
//           isError: true,
//           errorMessage:
//             "We couldn't retrieve template questions, please try again later",
//         });
//       });
//   };

  return (
    <AdminContext.Provider
      value={{
        getGeneralQuestions,
        generalQuestions,
        loadingGeneralQuestion,
        generalQuestionsError,

        getFacilityTemplatesHandler,
        facilityTemplates,
        loadingFacilityTemplates,
        facilityTemplatesError,

        // getTemplateQuestionsHandler,
        templateQuestions,
        templateQuestionsError,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export { AdminContextProvider };
