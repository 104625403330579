import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";
import { useState } from "react";
import { Delete } from "@mui/icons-material";

export const FormImageInput = ({
  name,
  label,
  helperText,
  disabled,
  showDeleteButton,
  width = undefined,
  resetImagePreview,
}) => {
  const { control, setValue, resetField } = useFormContext();
  const [imagePreview, setImagePreview] = useState();

  function formatFileName(name) {
    let nameLength = name?.length;
    return nameLength > 13
      ? `${name?.slice(0, 9)}.. ${name?.slice(nameLength - 4, nameLength)}`
      : name;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Grid
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="300px"
          gap={"8px"}
        >
          {/* Image Preview */}
          {value && (
            <Grid
              container
              justifyContent="flex-start"
              direction="column"
              alignItems="center"
            >
              <Box
                component="img"
                width="250px"
                maxWidth="100%"
                alt="Your facility logo"
                src={value instanceof File ? imagePreview : value}
              ></Box>
            </Grid>
          )}

          {/* Image Input */}
          <Grid item xs={12}>
            <input
              accept="image/*"
              id="raised-button-file"
              type="file"
              style={{ display: "none" }}
              disabled={disabled}
              onChange={(event) => {
                setImagePreview(URL.createObjectURL(event.target.files[0]));
                setValue(name, event.target.files[0], {
                  shouldTouch: true,
                  shouldDirty: true,
                });
              }}
            />
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              justifyContent={value ? "center" : "flex-end"}
              alignItems="flex-start"
              height="40px"
            >
              {/* uploaded file name */}
              {value?.name && (
                <Grid item xs={4}>
                  <Typography
                    variant="text-sm"
                    fontFamily="Nunito"
                    fontWeight={fontWeightVariations.medium}
                    color={primaryColors.gray[700]}
                  >
                    {formatFileName(value.name)}
                  </Typography>
                </Grid>
              )}
              {/* upload button */}
              <Grid
                item
                xs={6}
                display="flex"
                flexDirection="row"
                justifyContent="center"
              >
                <label htmlFor="raised-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    disabled={disabled}
                    sx={{
                      backgroundColor: value
                        ? primaryColors.brand[25]
                        : primaryColors.brand[50],
                      padding: "10px 16px",
                      borderRadius: "8px",
                      width: "fit-content",
                      height: "36px",
                      GridShadow: `0px 1px 2px 0px ${primaryColors.gray[25]}`,
                      "&.Mui-disabled": {
                        bgcolor: primaryColors.base.white,
                      },
                      "&:hover": {
                        bgcolor: value
                          ? primaryColors.brand[25]
                          : primaryColors.brand[50],
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: disabled
                          ? primaryColors.brand[200]
                          : value
                          ? primaryColors.brand[500]
                          : primaryColors.brand[700],
                        textTransform: "none",
                        fontSize: fontSizeVariations["text-sm"],
                        fontWeight: fontWeightVariations.semiBold,
                      }}
                    >
                      {label}
                    </Typography>
                  </Button>

                  {/* helper text */}
                  {helperText && !value && (
                    <Typography
                      variant="text-sm"
                      fontFamily="Nunito"
                      fontWeight={fontWeightVariations.medium}
                      color={primaryColors.gray[700]}
                    >
                      {helperText}
                    </Typography>
                  )}
                </label>
              </Grid>
              {/* delete button */}
              {showDeleteButton && !disabled && (
                <Grid item xs={2}>
                  {value && (
                    <IconButton
                      onClick={function resetImagePreview() {
                        resetField(name);
                      }}
                      sx={{
                        background: primaryColors.error[50],
                        borderRadius: "8px",
                      }}
                    >
                      <Delete
                        sx={{
                          width: "20px",
                          height: "20px",
                          color: primaryColors.error[500],
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    />
  );
};
