import { Box, Typography } from "@mui/material";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import { primaryColors } from "../../../helpers/customColors";

export const EmptyTableView = ({ title, description, actionButton }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="fit-content"
      padding="40px 1% 48px"
    >
      <Box padding="0% 38%" mb="24px" textAlign="center">
        <Typography
          sx={{
            fontSize: fontSizeVariations["text-md"],
            fontWeight: fontWeightVariations["semiBold-600"],
            color: primaryColors.gray[900],
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeVariations["text-sm"],
            fontWeight: fontWeightVariations["medium-500"],
            color: primaryColors.gray[500],
          }}
        >
          {description}
        </Typography>
      </Box>
      {actionButton}
    </Box>
  );
};
