import { Box, IconButton, Skeleton, Typography, Tooltip } from "@mui/material";
import { RevampedLandingPageCard } from "./RevampedLandingPage";
import { generateCardDynamicSection } from "../../landing-page/components/BranchProgressCard";
import { Info } from "@mui/icons-material";
import { primaryColors, secondaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { ChangeInRateBadge } from "./ChangeInRateBadge";

export const SpecificBranchesQuestionsInsights = ({
  rawQuestionsInsightsData,
  branchesData,
  divisionsData,
  isFetching,
  selectedStartDate,
  selectedEndDate,
  insightsInfo,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      {isFetching
        ? [...Array(3)].map(function renderQuestionsSkeletons(index) {
            return <SkeletonForQuestionInsight key={index} />;
          })
        : rawQuestionsInsightsData?.map(function renderQuestionsinsights(
            questionData,
            index
          ) {
            return (
              <RevampedLandingPageCard
                key={index}
                label={
                  <Typography
                    variant="text-lg"
                    fontWeight={fontWeightVariations.medium}
                    color={primaryColors.gray[900]}
                  >
                    {questionData.text}
                  </Typography>
                }
                width="30%"
                height={`${320 + branchesData?.length * 5}px`}
                button={
                  <Tooltip
                    title={
                      <Box
                        display="flex"
                        flexDirection="column"
                        width="170px"
                        gap="10px"
                      >
                        <Typography
                          variant="text-sm"
                          fontWeight={fontWeightVariations.semiBold}
                        >
                          {insightsInfo[questionData.type].title}
                        </Typography>
                        {insightsInfo[questionData.type].question && (
                          <Typography
                            variant="text-xs"
                            fontWeight={fontWeightVariations.semiBold}
                          >
                            {`Q:${insightsInfo[questionData.type].question}`}
                          </Typography>
                        )}
                        <Typography variant="text-xs">
                          <strong>How that’s calculated:</strong>
                          <br />
                          {insightsInfo[questionData.type].description}
                        </Typography>
                      </Box>
                    }
                  >
                    <IconButton aria-label="info">
                      <Info />
                    </IconButton>
                  </Tooltip>
                }
              >
                <QuestionInsightCard
                  branchesData={branchesData}
                  divisionsData={divisionsData}
                  questionData={questionData}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                />
              </RevampedLandingPageCard>
            );
          })}
    </Box>
  );
};

const SelectedBranchBadge = ({ label, type }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="fit-content"
      borderRadius={4}
      gap={1}
      py={0.5}
      px={1}
      bgcolor={
        type === "branch" ? secondaryColors.blue[50] : secondaryColors.green[50]
      }
    >
      <Box
        width="6px"
        height="6px"
        bgcolor={
          type === "branch"
            ? secondaryColors.blue[700]
            : secondaryColors.green[700]
        }
        display="inline-block"
        borderRadius="50%"
      ></Box>
      <Typography
        variant="text-xs"
        color={
          type === "branch"
            ? secondaryColors.blue[700]
            : secondaryColors.green[700]
        }
      >
        {label}
      </Typography>
    </Box>
  );
};

const SkeletonForQuestionInsight = () => {
  return (
    <Skeleton
      variant="rectangular"
      width="30%"
      height={300}
      sx={{ borderRadius: 5 }}
    />
  );
};

const QuestionInsightCard = ({
  branchesData,
  divisionsData,
  questionData,
  selectedStartDate,
  selectedEndDate,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="fit-content"
      justifyContent="space-between"
    >
      <Box width="100%">{generateCardDynamicSection(questionData)}</Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        top="-120px"
        gap="12px"
      >
        {/* branches badges */}
        {/* don't render if there's selected divisions */}
        {divisionsData.markedDivisionsData?.filter(function isDivisionSelected(
          divisionData
        ) {
          return divisionData.selected === true;
        }).length === 0 && (
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            {branchesData.isAllBranchesSelected ? (
              <SelectedBranchBadge label="All Branches" type="branch" />
            ) : (
              branchesData?.markedBranchesData?.map(
                function renderSelectedBranch(branchData, index) {
                  return (
                    branchData.selected && (
                      <SelectedBranchBadge
                        key={index}
                        label={branchData.name}
                        type="branch"
                      />
                    )
                  );
                }
              )
            )}
          </Box>
        )}
        {/* divisions badges */}
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          gap="10px"
        >
          {divisionsData.isAllDivisionsSelected ? (
            <SelectedBranchBadge label="All Divisions" type="division" />
          ) : (
            divisionsData?.markedDivisionsData?.map(
              function renderSelectedDivision(divisionData, index) {
                return (
                  divisionData.selected && (
                    <SelectedBranchBadge
                      key={index}
                      label={divisionData.name}
                      type="division"
                    />
                  )
                );
              }
            )
          )}
        </Box>
        <ChangeInRateBadge
          changeInPercentage={questionData?.percentage}
          changeInPoints={questionData?.points}
          semantic={questionData?.semantic}
          arrow={questionData?.arrow}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
        />
      </Box>
    </Box>
  );
};
