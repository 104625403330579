import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { debounce } from "lodash";
import { OverallViewContext } from "../../contexts/OverallViewContext";
import { isEmpty } from "../../../../helpers/General";
import { ProgressSectionSkeleton } from "../../screens";
import { ExternalProgressPage } from "./ExternalProgressPage";
import { getOverallProgressData } from "../../api";

export const OverallProgress = ({ skeleton = false }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState({ isError: false, errorMessage: "" });

  const { getAvailableFiltersOfCurrentTab } = useContext(OverallViewContext);

  const getOverallProgressDataHandler = useCallback(
    (progressPageQueryStrings) => {
      setLoading(true);
      setError({
        isError: false,
      });

      getOverallProgressData(progressPageQueryStrings)
        .then((data) => {
          if (!data || isEmpty(data)) {
            setError({
              isError: true,
              errorMessage: "No data in this duration",
            });
          } else {
            if (data) {
              setError({
                isError: false,
              });
              setData(data);
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          let errorMsg = "";

          // Show error msg only when the status code doesn't imply payment failure
          if (error.response.status !== 403) {
            errorMsg = "Network Error, Please try again later";
          }

          setError({
            isError: true,
            errorMessage: errorMsg,
          });
        });
    },
    []
  );

  useLazyEffect(
    () => {
      getOverallProgressDataHandler({ ...getAvailableFiltersOfCurrentTab() });
    },
    [getAvailableFiltersOfCurrentTab, getOverallProgressDataHandler],
    200
  );

  return skeleton ? (
    <ProgressSectionSkeleton />
  ) : (
    <ExternalProgressPage data={data} error={error} loading={loading} />
  );
};

function useLazyEffect(effect, deps = [], wait = 300) {
  const cleanUp = useRef();
  const effectRef = useRef();
  const updatedEffect = useCallback(effect, deps);
  effectRef.current = updatedEffect;
  const lazyEffect = useCallback(
    debounce(() => {
      cleanUp.current = effectRef.current?.();
    }, wait),
    []
  );
  useEffect(lazyEffect, deps);
  useEffect(() => {
    return () => (cleanUp.current ? cleanUp.current() : undefined);
  }, []);
}
