import { Box, Grid, Typography } from "@mui/material";
import { GenerateGeneralLinkBox } from "./GenerateGeneralLinkBox";
import { GenerateBulkSMSAccordion } from "./GenerateBulkSMSAccordion";
import { GenerateBulkLinksAccordion } from "./GenerateBulkLinksAccordion";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { GenerateQRCodesForUnitsAccordion } from "./GenerateQRCodesForUnitsAccordion";
import { useTemplateSelectionState } from "../../../reusable/hooks";
import { TemplateSelection } from "../../../reusable/components";

export const DistributionPage = () => {
  const {
    templateSelectionState,
    selectBranchHandler,
    selectDivisionHandler,
    selectTemplateHandler,
  } = useTemplateSelectionState();

  return (
    <Box display="flex" flexDirection="column" gap={5} maxWidth="lg" margin="auto">
      <Grid container spacing={2} justifyContent="center">
        <TemplateSelection
          templateSelectionState={templateSelectionState}
          selectBranchHandler={selectBranchHandler}
          selectDivisionHandler={selectDivisionHandler}
          selectTemplateHandler={selectTemplateHandler}
        />
        {/* Only enable the Distribution actions when there's a selected Template */}
        <DistributionTools
          disabled={templateSelectionState.templateData === undefined}
          selectedBranchData={templateSelectionState.branchData}
          selectedDivisionData={templateSelectionState.divisionData}
          selectedTemplateData={templateSelectionState.templateData}
        />
      </Grid>
    </Box>
  );
};

const DistributionTools = ({
  disabled,
  selectedBranchData,
  selectedDivisionData,
  selectedTemplateData,
}) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" mt={4}>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="text-md"
          color={primaryColors.base.black}
          fontWeight={fontWeightVariations["medium-500"]}
          sx={{ mb: "12px" }}
        >
          General Link
        </Typography>
        <Typography
          variant="text-sm"
          color={primaryColors.gray[500]}
          fontWeight={fontWeightVariations["medium-500"]}
          sx={{ mb: "8px" }}
        >
          No Expire date, users can only submit once
        </Typography>
      </Box>
      <Grid item container xs={12} sm={12} md={12} spacing={2}>
        <Grid item xs={6} sm={6} md={6} p={2}>
          <GenerateGeneralLinkBox
            disabled={disabled}
            selectedBranchData={selectedBranchData}
            selectedDivisionData={selectedDivisionData}
            selectedTemplateData={selectedTemplateData}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} p={2}>
          <GenerateQRCodesForUnitsAccordion
            disabled={disabled}
            selectedBranchData={selectedBranchData}
            selectedDivisionData={selectedDivisionData}
            selectedTemplateData={selectedTemplateData}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} p={2}>
          <GenerateBulkSMSAccordion
            disabled={disabled}
            selectedBranchData={selectedBranchData}
            selectedDivisionData={selectedDivisionData}
            selectedTemplateData={selectedTemplateData}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} p={2}>
          <GenerateBulkLinksAccordion
            disabled={disabled}
            selectedBranchData={selectedBranchData}
            selectedDivisionData={selectedDivisionData}
            selectedTemplateData={selectedTemplateData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
