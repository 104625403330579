import { Box } from "@mui/material";
import { TemplateGroupNavigationMenu } from "./TemplateGroupNavigationMenu";
import { TemplateGroupView } from "./TemplateGroupView";
import { TemplateGroupSettingsMenu } from "./TemplateGroupSettingsMenu";
import { useParams } from "react-router-dom";
import { useGetTemplateGroupDetails } from "../../api/getTemplateGroupDetails";
import { useEffect, useReducer, useState } from "react";
import { useGetTemplateQuestions } from "../../api/getTemplateQuestions";
import { primaryColors } from "../../../../helpers/customColors";

export const TemplateGroupManagementPage = () => {
  const [
    templateGroupManagementState,
    dispatchActionToTemplateGroupStateManagement,
  ] = useReducer(
    templateGroupStateManagementReducer,
    defaultTemplateGroupStateManagementData
  );
  const { templateGroupID } = useParams();

  const {
    reviewTemplateID,
    complaintTemplateID,
    welcomeSectionDataForNavigation,
    endingSectionDataForNavigation,
    detailedSectionDataForNavigation,
  } = useFetchAndGetLocalizedTemplateGroupDetails({
    templateGroupID,
  });

  const { reviewSectionDataForNavigation } =
    useFetchAndGetReviewTemplateQuestionsData({
      reviewTemplateID: reviewTemplateID,
    });

  const { complaintSectionDataForNavigation } =
    useFetchAndGetComplaintTemplateQuestionsData({
      complaintTemplateID: complaintTemplateID,
    });

  useEffect(function disableBodyScrollingForTemplateGroupManagementPageOnly() {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  return (
    <Box display="flex" width="100%">
      <Box
        width="320px"
        boxSizing="border-box"
        bgcolor={primaryColors.base.white}
      >
        <TemplateGroupNavigationMenu
          templateGroupStateManagement={{
            state: templateGroupManagementState,
            dispatcher: dispatchActionToTemplateGroupStateManagement,
          }}
          screensSectionsData={{
            welcomeSection: welcomeSectionDataForNavigation,
            reviewSection: reviewSectionDataForNavigation,
            complaintSection: complaintSectionDataForNavigation,
            detailedSection: detailedSectionDataForNavigation,
            endingSection: endingSectionDataForNavigation,
          }}
        />
      </Box>
      <TemplateGroupView
        templateGroupStateManagement={{
          state: templateGroupManagementState,
          dispatcher: dispatchActionToTemplateGroupStateManagement,
        }}
      />
      <Box width="25%">
        <TemplateGroupSettingsMenu />
      </Box>
    </Box>
  );
};

export const templateViewGroupLanguages = {
  English: "en",
  Arabic: "ar",
};

const defaultTemplateGroupStateManagementData = {
  selectedScreen: {
    type: undefined,
    key: undefined,
    viewData: undefined,
  },
  viewLanguage: templateViewGroupLanguages.English,
};

export const templateGroupStateManagementActions = {
  updateSelectedScreen: "UPDATE-SELECTED-SCREEN",
  updateViewLanguage: "UPDATE-VIEW-LANGUAGE",
};

function templateGroupStateManagementReducer(state, action) {
  switch (action.type) {
    case templateGroupStateManagementActions.updateSelectedScreen: {
      return {
        ...state,
        selectedScreen: {
          type: action.payload.selectedScreen.type,
          key: action.payload.selectedScreen.key,
          viewData: action.payload.selectedScreen.viewData,
        },
      };
    }
    case templateGroupStateManagementActions.updateViewLanguage: {
      return {
        ...state,
        viewLanguage: action.payload.viewLanguage,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}

export const selectedTemplateScreenSectionTypes = {
  welcomeSection: "WELCOME-SECTION",
  reviewSection: "REVIEW-SECTION",
  complaintSection: "COMPLAINT-SECTION",
  detailedSection: "DETAILED-SECTION",
  endingScreenSection: "END-SCREEN-SECTION",
};

export const staticNavigationMenuKeys = {
  welcomeScreen: `${selectedTemplateScreenSectionTypes.welcomeSection}-welcome-screen`,
  detailedScreen: `${selectedTemplateScreenSectionTypes.detailedSection}-detailed-screen`,
  endingScreen: `${selectedTemplateScreenSectionTypes.endingScreenSection}-ending-screen`,
};

function useFetchAndGetLocalizedTemplateGroupDetails({ templateGroupID }) {
  const {
    data: templateGroupDetailsServerResponse,
    isLoading: isLoadingTemplateGroupDetails,
  } = useGetTemplateGroupDetails({ params: { templateGroupID } });

  const englishTemplateGroupViewData =
    templateGroupDetailsServerResponse?.data.find(
      function getTemplateGroupViewDataBasedOnLang(
        localizedTemplateGroupViewData
      ) {
        return (
          localizedTemplateGroupViewData.lang ===
          templateViewGroupLanguages.English
        );
      }
    ).instance;

  const arabicTemplateGroupViewData =
    templateGroupDetailsServerResponse?.data.find(
      function getTemplateGroupViewDataBasedOnLang(
        localizedTemplateGroupViewData
      ) {
        return (
          localizedTemplateGroupViewData.lang ===
          templateViewGroupLanguages.Arabic
        );
      }
    ).instance;

  const templateGroupDetailsData = !templateGroupDetailsServerResponse
    ? undefined
    : {
        en: englishTemplateGroupViewData,
        ar: arabicTemplateGroupViewData,
      };

  return {
    templateGroupDetailsData,
    reviewTemplateID:
      templateGroupDetailsData?.en.main_question.review_template,
    complaintTemplateID:
      templateGroupDetailsData?.en.main_question.complaint_template,
    welcomeSectionDataForNavigation: !templateGroupDetailsData
      ? undefined
      : {
          welcomeScreenViewData: {
            en: {
              image: templateGroupDetailsData.en.welcome_screen.image,
              mainText: templateGroupDetailsData.en.welcome_screen.main_text,
              subText: templateGroupDetailsData.en.welcome_screen.sub_text,
            },
            ar: {
              image: templateGroupDetailsData.ar.welcome_screen.image,
              mainText: templateGroupDetailsData.ar.welcome_screen.main_text,
              subText: templateGroupDetailsData.ar.welcome_screen.sub_text,
            },
          },
          mainQuestion: {
            question: {
              id: templateGroupDetailsData.en.main_question.question.id,
              label: templateGroupDetailsData.en.main_question.question.text,
            },
            questionViewData: {
              en: templateGroupDetailsData.en.main_question.question,
              ar: templateGroupDetailsData.ar.main_question.question,
            },
          },
        },
    endingSectionDataForNavigation: !templateGroupDetailsData
      ? undefined
      : {
          endingScreenViewData: {
            en: {
              image: templateGroupDetailsData.en.ending_screen.image,
              mainText: templateGroupDetailsData.en.ending_screen.main_text,
              subText: templateGroupDetailsData.en.ending_screen.sub_text,
            },
            ar: {
              image: templateGroupDetailsData.ar.ending_screen.image,
              mainText: templateGroupDetailsData.ar.ending_screen.main_text,
              subText: templateGroupDetailsData.ar.ending_screen.sub_text,
            },
          },
        },
    detailedSectionDataForNavigation: !templateGroupDetailsData
      ? undefined
      : {
          detailedScreenViewData: {
            en: {
              questions: templateGroupDetailsData.en.detailed_screen.questions,
              personalInfo:
                templateGroupDetailsData.en.detailed_screen.personal_info,
              showVoiceNote:
                templateGroupDetailsData.en.detailed_screen.show_voice_note,
              voiceNoteLabel:
                templateGroupDetailsData.en.detailed_screen.voice_note_label,
            },
            ar: {
              questions: templateGroupDetailsData.ar.detailed_screen.questions,
              personalInfo:
                templateGroupDetailsData.ar.detailed_screen.personal_info,
              showVoiceNote:
                templateGroupDetailsData.ar.detailed_screen.show_voice_note,
              voiceNoteLabel:
                templateGroupDetailsData.ar.detailed_screen.voice_note_label,
            },
          },
          topQuestions: {
            en: templateGroupDetailsData.en.detailed_screen.questions,
            ar: templateGroupDetailsData.ar.detailed_screen.questions,
          },
        },
  };
}

function useFetchAndGetReviewTemplateQuestionsData({ reviewTemplateID }) {
  const { data: reviewTemplateQuestionsServerResponse } =
    useGetTemplateQuestions({
      params: {
        templateID: reviewTemplateID,
      },
      config: {
        enabled: reviewTemplateID !== undefined,
      },
    });

  const englishReviewQuestions =
    reviewTemplateQuestionsServerResponse?.data.find(
      function getTemplateGroupViewDataBasedOnLang(
        localizedTemplateQuestionsData
      ) {
        return (
          localizedTemplateQuestionsData.lang ===
          templateViewGroupLanguages.English
        );
      }
    ).questions;

  const arabicReviewQuestions =
    reviewTemplateQuestionsServerResponse?.data.find(
      function getTemplateGroupViewDataBasedOnLang(
        localizedTemplateQuestionsData
      ) {
        return (
          localizedTemplateQuestionsData.lang ===
          templateViewGroupLanguages.Arabic
        );
      }
    ).questions;

  return {
    reviewSectionDataForNavigation: !englishReviewQuestions
      ? undefined
      : {
          questions: englishReviewQuestions.map(
            function mapReviewQuestionsForNavMenu(englishReviewQuestion) {
              return {
                id: englishReviewQuestion.id,
                label: englishReviewQuestion.text,
                questionViewData: {
                  en: englishReviewQuestion,
                  ar: arabicReviewQuestions.find(
                    function findArabicReviewQuestionByID(
                      arabicReviewQuestion
                    ) {
                      return (
                        arabicReviewQuestion.id === englishReviewQuestion.id
                      );
                    }
                  ),
                },
              };
            }
          ),
        },
  };
}

function useFetchAndGetComplaintTemplateQuestionsData({ complaintTemplateID }) {
  const { data: complaintTemplateQuestionsServerResponse } =
    useGetTemplateQuestions({
      params: {
        templateID: complaintTemplateID,
      },
      config: {
        enabled: complaintTemplateID !== undefined,
      },
    });

  const englishComplaintQuestions =
    complaintTemplateQuestionsServerResponse?.data.find(
      function getTemplateGroupViewDataBasedOnLang(
        localizedTemplateQuestionsData
      ) {
        return (
          localizedTemplateQuestionsData.lang ===
          templateViewGroupLanguages.English
        );
      }
    ).questions;

  const arabicComplaintQuestions =
    complaintTemplateQuestionsServerResponse?.data.find(
      function getTemplateGroupViewDataBasedOnLang(
        localizedTemplateQuestionsData
      ) {
        return (
          localizedTemplateQuestionsData.lang ===
          templateViewGroupLanguages.Arabic
        );
      }
    ).questions;

  return {
    complaintSectionDataForNavigation: !englishComplaintQuestions
      ? undefined
      : {
          questions: englishComplaintQuestions.map(
            function mapComplaintQuestionsForNavMenu(englishComplaintQuestion) {
              return {
                id: englishComplaintQuestion.id,
                label: englishComplaintQuestion.text,
                questionViewData: {
                  en: englishComplaintQuestion,
                  ar: arabicComplaintQuestions.find(
                    function findArabicComplaintQuestionByID(
                      arabicComplaintQuestion
                    ) {
                      return (
                        arabicComplaintQuestion.id ===
                        englishComplaintQuestion.id
                      );
                    }
                  ),
                },
              };
            }
          ),
        },
  };
}
