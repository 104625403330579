import { useContext, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { GenericDialog } from "../../../reusable/components/GenericDialog";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { primaryColors } from "../../../helpers/customColors";
import { useGenerateLinkOrQRCode } from "../api/generateLinkOrQRCode";
import { IsLoadingContainer } from "../../../reusable/components/IsLoadingContainer";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../contexts/NotificationContext";

export const GeneralLinkQRCodeDialog = ({
  selectedBranchData,
  selectedDivisionData,
  selectedTemplateData,
  disabled,
}) => {
  const [qrCodeLink, setQRCodeLink] = useState("");

  const generateLinkOrQRCodeMutation = useGenerateLinkOrQRCode();

  const { fireNotification } = useContext(NotificationContext);

  const downloadQRCode = () => {
    fetch(
      `https://api.qrserver.com/v1/create-qr-code/?data=${qrCodeLink}&size=300x300;`,
      {
        method: "GET",
        headers: {},
      }
    ).then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${selectedBranchData?.name} - ${
            selectedDivisionData?.name
          } - QR - Code - ${new Date().toLocaleDateString()}.png`.replace(
            / /g,
            ""
          )
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    });
  };

  return (
    <GenericDialog
      dialogTitle={`QR Code`}
      dialogSubtitle={`Branch: ${selectedBranchData?.name} | Division: ${selectedDivisionData?.name} | Template: ${selectedTemplateData?.name}`}
      maxWidth="md"
      onOpenCallback={function onOpenGeneralLinkQRCodeDIalogHandler() {
        if (!disabled) {
          generateLinkOrQRCodeMutation
            .mutateAsync({
              branchID: selectedBranchData.id,
              divisionID: selectedDivisionData.id,
              templateID: selectedTemplateData.id,
              forQRCode: true,
              type: "both",
            })
            .then(function (response) {
              setQRCodeLink(response.data.link);
            });
        } else {
          fireNotification({
            title: "Please, Select first a Branch, Division, and Template",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
            horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
            width: NOTIFICATION_WIDTH.small,
            target: NOTIFICATIONS_TARGET.admin,
            closeAfter: 60000
          });
        }
      }}
      preventOpening={disabled}
      triggerButton={
        <Button
          variant="text"
          sx={{ display: "flex", gap: 1, '&:hover': {bgcolor: 'transparent'} }}
          disableTouchRipple
        >
          <QrCode2Icon sx={{ color: primaryColors.gray[700], width: "20px" }} />
          <Typography
            variant="text-sm"
            color={primaryColors.gray[700]}
            sx={{ textTransform: "capitalize" }}
          >
            QR Code
          </Typography>
        </Button>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={5}
        height="400px"
      >
        <IsLoadingContainer isLoading={generateLinkOrQRCodeMutation.isLoading}>
          {qrCodeLink && (
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?data=${qrCodeLink}&size=300x300;`}
              alt="qrCode"
            />
          )}
        </IsLoadingContainer>

        {generateLinkOrQRCodeMutation.isSuccess && (
          <Button onClick={downloadQRCode} sx={{ textTransform: "capitalize" }}>
            Download
          </Button>
        )}
      </Box>
    </GenericDialog>
  );
};
