import moment from "moment";
import {
  createContext,
  useEffect,
  useReducer,
  useState,
  useCallback,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import { channelsDict } from "../components/ReviewsManagement";
import { overallViewTabs } from "../screens/OverallViewScreen";

export const OverallViewContext = createContext();

export const OverallViewContextProvider = ({ currentTab, children }) => {
  // Shared Selected Filters Reducer
  const [selectedFiltersState, dispatchActionToSelectedFilters] = useReducer(
    updateSelectedFiltersReducer,
    {
      // Default Date Range is the Past 6 Months
      date_from: moment().subtract(6, "months").format("YYYY-MM-DD"),
      date_to: moment().format("YYYY-MM-DD"),
    }
  );
  console.log(selectedFiltersState, "nice");
  // Shared Filters Data Reducer
  const [filtersOptionsState, dispatchActionToFilterOptionsState] = useReducer(
    updateFiltersDataReducer,
    filtersDataInitialState
  );

  const previousTabRef = useRef(currentTab);

  const [applyFiltersFlag, setApplyFiltersFlag] = useState(true);

  const applyFiltersFlagHandler = (newFlagState) => {
    setApplyFiltersFlag(newFlagState);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const getAvailableFiltersOfCurrentTab = useCallback(() => {
    const availableFilters = {};

    let urlQueryStringsForCurrentTab = [];
    if (currentTab === overallViewTabs.progress)
      urlQueryStringsForCurrentTab = [...progressTabURLQueryStrings];
    if (currentTab === overallViewTabs.reviews)
      urlQueryStringsForCurrentTab = [...reviewsTabURLQueryStrings];
    if (currentTab === overallViewTabs.complaints)
      urlQueryStringsForCurrentTab = [...complaintsTabURLQueryStrings];

    for (let filterKey in selectedFiltersState) {
      if (selectedFiltersState[filterKey] !== undefined) {
        // Only use the filters related to the Current Tab
        if (urlQueryStringsForCurrentTab.includes(filterKey)) {
          // Only include a filter key in URL if it has values selected
          if (selectedFiltersState[filterKey].toString().length > 0)
            availableFilters[filterKey] = selectedFiltersState[filterKey];
        }
      }
    }

    return availableFilters;
  }, [currentTab, selectedFiltersState]);

  useEffect(() => {
    if (previousTabRef.current !== currentTab) {
      applyFiltersFlagHandler(true);

      previousTabRef.current = currentTab;
    }
  }, [currentTab]);

  /**
   * On mount only, set the selectedFiltersState default state using the URL Query Strings
   *  */
  useEffect(() => {
    const selectedFiltersFromURLParams = {};

    const allowedFiltersForThreeTabs = [
      ...progressTabURLQueryStrings,
      ...reviewsTabURLQueryStrings,
      ...complaintsTabURLQueryStrings,
    ];

    for (const entry of searchParams.entries()) {
      // Make this module manage only the shared filters in Overall View (not Tab specific ones)
      if (allowedFiltersForThreeTabs.includes(entry[0]))
        selectedFiltersFromURLParams[entry[0]] = entry[1];
    }

    dispatchActionToSelectedFilters({
      type: updateSelectedFiltersActions.setDefaultState,
      payload: {
        selectedFiltersFromURLParams,
      },
    });
  }, [searchParams]);
  /**
   * Update the URL Query Strings upon the mutation of the selectedFiltersState and currentTab
   * Based on the current Tab
   *  */
  useEffect(() => {
    const availableFiltersOfCurrentTab = getAvailableFiltersOfCurrentTab();

    setSearchParams({
      ...availableFiltersOfCurrentTab,
    });
  }, [getAvailableFiltersOfCurrentTab, setSearchParams]);

  return (
    <OverallViewContext.Provider
      // Pass these values down to all children
      value={{
        selectedFiltersState,
        dispatchActionToSelectedFilters,

        filtersOptionsState,
        dispatchActionToFilterOptionsState,

        applyFiltersFlag,
        applyFiltersFlagHandler,

        getAvailableFiltersOfCurrentTab,
      }}
    >
      {children}
    </OverallViewContext.Provider>
  );
};

export const updateSelectedFiltersActions = {
  // Start: Actions for First Level Filters
  // Handle each filter's state on its own
  updateSelectedDate: "UPDATE-DATE",
  updateSelectedBranches: "UPDATE-SELECTED-BRANCHES",
  updateSelectedDivisions: "UPDATE-SELECTED-DIVISIONS",
  updateSelectedTemplates: "UPDATE-SELECTED-TEMPLATES",
  updateSelectedQuestions: "UPDATE-SELECTED-QUESTIONS",

  disableRefetchingFiltersOnClose: "DISABLE-REFETCHING-FILTERS-ON-CLOSE",
  // End: Actions for First Level Filters

  // Actions for Second Level Filters
  updateSelectedChannel: "UPDATE-SELECTED-CHANNEL",
  updateSelectedIssue: "UPDATE-SELECTED-ISSUE",
  toggleBookmarkedFlag: "TOGGLE-STARRED-FLAG",
  toggleResolvedFlag: "TOGGLE-RESOLVED-FLAG",
  toggleArchivedFlag: "TOGGLE-ARCHIVED-FLAG",

  setDefaultState: "SET-DEFAULT-STATE",
  resetSelectedFilters: "RESET-SELECTED-FILTER",
  resetSelectedDate: "RESET-SELECTED-DATE",
};

export const updateFiltersOptionsActions = {
  // Handle Select Menus content: Will be called selectively upon filters API responses.
  // updateCurrentBranches: "UPDATE-CURRENT-BRANCHES",
  updateCurrentDivisions: "UPDATE-CURRENT-DIVISIONS",
  // updateCurrentTemplates: "UPDATE-CURRENT-TEMPLATES",
  // updateCurrentQuestions: "UPDATE-CURRENT-QUESTIONS",

  // New: Handle all the incoming filters responses dynamically
  updateCurrentFiltersOptions: "UPDATE-CURRENT-FILTERS-OPTIONS",
};

const updateSelectedFiltersReducer = (selectedFiltersState, action) => {
  switch (action.type) {
    case updateSelectedFiltersActions.updateSelectedDate: {
      return {
        ...selectedFiltersState,
        date_from: action.payload.date_from,
        date_to: action.payload.date_to,
      };
    }
    case updateSelectedFiltersActions.updateSelectedBranches: {
      return {
        ...selectedFiltersState,
        branches: action.payload.branches.join(","),
        shouldRefetchFiltersOnClose: true,
      };
    }
    case updateSelectedFiltersActions.updateSelectedDivisions: {
      return {
        ...selectedFiltersState,
        divisions: action.payload.divisions.join(","),
        shouldRefetchFiltersOnClose: true,
      };
    }
    case updateSelectedFiltersActions.updateSelectedTemplates: {
      return {
        ...selectedFiltersState,
        templates: action.payload.templates.join(","),
        shouldRefetchFiltersOnClose: true,
      };
    }
    case updateSelectedFiltersActions.updateSelectedQuestions: {
      return {
        ...selectedFiltersState,
        questions: action.payload.questions.join(","),
      };
    }
    case updateSelectedFiltersActions.updateSelectedChannel: {
      const channelsArray = action.payload.channels;
      const allChannelsOptionSelected = channelsArray.includes(
        channelsDict.All
      );
      return {
        ...selectedFiltersState,
        channels: allChannelsOptionSelected ? "" : channelsArray.join(","),
      };
    }
    case updateSelectedFiltersActions.updateSelectedIssue: {
      return {
        ...selectedFiltersState,
        issue: action.payload.issue,
      };
    }
    case updateSelectedFiltersActions.toggleArchivedFlag: {
      return {
        ...selectedFiltersState,
        archived: selectedFiltersState.archived === "true" ? undefined : "true",
      };
    }
    case updateSelectedFiltersActions.toggleBookmarkedFlag: {
      return {
        ...selectedFiltersState,
        bookmarked:
          selectedFiltersState.bookmarked === "true" ? undefined : "true",
      };
    }
    case updateSelectedFiltersActions.toggleResolvedFlag: {
      return {
        ...selectedFiltersState,
        resolved: selectedFiltersState.resolved === "true" ? undefined : "true",
      };
    }
    case updateSelectedFiltersActions.disableRefetchingFiltersOnClose: {
      return {
        ...selectedFiltersState,
        shouldRefetchFiltersOnClose: false,
      };
    }
    case updateSelectedFiltersActions.setDefaultState: {
      return {
        ...selectedFiltersState,
        ...action.payload.selectedFiltersFromURLParams,
        // channels: action.payload.selectedFiltersFromURLParams.channels
        //   ? action.payload.selectedFiltersFromURLParams.channels
        //   : "",
        // archived:
        //   action.payload.selectedFiltersFromURLParams.archived === "true"
        //     ? true
        //     : undefined,
        // bookmarked:
        //   action.payload.selectedFiltersFromURLParams.bookmarked === "true"
        //     ? true
        //     : undefined,
      };
    }
    case updateSelectedFiltersActions.resetSelectedDate: {
      return {
        ...selectedFiltersState,
        date_from: undefined,
        date_to: undefined,
      };
    }
    case updateSelectedFiltersActions.resetSelectedFilters: {
      return {
        ...selectedFiltersState,
        branches: undefined,
        divisions: undefined,
        templates: undefined,
        questions: undefined,
      };
    }
    default: {
      return selectedFiltersState;
    }
  }
};

const updateFiltersDataReducer = (filtersOptionsState, action) => {
  switch (action.type) {
    // case updateFiltersOptionsActions.updateCurrentBranches: {
    //   return {
    //     ...filtersOptionsState,
    //     branchesOptions: action.payload.branchesOptions,
    //   };
    // }
    case updateFiltersOptionsActions.updateCurrentDivisions: {
      return {
        ...filtersOptionsState,
        divisionsOptions: action.payload.divisionsOptions,
      };
    }
    // case updateFiltersOptionsActions.updateCurrentTemplates: {
    //   return {
    //     ...filtersOptionsState,
    //     templatesOptions: action.payload.templatesOptions,
    //   };
    // }
    // case updateFiltersOptionsActions.updateCurrentQuestions: {
    //   return {
    //     ...filtersOptionsState,
    //     questionsOptions: action.payload.questionsOptions,
    //   };
    // }
    case updateFiltersOptionsActions.updateCurrentFiltersOptions: {
      return {
        ...filtersOptionsState,
        /**
         * If the sent payload for branches/divisions/templats/question is undefined,
         * then set each to their already existing previous state
         */
        branchesOptions: action.payload.branchesOptions
          ? action.payload.branchesOptions
          : filtersOptionsState.branchesOptions,
        divisionsOptions: action.payload.divisionsOptions
          ? action.payload.divisionsOptions
          : filtersOptionsState.divisionsOptions,
        templatesOptions: action.payload.templatesOptions
          ? action.payload.templatesOptions
          : filtersOptionsState.templatesOptions,
        questionsOptions: action.payload.questionsOptions
          ? action.payload.questionsOptions
          : filtersOptionsState.questionsOptions,
      };
    }
    default: {
      return filtersOptionsState;
    }
  }
};

// const selectedFiltersInitialState = {
//   date_from: undefined,
//   date_to: undefined,
//   branches: undefined,
//   divisions: undefined,
//   templates: undefined,
//   questions: undefined,
//   channels: undefined,
//   starred: undefined,
//   archived: undefined,
// };

const filtersDataInitialState = {
  branchesOptions: [],
  divisionsOptions: [],
  templatesOptions: [],
  questionsOptions: [],
};

// Solely used to determine which Filters should be populated to the URL based in the Current Tab
const progressTabURLQueryStrings = [
  "date_from",
  "date_to",

  // Temp: Until the APIs get changed
  // "branches",
  // "divisions",
];
const reviewsTabURLQueryStrings = [
  "date_from",
  "date_to",
  "branches",
  "divisions",
  "templates",
  "questions",

  "channels",
  "archived",
  "bookmarked",
];
const complaintsTabURLQueryStrings = [
  "date_from",
  "date_to",
  "branches",
  "divisions",

  "channels",
  "bookmarked",
  "resolved",
  "issue",
];
