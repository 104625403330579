import { ScreenContainer } from "../../../layout/ScreenContainer";
import { OnboardingPage } from "../components/OnboardingPage";

export const OnboardingPageScreen = () => {
  return (
    <ScreenContainer>
      <OnboardingPage />
    </ScreenContainer>
  );
};
