import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

const TEMPLATE_GROUPS_PAGE_COUNT = 10;

export const defaultTemplateGroupsListParams = {
  page: 1,
  pageSize: TEMPLATE_GROUPS_PAGE_COUNT,
};

const getTemplateGroups = (queryStrings) => {
  const queryData = {
    params: {
      ...queryStrings,
      page_size: queryStrings.pageSize
    },
  };
  const requestURL = `${API_URL}/template-group`;

  return melior.get(requestURL, queryData);
};

export const useGetTemplateGroups = (
  { params, config } = {
    params: defaultTemplateGroupsListParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["template-groups", params],
    queryFn: () => getTemplateGroups(params),
    keepPreviousData: true,
  });
};
