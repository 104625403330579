import { makeStyles } from "@mui/styles";

import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { COLORS } from "../../../helpers/Colors";
import { primaryColors } from "../../../helpers/customColors";

const useStyles = makeStyles(() => ({
  tooltip: {
    margin: 0,
    border: "1px solid",
    borderColor: COLORS.silver,
    backgroundColor: COLORS.white,
    paddingBottom: "0%",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    opacity: "0.7",
    width: "auto",
  },
  tooltipTitle: {
    margin: "5px",
    color: COLORS.brightBlack,
  },
}));

export const VerticalBarChart = ({
  data,
  YAxisScale,
  getBarColor,
  showTooltip,
}) => {
  const CustomTooltip = ({ payload, active }) => {
    const classes = useStyles();
    if (active) {
      return (
        <div className={classes.tooltip}>
          <p className={classes.tooltipTitle}>
            Total count: {payload[0].payload.tooltipValue}
          </p>
        </div>
      );
    }

    return null;
  };
  const renderCustomizedLabel = ({ viewBox, value }) => {
    const { x, y, width, height } = viewBox;
    if (width === 0) return;
    return (
      <text
        x={x + 22}
        y={y + 20}
        fontSize="16px"
        fontFamily="Nunito"
        textAnchor="middle"
        fill={primaryColors.base.white}
      >
        {value?.toFixed(1)}
      </text>
    );
  };

  function getBranchTickData(branchId) {
    let branchData = data?.find((branch) => branch.id === branchId);
    return { name: branchData.name, color: branchData.color };
  }
  const renderCustomizedTick = ({ x, y, textAnchor, payload }) => {
    let branchData = getBranchTickData(payload.value);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = getComputedStyle(document.body).font;
    let circleTextGap = context.measureText(branchData.name).width / 2 + 12;

    return [
      <text x={x} y={y + 15} textAnchor={textAnchor}>
        {branchData.name}
      </text>,
      <circle
        cx={x - circleTextGap}
        cy={y + 10}
        r={5}
        fill={branchData.color || primaryColors.gray[100]}
      />,
    ];
  };

  return (
    <div
      style={{
        width: "100%",
        height: `${300}px`,
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 100,
            bottom: 40,
            left: 20,
          }}
        >
          <CartesianGrid stroke={COLORS.silver} vertical={false} />
          <XAxis
            dataKey="id"
            type="category"
            axisLine={false}
            tickLine={false}
            tick={renderCustomizedTick}
          />

          {showTooltip && <Tooltip content={<CustomTooltip />} />}
          <YAxis
            ticks={YAxisScale}
            tickCount={YAxisScale.length}
            width={110}
            axisLine={false}
            tickLine={false}
          />
          <Bar dataKey="value" barSize={45} style={{}} radius={[8, 8, 0, 0]}>
            {data?.map((entry, index) => (
              <Cell key={index} fill={getBarColor(entry.value)} />
            ))}
            <LabelList content={renderCustomizedLabel} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
